import React, { useState, useEffect } from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import exportingModule from "highcharts/modules/exporting";
import exportDataModule from 'highcharts/modules/export-data'
import { singleDoubleChartWidth, chartHeight } from '../../services';
import useWindow from '../../hooks/useWindow'
exportingModule(Highcharts);
exportDataModule(Highcharts);


export const GeneralChart = ({ param }) => {
    const [options, setOptions] = useState({})
    const size = useWindow()

    useEffect(() => {
        const { chart, width, height, title, subtitle, series, legend, xAxis, yAxis, plotOptions, isSingle = true } = param
        const options = {
            chart: { 
                ...chart,
                width: width || singleDoubleChartWidth(size.width, isSingle),
                height: height || chartHeight(size.height),
            },
            exporting: {
                buttons: {
                    contextButton: {
                        enabled: true,
                        align: 'left',
                        menuItems: ["downloadPNG", "downloadPDF", "downloadCSV", "separator"]
                    }
                }
            },
            title,
            subtitle,
            legend,
            xAxis,
            yAxis,
            plotOptions,
            series
        }
        setOptions(options)
        // console.log(options)
    }, [param, size])

    return <div className='row-center'>
        <HighchartsReact highcharts={Highcharts} options={options} />
    </div>

}