import { useState, useEffect, useContext } from 'react'
import { sleep, list2 } from '../../services'
import { DispatchContext } from "../../reducers";

export const Intraday = () => {

    const dispatch = useContext(DispatchContext)
    const [messages, setMessages] = useState([])

    async function fetch (spin=false) {
        if (spin) dispatch({ type: 'spin on' })
        const [prices, warnings] = await Promise.all([
            list2('price', 'intraday'),
            list2('warning')
        ])
        dispatch({ type: 'spin off' })
        const messages = [...warnings, ...prices]
        setMessages(messages)
    }
    
    useEffect(() => {
        fetch(true)
    }, [])

    useEffect(() => {
        async function display() {
            if (messages.length === 0) return
            await sleep(300000)
            fetch()
        }
        display()
    }, [messages])

    return (
        <>
            {messages.length > 0 && <div className="ticker-wrap bg-black fs-16">
                <div className="ticker">
                    {messages.map(({ message, color, delta }, idx) => <div key={`key_intraday_message_${idx}`} className={`inline_block pr-10 ${color}`}>{message}&nbsp;{delta && (color === 'red' ? <span>&#9660;{delta}</span> : <span>&#9650;{delta}</span>)}</div>)}
                </div>
            </div>}
        </>
    )
}