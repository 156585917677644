import React, { useState, useEffect } from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import useWindow from '../../hooks/useWindow'
import exportingModule from "highcharts/modules/exporting";
import exportDataModule from 'highcharts/modules/export-data'
exportingModule(Highcharts);
exportDataModule(Highcharts);

export const ForwardCurveChart = ({ data, label, categories }) => {

    const [options, setOptions] = useState({})
    const size = useWindow()

    useEffect(() => {
        const options = {
            chart:  { 
                type: 'spline',
                width: 100,
                height: 100,
                borderWidth: 1,
                margin: [5,5,5,5]
            },
            tooltip: {
                style: {
                    display: "none",
                }
            },
            title: {
                align: 'right',
                style: { fontSize: '14px', fontWeight: 900 },
                text: ''
            },
            exporting: {
                buttons: {
                    contextButton: {
                        enabled: false
                    }    
                }
            },
            yAxis: { 
                title: { text: '' },
                labels: { enabled: false },
                gridLineWidth: 0, 
            },
            xAxis: {
                title: { text: '' },
                labels: { enabled: false },
                tickLength: 0,
                lineWidth: 0,
                categories
            },
            legend: {
                enabled: false
            },
            series: data ? [{
                name: label,
                marker: { enabled: false },
                data
            }] : []
        }
        setOptions(options)
    }, [data, label, size, categories]);

    return (
    <div>
        <HighchartsReact
            highcharts={Highcharts}
            options={options}
        />
    </div>)
}