import { Menu, MenuItem, Divider } from '@material-ui/core';
import { useState } from 'react';

export const MenuComponent = ({ label, header, options=[], topOptions=[], bottomOptions=[], callback }) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSelect = event => {
        callback(event)
        handleClose()
    }

    return (
        <>
            <label onClick={handleClick}>
                {label}
            </label>
            <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
                {header && <MenuItem key={`key_menu_item_header`}>{header}</MenuItem>}
                {header && <Divider variant="fullWidth" />}
                {topOptions.map((option, i) => <MenuItem key={`key_menu_item_top_${option.label}_${i}`} onClick={() => handleSelect(option) }>{option.label}</MenuItem>)}
                {topOptions.length > 0 && <Divider variant="fullWidth" />}
                {options.map((option, i) => {
                    const { divider, disabled, label } = option
                    return divider ? <Divider variant="fullWidth" /> : 
                    <MenuItem disabled={disabled} key={`key_menu_item_${label}_${i}`} onClick={() => handleSelect(option) }>{label}</MenuItem>
                })}
                {bottomOptions.length > 0 && <Divider variant="fullWidth" />}
                {bottomOptions.map((option, i) => <MenuItem key={`key_menu_item_bottom_${option.label}_${i}`} onClick={() => handleSelect(option) }>{option.label}</MenuItem>)}
            </Menu>
        </>
    );
}