
export const riskClass = (param) => param.value === 'Low' ? 'bg-green_a1' : param.value === 'Medium' ? 'bg-amber2' : 'bg-red3'

export const riskHeatMapClass = (param) => {
    const field = param.colDef.field + '-w'
    const delta = param.data[field]
    if (delta > 0.8) {
        return 'heatmap-10'
    } else if (delta > 0.6) {
        return 'heatmap-9'
    } else if (delta > 0.4) {
        return 'heatmap-8'
    } else if (delta > 0.2) {
        return 'heatmap-7'
    } else if (delta > 0) {
        return 'heatmap-6'
    } else if (delta === 0 || !delta) {
        return 'heatmap-5'
    } else if (delta > -0.2) {
        return 'heatmap-4'
    } else if (delta > -0.4) {
        return 'heatmap-3'
    } else if (delta > -0.6) {
        return 'heatmap-2'
    } else if (delta > -0.8) {
        return 'heatmap-1'
    } else {
        return 'heatmap-0'
    }
}

export const portRiskHeatMapClass = (param) => {
    const field = param.colDef.field + '_risk'
    return param.data[field] === 'High' ? 'bg-red3' : ''
}

export default {
    riskClass,
    riskHeatMapClass,
    portRiskHeatMapClass,
}