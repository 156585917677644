import React, { useState, useEffect } from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import moment from 'moment'
import useWindow from '../../hooks/useWindow'
import exportingModule from "highcharts/modules/exporting";
import exportDataModule from 'highcharts/modules/export-data'
import { post2, list2 } from '../../services'
exportingModule(Highcharts);
exportDataModule(Highcharts);

export const ForwardCurveDetailChart = ({ series, series1, series2, date, label, categories, width, height }) => {

    const [options, setOptions] = useState({})
    const size = useWindow()

    const [exp, setExp] = useState(false)
    useEffect(() => {
        async function fetch() {
            const auth = await list2('auth')
            setExp(auth === true)
        }
        fetch()
    }, [])

    useEffect(() => {
        const options = {
            chart:  { 
                type: 'spline',
                width,
                height,
            },
            exporting: exp ? {
                buttons: {
                    contextButton: {
                        enabled: true,
                        align: 'left',
                        menuItems: ["downloadPNG", "downloadPDF", "downloadCSV", "separator",
                            {
                                text: 'Send Email',
                                onclick: function() {
                                    const csv = this.getCSV()
                                    const html = this.getSVG()
                                    const title = this.getFilename()
                                    post2('user/export_file', { csv, html, title })
                                }
                            }
                        ]
                    }
                }
            } : {
                buttons: {
                    contextButton: {
                        enabled: false
                    }    
                }
            },
            title: {
                text: ''
            },
            yAxis: { 
                title: { text: '' },
            },
            tooltip: {
                formatter: function () {
                    return `<b>${this.x}</b><br /><p>${this.y.toFixed(4)}</p>`
                }
            },
            xAxis: {
                title: { text: '' },
                categories
            },
            legend: {
                enabled: true
            },
            series: [
                {
                    name: date,
                    data: series
                },
                {
                    name: moment(date).subtract(1, 'week').format('YYYY-MM-DD'),
                    data: series1
                },
                {
                    name: moment(date).subtract(2, 'week').format('YYYY-MM-DD'),
                    data: series2
                }
            ]
        }
        setOptions(options)
    }, [series, series1, series2, label, size, date, categories, width, height, exp])

    return (
    <div>
        <HighchartsReact
            highcharts={Highcharts}
            options={options}
        />
    </div>)
}