import { useState, useEffect } from 'react'
import Highcharts from "highcharts/highstock";
import HighchartsReact from 'highcharts-react-official'
import { useWindow } from '../../hooks'
import { singleDoubleChartWidth, chartHeight, post2, list2 } from '../../services';
import moment from 'moment';
import exportingModule from "highcharts/modules/exporting"; 
import exportDataModule from 'highcharts/modules/export-data'
exportingModule(Highcharts);
exportDataModule(Highcharts);

export const PriceChart = ({ data, label, config, isSingle=false }) => {

    const [options, setOptions] = useState({})
    const [aves, setAves] = useState([])
    const [colors, setColors] = useState([])
    const size = useWindow()

    const [exp, setExp] = useState(false)
    useEffect(() => {
        async function fetch() {
            const auth = await list2('auth')
            setExp(auth === true)
        }
        fetch()
    }, [])

    const addAvgLine = (axis) => {
        const values = [], cls = []
        const mapped = axis.series.map(({ color, processedYData, visible }, pos) => {
            const text = (processedYData.reduce((pre, cur) => pre + cur, 0) / processedYData.length).toFixed(2)
            const value = +text
            if (visible) {
                values.push(value)
            }
            cls.push({ value, color })
            return {
                id: `avg${pos}`,
                value,
                color,
                dashStyle: 'dash',
                width: 1,
                zIndex: 1000,
            }
        })
        if (values.length > 0) {
            setAves(values)
            setColors(cls)
        }
        mapped.forEach((plotline, pos) => {
            axis.chart.get('yAxis0').removePlotLine(`avg${pos}`)
            if (axis.series[pos].visible) {
                axis.chart.get('yAxis0').addPlotLine(plotline)
            }
        })
    }

    useEffect(() => {
        const last = data[data.length - 1]
        const { specialCase } = config
        const jkmSpecialHandle = specialCase && specialCase.id === 31
        const options = {
            chart:  { 
                width: singleDoubleChartWidth(size.width, isSingle),
                height: chartHeight(size.height),
                alignTicks: false ,
                spacingRight: config.yAxis ? 0 : 10
            },
            exporting: exp ? {
                buttons: {
                    contextButton: {
                        enabled: true,
                        align: 'left',
                        menuItems: ["downloadPNG", "downloadPDF", "downloadCSV", "separator",
                            {
                                text: 'Send Email',
                                onclick: function() {
                                    const csv = this.getCSV()
                                    const html = this.getSVG()
                                    const title = this.getFilename()
                                    post2('user/export_file', { csv, html, title })
                                }
                            }
                        ]
                    }
                }
            } : {
                buttons: {
                    contextButton: {
                        enabled: false
                    }    
                }
            },
            title: {
                text: label
            },
            xAxis: {
                min: moment.utc().subtract(3, 'month').valueOf(),
                max: jkmSpecialHandle ? moment.utc().add(3, 'month').endOf('month').valueOf() : moment.utc().valueOf(),
                events: jkmSpecialHandle ? undefined : {
                    afterSetExtremes: function() {
                        addAvgLine(this.chart.yAxis[0])
                    }
                }
            },
            legend: {
                enabled: true
            },
            yAxis: config.yAxis ? config.yAxis.map((y, i) => {
                return {...y, id: `yAxis${i}`, labels: { x: y.opposite ? 2 : -2 } }
            }): { title: { text: '' }, opposite: false, id: 'yAxis0', labels: { x: -2 } },
            plotOptions: {
                spline: {
                    marker: {
                        enabled: false
                    }
                }
            },
            series: data && data.length > 0 ? Object.keys(data[0]).filter(key => key !== 'date').map((key, i) => ({
                name: key,
                dataLabels: {
                    enabled: true,
                    align: 'left',
                    crop: false,
                    allowOverlap: true,
                    formatter: function() {
                        if (jkmSpecialHandle) {
                            const { showLabels } = specialCase
                            return showLabels.includes(moment(this.x).format('YYYY-MM-DD')) ? this.y : ''
                        } else {
                            return moment(this.x).format('YYYY-MM-DD') === last.date ? this.y : ''
                        }
                    }
                },
                type: config.type[key],
                dashStyle: config.dashStyle[key],
                data: data.map((d) => ({ x: moment.utc(d.date, 'YYYY-MM-DD').valueOf(), y: d[key], time: moment(d.date).format(), date: d.date, showLabel: d.showLabel })),
                yAxis: config.yAxisConfig ? config.yAxisConfig[key] : undefined,
                events: jkmSpecialHandle ? undefined : {
                    hide: function() {
                        addAvgLine(this.chart.yAxis[0])
                        // this.chart.get('yAxis0').removePlotLine(`avg${i}`);
                    },
                    show: function() {
                        addAvgLine(this.chart.yAxis[0])
                    }
                }
            })) : []
        }
        const tickPositioner = function() {
            const ticks = this.tickPositions
            const interval = this.tickInterval
            const newticks = ticks.filter((t, i) => i === 0 || i === ticks.length - 1 || aves.every((a) => Math.abs(t - a) >= interval))
            newticks.push(...aves)
            newticks.sort((a, b) => a - b)
            return newticks
        }
        const formatter = function() {
            const find = colors.find(({ value }) => value === this.value)
            return find ? `<div style="color:${find.color};text-align:center;font-weight:bold;"><div>avg<div><div>${this.value}<div></div>` : `<div>${this.value}</div>`
        }
        if (Array.isArray(options.yAxis)) {
            options.yAxis.forEach((y) => {
                y.tickPositioner = tickPositioner
                y.labels.useHTML = true
                y.labels.formatter = formatter
            })
        } else {
            options.yAxis.tickPositioner = tickPositioner
            options.yAxis.labels.useHTML = true
            options.yAxis.labels.formatter = formatter
        }
        // console.log(options)
        setOptions(options)
    }, [data, label, config, size, isSingle, aves, colors, exp]);

    return (
        <div>
            <HighchartsReact
                highcharts={Highcharts}
                constructorType={"stockChart"}
                options={options}
            />
        </div>
    )
}