import React, { useState, useEffect } from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import moment from 'moment';
import HC_more from 'highcharts/highcharts-more'
import useWindow from '../../hooks/useWindow'
import { singleDoubleChartWidth, chartHeight, pascal, post2, list2 } from '../../services';
import exportingModule from "highcharts/modules/exporting";
import exportDataModule from 'highcharts/modules/export-data'
exportingModule(Highcharts);
exportDataModule(Highcharts);

HC_more(Highcharts)

export const MedianChart = ({ data, title, start, type, isSingle=false }) => {

    const [options, setOptions] = useState({})
    const size = useWindow()
    
    const [exp, setExp] = useState(false)
    useEffect(() => {
        async function fetch() {
            const auth = await list2('auth')
            setExp(auth === true)
        }
        fetch()
    }, [])

    useEffect(() => {
        if (!data || data.length === 0) return
        const end = data[data.length - 1].date
        const text = `${moment(start).format('MMM YY')} - ${moment(end).format('MMM YY')} (MBD)`
        const options = {
            chart:  { 
                width: singleDoubleChartWidth(size.width, isSingle),
                height: chartHeight(size.height)
            },
            exporting: exp ? {
                buttons: {
                    contextButton: {
                        enabled: true,
                        align: 'left',
                        menuItems: ["downloadPNG", "downloadPDF", "downloadCSV", "separator",
                            {
                                text: 'Send Email',
                                onclick: function() {
                                    const csv = this.getCSV()
                                    const html = this.getSVG()
                                    const title = this.getFilename()
                                    post2('user/export_file', { csv, html, title })
                                }
                            }
                        ]
                    }
                }
            } : {
                buttons: {
                    contextButton: {
                        enabled: false
                    }    
                }
            },
            title: {
                text: `${title}: ${type === 'balance' ? 'Gross Balance' : `${pascal(type)}`}`,
                align: 'center'
            },
            subtitle: {
                text,
                align: 'right'
            },
            xAxis: {
                title: { text: '' },
                categories: data.map(dat => moment(dat.date).format('MMM YY'))
            },
            tooltip: {
                formatter: function () {
                    return this.points.reduce((pre, cur) => {
                        const text = cur.series.userOptions.type === 'areasplinerange' ? `<div><b>5Y High:</b>&nbsp;${cur.point.high}</div><br /><div><b>5Y Low:</b>&nbsp;${cur.point.low}</div>` : `<div><b>${cur.series.userOptions.name}:</b>&nbsp;${cur.y}</div>`
                        return `${pre}<br />${text}`
                    }, `<b>${this.x}</b><br />`)
                },
                shared: true
            },
            yAxis: { 
                title: { text: '' },
                labels: {
                    formatter: function() {
                        return type === 'yield' ? `${(this.value*100).toFixed(1)}%` : this.value
                    }
                }
            },
            series: [
                {
                    name: '5Y',
                    type: 'areasplinerange',
                    fillOpacity: 0.3,
                    marker: { enabled: false },
                    data: data.map(dat => dat[`${type}_range`]),
                },
                {
                    name: '5Y Median',
                    type: 'spline',
                    data: data.map(dat => +dat[`${type}_median`]),
                },
                {
                    name: pascal(type),
                    type: 'spline',
                    data: data.map(dat => +dat[type]),
                }
            ]
        }
        setOptions(options)
    }, [data, title, start, type, size, isSingle, exp]);

    return (
        <div>
            <HighchartsReact
                highcharts={Highcharts}
                options={options}
            />
        </div>
    )
}
