import { useState, useEffect } from 'react';
import { Bar } from '../../../components';
import { EiaWeekly } from '.';

const allOptions = {
    balance: [
        { name: 'eia_balance_overview', label: 'Overview' }
    ],
    refinery: [
        { name: 'eia_input_refiner', label: 'Inputs and Utilization' },
        { name: 'eia_input_blender', label: 'Blending Components' }
    ],
    imports: [
        { name: 'eia_imports_crude', label: 'Crude' },
        { name: 'eia_imports_gasoline', label: 'Gasoline' },
        { name: 'eia_imports_distillate', label: 'Distillate' },
        { name: 'eia_imports_jet', label: 'Jet' },
        { name: 'eia_imports_residual', label: 'Residual' },
        { name: 'eia_imports_other', label: 'Other' }
    ],
    stocks: [
        { name: 'eia_stock_crude', label: 'Crude' },
        { name: 'eia_stock_gasoline', label: 'Gasoline' },
        { name: 'eia_stock_distillate', label: 'Distillate' },
        { name: 'eia_stock_jet', label: 'Jet' },
        { name: 'eia_stock_residual', label: 'Residual' },
        { name: 'eia_stock_other', label: 'Other' }
    ],
    production: [
        { name: 'eia_production_crude', label: 'Crude' },
        { name: 'eia_production_gasoline', label: 'Gasoline' },
        { name: 'eia_production_distillate', label: 'Distillate' },
        { name: 'eia_production_jet', label: 'Jet' },
        { name: 'eia_production_residual', label: 'Residual' },
        { name: 'eia_production_other', label: 'Other' }
    ],
}

export const EiaTab = ({ type }) => {
    const [options, setOptions] = useState(allOptions.balance)
    const [tab, setTab] = useState()

    useEffect(() => {   
        if (type) setOptions(allOptions[type])
    }, [type])

    useEffect(() => {
        if (options) setTab(options[0].name)
    }, [options])

    const onSelectTab = event => {
        setTab(event)
    }

    return <div>
        <Bar options={options} cls={'thirdbar'} callback={onSelectTab} />
        <EiaWeekly type={tab} />
    </div>
}