import { useState, useEffect } from "react";
import { list2 } from '../services/Api'

const useConfig = () => {
    const [config, setConfig] = useState({})
    useEffect(() => {
        async function fetch() {
            const config = await list2('config')
            setConfig(config)
        }
        fetch()
    }, []);
    return config;
}

export default useConfig;