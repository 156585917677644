import { useState, useEffect } from 'react';
import { list2 } from '../../services/Api'

export const Lastupdate = ({ type = 'news', align = 'right' }) => {
    const [label, setLabel] = useState('')
    useEffect(() => {
        async function fetch() {
            if (type) {
                const data = await list2('lastupdate', type)
                setLabel(`Update: ${data}`)
            }
        }
        fetch()
    }, [type])

    return (
        <div className={`row-${align} w-95p mb-3 mt-3`}>
            <label className='f-01 gray'>{label}</label>
        </div>
    )
}