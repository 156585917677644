import { useEffect, useState } from 'react';
import ReactFlow, { useNodesState, useEdgesState, useReactFlow, ReactFlowProvider, addEdge, MarkerType, Handle, Position } from 'reactflow';
import 'reactflow/dist/style.css';
import useWindow from '../../../hooks/useWindow'

const nodeTypes = { 
    general: GeneralNode
};

export const colors = {
    white: '#ffffff',
    unit: '#795548',
    crude: '#2e7d32',

    lpg: '#b3e5fc',

    lnap: '#66bb6a',
    hnap: '#66bb6a',
    nap: '#66bb6a',
    lcn: '#66bb6a',
    hcn: '#66bb6a',

    isomerate: '#ef5350',
    reformate: '#ef5350',
    gasoline: '#ef5350',

    kero: '#ff9800',
    diesel: '#ff9800',
    lco: '#ff9800',
    lcgo: '#ff9800',

    ar: '#283593',
    vr: '#283593',
    lvgo: '#283593',
    hvgo: '#283593',
    hcgo: '#283593',

    fo: '#000000',
    vgo: '#000000',
    slurry: '#000000',
    bttm: '#000000',
    coke: '#000000',
    black: '#000000',
}

export function GeneralNode({ data: { handles, label }, isConnectable=true }) {
    return <div>
        {handles.map((handle) => <Handle key={handle.id} id={handle.id} type={handle.type} position={Position[handle.position]} style={handle.style} isConnectable={isConnectable} />)}
        <div className=''>
            {label}
        </div>
    </div>
}

export const process_data = ({ data, base_x, base_y }) => {

    const generate_units = ({ data, base_x, base_y }) => [
    
        { id: 'unit-throughput', type: 'unit', x: base_x-100, y: base_y+175, bgColor: 'green', color: 'white', width: 100, height: 50, label: <div className='column-center'><div>Throughput</div><div>{data.throughput} KBD</div></div>,
            handles: [{ idx: 0, position: 'Right', type: 'source', top: 25 }], key: 'throughput'
        },
        { id: 'unit-cdu', type: 'unit', x: base_x+50, y: base_y, bgColor: colors.unit, color: 'white', width: 100, height: 450, round: true, label: <div className='column-center mt-3'><div>CDU</div><div>{data.capacity.cdu} KBD</div></div>, 
            handles: [
                { idx: 0, position: 'Left', type: 'target', top: 200 }, 
                { idx: 1, position: 'Top', type: 'source', left: 50 }, 
                { idx: 2, position: 'Right', type: 'source', top: 20, left: 85 }, 
                { idx: 3, position: 'Right', type: 'source', top: 190 }, 
                { idx: 4, position: 'Right', type: 'source', top: 320 }, 
                { idx: 5, position: 'Right', type: 'source', top: 360 }, 
                { idx: 6, position: 'Bottom', type: 'source', left: 50 }
            ],
            key: 'throughput'
        },
        { id: 'unit-vdu', type: 'unit', x: base_x+200, y: base_y+700, bgColor: colors.unit, color: 'white', width: 100, height: 200, round: true, label: <div className='column-center mt-3'><div>VDU</div><div>{data.capacity.vdu} KBD</div></div>, 
            handles: [
                { idx: 0, position: 'Left', type: 'target', top: 100 }, 
                { idx: 1, position: 'Top', type: 'source', left: 50 }, 
                { idx: 2, position: 'Right', type: 'source', top: 100 }, 
                { idx: 3, position: 'Bottom', type: 'source', left: 50 }
            ],
            key: 'vdu_in_ar'
        },
        { id: 'unit-isom', type: 'unit', x: base_x+650, y: base_y+10, bgColor: 'green', color: 'white', width: 100, height: 140, label: <div className='column-center mt-2'><div>ISOM</div><div>{data.capacity.isom} KBD</div><div>{data.properties.isom}</div></div>, 
            handles: [
                { idx: 0, position: 'Left', type: 'target', top: 10 },
                { idx: 1, position: 'Left', type: 'target', top: 50 },
                { idx: 2, position: 'Left', type: 'target', top: 90 },
                { idx: 3, position: 'Left', type: 'target', top: 130 },
                { idx: 4, position: 'Bottom', type: 'target', left: 30 },
                { idx: 5, position: 'Bottom', type: 'target', left: 90 },
                { idx: 6, position: 'Top', type: 'source', left: 10 },
                { idx: 7, position: 'Right', type: 'source', top: 60 }
            ],
            key: 'isom_in'
        },
        { id: 'unit-reformer', type: 'unit', x: base_x+860, y: base_y+180, bgColor: 'green', color: 'white', width: 100, height: 140, label: <div className='column-center mt-2'><div>Reformer</div><div>{data.capacity.reformer} KBD</div><div>{data.properties.reformer}</div></div>, 
            handles: [
                { idx: 0, position: 'Left', type: 'target', top: 10 },
                { idx: 1, position: 'Left', type: 'target', top: 40 },
                { idx: 2, position: 'Left', type: 'target', top: 70 },
                { idx: 3, position: 'Left', type: 'target', top: 100 },
                { idx: 4, position: 'Left', type: 'target', top: 130 }, 
                { idx: 5, position: 'Top', type: 'source', left: 10 },
                { idx: 6, position: 'Right', type: 'source', top: 50 }
            ],
            key: 'reformer_in'
        },
        { id: 'unit-dht', type: 'unit', x: base_x+400, y: base_y+340, bgColor: 'orange', color: 'white', width: 100, height: 100, label: <div className='column-center mt-2'><div>DHT</div><div>{data.capacity.dht} KBD</div><div>{data.properties.dht}</div></div>, 
            handles: [
                { idx: 0, position: 'Left', type: 'target', top: 20 }, 
                { idx: 1, position: 'Left', type: 'target', top: 50 }, 
                { idx: 2, position: 'Left', type: 'target', top: 80 }, 
                { idx: 3, position: 'Top', type: 'Source', left: 50 },
                { idx: 4, position: 'Right', type: 'Source', top: 10 },
                { idx: 5, position: 'Right', type: 'Source', top: 60 },
            ],
            key: 'dht_in'
        },
        { id: 'unit-dhc', type: 'unit', x: base_x+500, y: base_y+490, bgColor: 'orange', color: 'white', width: 100, height: 130, label: <div className='column-center mt-3'><div>DHC</div><div>{data.capacity.dhc} KBD</div><div>{data.properties.dhc}</div></div>, 
            handles: [
                { idx: 0, position: 'Left', type: 'target', top: 40 }, 
                { idx: 1, position: 'Left', type: 'target', top: 100 }, 
                { idx: 2, position: 'Top', type: 'source', left: 20 }, 
                { idx: 3, position: 'Top', type: 'source', left: 80 }, 
                { idx: 4, position: 'Right', type: 'source', top: 20 }, 
                { idx: 5, position: 'Right', type: 'source', top: 70 }, 
                { idx: 6, position: 'Bottom', type: 'source', left: 50 }
            ],
            key: 'dhc_in'
        },
        { id: 'unit-fcc', type: 'unit', x: base_x+900, y: base_y+660, bgColor: 'blue', color: 'white', width: 100, height: 200, label: <div className='column-center mt-2'><div>FCC</div><div>{data.capacity.fcc} KBD</div><div className='text-center'>{data.properties.fcc} Conversion</div></div>,
            handles: [
                { idx: 0, position: 'Left', type: 'target', top: 10 }, 
                { idx: 1, position: 'Left', type: 'target', top: 60 }, 
                { idx: 2, position: 'Left', type: 'target', top: 140-1 }, 
                { idx: 3, position: 'Left', type: 'target', top: 180 }, 
                { idx: 4, position: 'Left', type: 'target', top: 100 },
                { idx: 5, position: 'Top', type: 'source', left: 50 }, 
                { idx: 6, position: 'Right', type: 'source', top: 40 }, 
                { idx: 7, position: 'Right', type: 'source', top: 80 }, 
                { idx: 8, position: 'Right', type: 'source', top: 120 }
            ],
            key: 'fcc_in'
        },
        { id: 'unit-vgo-blender', type: 'unit', bgColor: 'purple', color: 'white', x: base_x+650, y: base_y+1300, width: 100, height: 100, label: <div className='column-center mt-2'><div>VGO</div><div>Blender</div></div>, 
            handles: [
                { idx: 0, position: 'Left', type: 'target', top: 20 }, 
                { idx: 1, position: 'Left', type: 'target', top: 50 }, 
                { idx: 2, position: 'Left', type: 'target', top: 80 }, 
                { idx: 3, position: 'Right', type: 'source', top: 50 }, 
            ],
            key: 'vgo_in'
        },
        { id: 'unit-fo-blender', type: 'unit', bgColor: 'black', color: 'white', x: base_x+1130, y: base_y+950, width: 100, height: 160, label: <div className='column-center mt-2'><div>Fuel Oil</div><div className='text-center'>Blender</div></div>, 
            handles: [
                { idx: 0, position: 'Left', type: 'target', top: 80 }, 
                { idx: 1, position: 'Left', type: 'target', top: 60 }, 
                { idx: 2, position: 'Left', type: 'target', top: 40 }, 
                { idx: 3, position: 'Left', type: 'target', top: 20 }, 
                { idx: 4, position: 'Left', type: 'target', top: 100 }, 
                { idx: 5, position: 'Left', type: 'target', top: 120 },
                { idx: 6, position: 'Right', type: 'source', top: 80 }, 
            ],
            key: 'fo_in'
        },
        { id: 'unit-coker', type: 'unit', bgColor: 'black', color: 'white', x: base_x+660, y: base_y+1100, width: 100, height: 100, label: <div className='column-center mt-2'><div>Coker</div><div>{data.capacity.coker} KBD</div><div className='text-center'>{data.properties.coker} Conversion</div></div>, 
            handles: [
                { idx: 0, position: 'Left', type: 'target', top: 20 }, 
                { idx: 1, position: 'Left', type: 'target', top: 50 }, 
                { idx: 2, position: 'Left', type: 'target', top: 80 },
                { idx: 3, position: 'Top', type: 'source', left: 20 }, 
                { idx: 4, position: 'Top', type: 'source', left: 80 }, 
                { idx: 5, position: 'Right', type: 'source', top: 20 }, 
                { idx: 6, position: 'Right', type: 'source', top: 80 }, 
                { idx: 7, position: 'Bottom', type: 'source', left: 50 },
            ],
            key: 'coker_in'
        },
        { id: 'hidden-diesel', type: 'hidden', x: base_x+350, y: base_y+360, product: 'diesel', width: 1, height: 1, label: '', 
            handles: [{ idx: 0, position: 'Bottom', type: 'source' }],
            key: 'cdu_out_diesel_to_fo'
        },
        { id: 'hidden-lvgo', type: 'hidden', x: base_x+450, y: base_y+670, product: 'lvgo', width: 1, height: 1, label: '', 
            handles: [{ idx: 0, position: 'Bottom', type: 'source' }],
            key: 'vdu_out_lvgo_to_vgo'
        },
        { id: 'hidden-hvgo', type: 'hidden', x: base_x+400, y: base_y+800, product: 'hvgo', width: 1, height: 1, label: '', 
            handles: [{ idx: 0, position: 'Bottom', type: 'source' }],
            key: 'vdu_out_hvgo_to_vgo'
        }
    ]
    
    const units = generate_units({ data, base_x, base_y })
    const generate_labels = ({ data, base_x, base_y }) => [
        { id: 'product-lpg-cdu', product: 'lpg', label: 'LPG', volume: data.cdu_out_lpg, x: base_x+51, y: base_y-80, position: 'Bottom', classes: 'mt-2 mb-2' },
        { id: 'label-cdu_out_lnap', product: 'lnap', label: 'Lnap', volume: data.cdu_out_lnap, x: base_x+155, y: base_y+5 },
        { id: 'label-cdu_out_hnap', product: 'hnap', label: 'Hnap', volume: data.cdu_out_hnap, x: base_x+155, y: base_y+175 },
        { id: 'label-cdu_out_kero', product: 'kero', label: 'Kero', volume: data.cdu_out_kero, x: base_x+155, y: base_y+285 },
        { id: 'label-cdu_out_diesel', product: 'diesel', label: 'Diesel', volume: data.cdu_out_diesel, x: base_x+155, y: base_y+360 },
        { id: 'label-cdu_out_ar', product: 'ar', label: 'AR', volume: data.cdu_out_ar, x: base_x+110, y: base_y+460 },
        { id: 'product-kero-cdu', product: 'kero', label: 'SR Kero', volume: data.cdu_out_kero_to_product, x: base_x+300, y: base_y+200, position: 'Left', classes: 'mt-1 ml-1' },
        
        { id: 'label-vdu_in_ar', product: 'ar', label: 'AR', volume: data.vdu_in_ar, x: base_x+110, y: base_y+785 },
        { id: 'label-vdu_out_lvgo', product: 'lvgo', label: 'LVGO', volume: data.vdu_out_lvgo, x: base_x+185, y: base_y+665 },
        { id: 'label-vdu_out_hvgo', product: 'hvgo', label: 'HVGO', volume: data.vdu_out_hvgo, x: base_x+300, y: base_y+785 },
        { id: 'label-vdu_out_vr', product: 'vr', label: 'VR', volume: data.vdu_out_vr, x: base_x+180, y: base_y+880 },
    
        { id: 'label-isom_in_dht_nap', product: 'nap', label: 'DHT Nap->Isom', volume: data.isom_in_dht_nap, x: base_x+395, y: base_y+40 },
        { id: 'label-isom_in_dhc_lnap', product: 'lnap', label: 'DHC Lnap->Isom', volume: data.isom_in_dhc_lnap, x: base_x+470, y: base_y+80 },
        { id: 'label-isom_in_dhc_hnap', product: 'hnap', label: 'DHC Hnap->Isom', volume: data.isom_in_dhc_hnap, x: base_x+530, y: base_y+120 },
        { id: 'label-isom_in_lcn', product: 'lcn', label: 'LCN->Isom', volume: data.isom_in_lcn, x: base_x+605, y: base_y+150 },
        { id: 'label-isom_in_hcn', product: 'hcn', label: 'HCN->Isom', volume: data.isom_in_hcn, x: base_x+750, y: base_y+150 },
        { id: 'product-lnap', product: 'lnap', label: 'Lnap to Naphtha', volume: data.isom_out_lnap, x: base_x+1150, y: base_y-40, position: 'Left', classes: 'mt-1 ml-1' },
        { id: 'product-isomerate', product: 'isomerate', label: 'Isomerate', volume: data.isom_out_isomerate, x: base_x+1150, y: base_y+45, position: 'Left', classes: 'mt-1 ml-1' },
    
        { id: 'label-reformer_in_dht_nap', product: 'nap', label: 'DHT Nap->Reformer', volume: data.reformer_in_dht_nap, x: base_x+395, y: base_y+235, width: 120 },
        { id: 'label-reformer_in_dhc_hnap', product: 'hnap', label: 'DHC Hnap->Reformer', volume: data.reformer_in_dhc_hnap, x: base_x+750, y: base_y+315, width: 140 },
        { id: 'label-reformer_in_lcn', product: 'lcn', label: 'LCN->Reformer', volume: data.reformer_in_lcn, x: base_x+750, y: base_y+265 },
        { id: 'label-reformer_in_hcn', product: 'hcn', label: 'HCN->Reformer', volume: data.reformer_in_hcn, x: base_x+750, y: base_y+205 },
        { id: 'product-hnap', product: 'hnap', label: 'Hnap to Naphtha', volume: data.reformer_out_hnap, x: base_x+1150, y: base_y+130, position: 'Left', classes: 'mt-1 ml-1' },
        { id: 'product-reformate', product: 'reformate', label: 'Reformate', volume: data.reformer_out_reformate, x: base_x+1150, y: base_y+205, position: 'Left', classes: 'mt-1 ml-1' },
        
        { id: 'label-dht_in_diesel', product: 'diesel', label: 'Diesel->DHT', volume: data.dht_in_diesel, x: base_x+335, y: base_y+325 },
        { id: 'label-dht_in_lco', product: 'lco', label: 'LCO->DHT', volume: data.dht_in_diesel, x: base_x+1030, y: base_y+730 },
        { id: 'label-dht_in_lcgo', product: 'lcgo', label: 'LCGO->DHT', volume: data.dht_in_diesel, x: base_x+760, y: base_y+1125 },
        { id: 'label-dht_out_nap', product: 'nap', label: 'Nap', volume: data.dht_out_nap, x: base_x+410, y: base_y+280 },
        { id: 'product-kero-dht', product: 'kero', label: 'DHT Kero', volume: data.dht_out_kero, x: base_x+1150, y: base_y+325, position: 'Left', classes: 'mt-1 ml-1' },
        { id: 'product-diesel-dht', product: 'diesel', label: 'DHT Diesel', volume: data.dht_out_diesel, x: base_x+1150, y: base_y+375, position: 'Left', classes: 'mt-1 ml-1' },
            
        { id: 'label-dhc_in_lvgo', product: 'lvgo', label: 'LVGO->DHC', volume: data.dhc_in_lvgo, x: base_x+175, y: base_y+530 },
        { id: 'label-dhc_in_hvgo', product: 'hvgo', label: 'HVGO->DHC', volume: data.dhc_in_hvgo, x: base_x+405, y: base_y+600 },
        { id: 'label-dhc_out_lnap', product: 'lnap', label: 'Lnap', volume: data.dhc_out_lnap, x: base_x+470, y: base_y+450 },
        { id: 'label-dhc_out_hnap', product: 'hnap', label: 'Hnap', volume: data.dhc_out_hnap, x: base_x+585, y: base_y+450 },
        { id: 'label-dhc_out_bttm', product: 'bttm', label: 'Bttm', volume: data.dhc_out_bttm, x: base_x+560, y: base_y+620 },
        { id: 'product-lnap-dhc', product: 'gasoline', label: 'DHC Lnap to Gasoline', volume: data.dhc_out_lnap_to_gasoline, x: base_x+1150, y: base_y+420, width: 180, position: 'Left', classes: 'mt-1 ml-1' },
        { id: 'product-kero-dhc', product: 'kero', label: 'DHC Kero', volume: data.dhc_out_kero, x: base_x+1150, y: base_y+485, position: 'Left', classes: 'mt-1 ml-1' },
        { id: 'product-diesel-dhc', product: 'diesel', label: 'DHC Diesel', volume: data.dhc_out_diesel, x: base_x+1150, y: base_y+535, position: 'Left', classes: 'mt-1 ml-1' },
    
        { id: 'label-fcc_in_lvgo', product: 'lvgo', label: 'LVGO->FCC', volume: data.fcc_in_lvgo, x: base_x+260, y: base_y+655 },
        { id: 'label-fcc_in_hvgo', product: 'hvgo', label: 'HVGO->FCC', volume: data.fcc_in_hvgo, x: base_x+455, y: base_y+785 },
        { id: 'label-fcc_in_vr', product: 'vr', label: 'VR->FCC', volume: data.fcc_in_vr, x: base_x+260, y: base_y+905 },
        { id: 'label-fcc_in_bttm', product: 'bttm', label: 'Bttm->FCC', volume: data.fcc_in_bttm, x: base_x+760, y: base_y+705 },
        { id: 'label-fcc_in_hcgo', product: 'hcgo', label: 'HCGO->FCC', volume: data.fcc_in_hcgo, x: base_x+835, y: base_y+1110 },
        { id: 'label-fcc_out_slurry', product: 'slurry', label: 'Slurry', volume: data.fcc_out_slurry, x: base_x+1010, y: base_y+790 },
        { id: 'product-lpg-fcc', product: 'lpg', label: 'LPG', volume: data.fcc_out_lpg, x: base_x+1150, y: base_y+610, position: 'Left', classes: 'mt-1 ml-1' },
        { id: 'product-nap-fcc', product: 'gasoline', label: 'FCC Nap to Gasoline', volume: data.fcc_out_lpg, x: base_x+1150, y: base_y+675, width: 180, position: 'Left', classes: 'mt-1 ml-1' },
    
        { id: 'label-fo_in_kero', product: 'kero', label: 'Kero->FO', volume: data.fo_in_ar, x: base_x+240, y: base_y+285 },
        { id: 'label-fo_in_diesel', product: 'diesel', label: 'Diesel->FO', volume: data.fo_in_ar, x: base_x+280, y: base_y+370 },
        { id: 'label-fo_in_ar', product: 'ar', label: 'AR->FO', volume: data.fo_in_ar, x: base_x+110, y: base_y+1015 },
        { id: 'label-fo_in_vr', product: 'vr', label: 'VR->FO', volume: data.fcc_in_vr, x: base_x+260, y: base_y+995 },
        { id: 'label-fo_in_hcgo', product: 'hcgo', label: 'HCGO->FO', volume: data.fo_in_hcgo, x: base_x+835, y: base_y+1165 },
        { id: 'product-fo', product: 'fo', label: 'Fuel Oil', volume: data.fo_out_fo, x: base_x+1300, y: base_y+1005, position: 'Left', classes: 'mt-1 ml-1' },
    
        { id: 'label-vgo_in_lvgo', product: 'lvgo', label: 'LVGO->VGO', volume: data.vgo_in_lvgo, x: base_x+460, y: base_y+1335 },
        { id: 'label-vgo_in_hvgo', product: 'hvgo', label: 'HVGO->VGO', volume: data.vgo_in_hvgo, x: base_x+315, y: base_y+1350 },
        { id: 'label-vgo_in_bttm', product: 'bttm', label: 'Bttm->VGO', volume: data.vgo_in_bttm, x: base_x+555, y: base_y+1285 },
        { id: 'product-vgo', product: 'vgo', label: 'VGO', volume: data.vgo_out_vgo, x: base_x+950, y: base_y+1325, position: 'Left', classes: 'mt-1 ml-1' },
        
        { id: 'label-coker_in_bttm', product: 'bttm', label: 'Bttm->Coker', volume: data.coker_in_bttm, x: base_x+555, y: base_y+1085 },
        { id: 'label-coker_in_ar', product: 'ar', label: 'AR->Coker', volume: data.coker_in_ar, x: base_x+110, y: base_y+1165 },
        { id: 'label-coker_in_vr', product: 'vr', label: 'VR->Coker', volume: data.coker_in_vr, x: base_x+260, y: base_y+1135 },
        { id: 'label-coker_out_hcgo', product: 'hcgo', label: 'HCGO', volume: data.coker_out_hcgo, x: base_x+765, y: base_y+1165 },
        { id: 'product-coke', product: 'coke', label: 'Petcoke', volume: data.coker_out_coke, x: base_x+1000, y: base_y+1200, position: 'Left', classes: 'mt-1 ml-1' },
    
    ]
    
    const labels = generate_labels({ data, base_x, base_y })
    
    
    const prepare_label = ({ id, x, y, product, position, handleType='target', label='', width=100, height=50, volume=0, uom='KBD', classes='' }) => {
        const labelHandles = position ? [{ id: `handle-${id}-0`, type: handleType, position }] : []
        return { id, type: 'label', bgColor: 'transparent', volume, x, y, product, width, height, label: <div className={`colunmn-center fs-12 bold ${classes}`}><div>{label}</div><div>{volume.toFixed(2)} {uom}</div></div>, labelHandles }
    }
    
    const generate_nodes = (data) => [...units.map((unit) => ({ ...unit, volume: +data[unit.key].toFixed(2) })), ...labels.map((label) => prepare_label(label))]
    
    const nodes = generate_nodes(data)
    
    const generate_edges = (data) => [
        { source: 'unit-throughput', target: 'unit-cdu', sourceHandle: 'handle-unit-throughput-0', targetHandle: 'handle-unit-cdu-0', product: 'crude', volume: data.throughput },
    
        { source: 'unit-cdu', target: 'product-lpg-cdu', sourceHandle: 'handle-unit-cdu-1', targetHandle: 'handle-product-lpg-cdu-0', product: 'lpg', volume: data.cdu_out_lpg },
        { source: 'unit-cdu', target: 'unit-isom', sourceHandle: 'handle-unit-cdu-2', targetHandle: 'handle-unit-isom-0', product: 'lnap', volume: data.cdu_out_lnap },
        { source: 'unit-cdu', target: 'unit-reformer', sourceHandle: 'handle-unit-cdu-3', targetHandle: 'handle-unit-reformer-0', product: 'hnap', volume: data.cdu_out_hnap },
        { source: 'unit-cdu', target: 'product-kero-cdu', sourceHandle: 'handle-unit-cdu-4', targetHandle: 'handle-product-kero-cdu-0', product: 'kero', volume: data.cdu_out_kero_to_product },
        { source: 'unit-cdu', target: 'unit-fo-blender', sourceHandle: 'handle-unit-cdu-4', targetHandle: 'handle-unit-fo-blender-2', product: 'kero', volume: data.cdu_out_kero_to_fo },
        { source: 'unit-cdu', target: 'unit-dht', sourceHandle: 'handle-unit-cdu-5', targetHandle: 'handle-unit-dht-0', product: 'diesel', volume: data.cdu_out_diesel_to_dht },
        { source: 'unit-cdu', target: 'unit-vdu', sourceHandle: 'handle-unit-cdu-6', targetHandle: 'handle-unit-vdu-0', product: 'ar',volume: data.cdu_out_ar_to_vdu },
        { source: 'unit-cdu', target: 'unit-fo-blender', sourceHandle: 'handle-unit-cdu-6', targetHandle: 'handle-unit-fo-blender-0', product: 'ar', volume: data.cdu_out_ar_to_fo },
        { source: 'unit-cdu', target: 'unit-coker', sourceHandle: 'handle-unit-cdu-6', targetHandle: 'handle-unit-coker-2', product: 'ar', volume: data.cdu_out_ar_to_coker },
    
        { source: 'unit-vdu', target: 'unit-dhc', sourceHandle: 'handle-unit-vdu-1', targetHandle: 'handle-unit-dhc-0', product: 'lvgo', volume: data.vdu_out_lvgo_to_dhc },
        { source: 'unit-vdu', target: 'unit-dhc', sourceHandle: 'handle-unit-vdu-2', targetHandle: 'handle-unit-dhc-1', product: 'hvgo', volume: data.vdu_out_hvgo_to_dhc },
        { source: 'unit-vdu', target: 'unit-fcc', sourceHandle: 'handle-unit-vdu-1', targetHandle: 'handle-unit-fcc-0', product: 'lvgo', volume: data.vdu_out_lvgo_to_fcc },
        { source: 'unit-vdu', target: 'unit-fcc', sourceHandle: 'handle-unit-vdu-2', targetHandle: 'handle-unit-fcc-2', product: 'hvgo', volume: data.vdu_out_hvgo_to_fcc },
        { source: 'unit-vdu', target: 'unit-fcc', sourceHandle: 'handle-unit-vdu-3', targetHandle: 'handle-unit-fcc-3', product: 'vr', volume: data.vdu_out_vr_to_fcc },
        { source: 'unit-vdu', target: 'unit-fo-blender', sourceHandle: 'handle-unit-vdu-3', targetHandle: 'handle-unit-fo-blender-1', product: 'vr', volume: data.vdu_out_vr_to_fo },
        { source: 'unit-vdu', target: 'unit-coker', sourceHandle: 'handle-unit-vdu-3', targetHandle: 'handle-unit-coker-1', product: 'vr', volume: data.vdu_out_vr_to_coker },
        
        { source: 'unit-dht', target: 'unit-isom', sourceHandle: 'handle-unit-dht-3', targetHandle: 'handle-unit-isom-1', product: 'nap', volume: data.dht_out_nap_to_isom },
        { source: 'unit-dht', target: 'unit-reformer', sourceHandle: 'handle-unit-dht-3', targetHandle: 'handle-unit-reformer-2', product: 'nap', volume: data.dht_out_nap_to_reformer },
        { source: 'unit-dht', target: 'product-kero-dht', sourceHandle: 'handle-unit-dht-4', targetHandle: 'handle-product-kero-dht-0', product: 'kero', volume: data.dht_out_kero },
        { source: 'unit-dht', target: 'product-diesel-dht', sourceHandle: 'handle-unit-dht-5', targetHandle: 'handle-product-diesel-dht-0', product: 'diesel', volume: data.dht_out_diesel },
        
        { source: 'unit-dhc', target: 'unit-isom', sourceHandle: 'handle-unit-dhc-2', targetHandle: 'handle-unit-isom-2', product: 'lnap', volume: data.dhc_out_lnap_to_isom },
        { source: 'unit-dhc', target: 'unit-isom', sourceHandle: 'handle-unit-dhc-3', targetHandle: 'handle-unit-isom-3', product: 'hnap', volume: data.dhc_out_hnap_to_isom },
        { source: 'unit-dhc', target: 'unit-reformer', sourceHandle: 'handle-unit-dhc-3', targetHandle: 'handle-unit-reformer-4', product: 'hnap', volume: data.dhc_out_hnap_to_reformer },
        { source: 'unit-dhc', target: 'product-lnap-dhc', sourceHandle: 'handle-unit-dhc-2', targetHandle: 'handle-product-lnap-dhc-0', product: 'gasoline', volume: data.dhc_out_lnap_to_gasoline },
        { source: 'unit-dhc', target: 'product-kero-dhc', sourceHandle: 'handle-unit-dhc-4', targetHandle: 'handle-product-kero-dhc-0', product: 'kero', volume: data.dhc_out_kero },
        { source: 'unit-dhc', target: 'product-diesel-dhc', sourceHandle: 'handle-unit-dhc-5', targetHandle: 'handle-product-diesel-dhc-0', product: 'diesel', volume: data.dhc_out_diesel },
        { source: 'unit-dhc', target: 'unit-fcc', sourceHandle: 'handle-unit-dhc-6', targetHandle: 'handle-unit-fcc-1', product: 'bttm', volume: data.dhc_out_bttm_to_fcc },
        { source: 'unit-dhc', target: 'unit-coker', sourceHandle: 'handle-unit-dhc-6', targetHandle: 'handle-unit-coker-0', product: 'bttm', volume: data.dhc_out_bttm_to_coker },
        { source: 'unit-dhc', target: 'unit-vgo-blender', sourceHandle: 'handle-unit-dhc-6', targetHandle: 'handle-unit-vgo-blender-0', product: 'bttm', volume: data.dhc_out_bttm_to_vgo },
        
        { source: 'unit-isom', target: 'product-lnap', sourceHandle: 'handle-unit-isom-6', targetHandle: 'handle-product-lnap-0', product: 'lnap', volume: data.isom_out_lnap },
        { source: 'unit-isom', target: 'product-isomerate', sourceHandle: 'handle-unit-isom-7', targetHandle: 'handle-product-isomerate-0', product: 'isomerate', volume: data.isom_out_isomerate },
        
        { source: 'unit-reformer', target: 'product-hnap', sourceHandle: 'handle-unit-reformer-5', targetHandle: 'handle-product-hnap-0', product: 'hnap', volume: data.reformer_out_hnap },
        { source: 'unit-reformer', target: 'product-reformate', sourceHandle: 'handle-unit-reformer-6', targetHandle: 'handle-product-reformate-0', product: 'reformate', volume: data.reformer_out_reformate },
        
        { source: 'unit-fcc', target: 'product-lpg-fcc', sourceHandle: 'handle-unit-fcc-5', targetHandle: 'handle-product-lpg-fcc-0', product: 'lpg', volume: data.fcc_out_lpg },
        { source: 'unit-fcc', target: 'product-nap-fcc', sourceHandle: 'handle-unit-fcc-6', targetHandle: 'handle-product-nap-fcc-0', product: 'gasoline', volume: data.fcc_out_nap },
        { source: 'unit-fcc', target: 'unit-dht', sourceHandle: 'handle-unit-fcc-7', targetHandle: 'handle-unit-dht-2', product: 'lco', volume: data.fcc_out_lco },
        { source: 'unit-fcc', target: 'unit-fo-blender', sourceHandle: 'handle-unit-fcc-8', targetHandle: 'handle-unit-fo-blender-3', product: 'slurry', volume: data.fcc_out_slurry },
        
        { source: 'unit-coker', target: 'unit-isom', sourceHandle: 'handle-unit-coker-3', targetHandle: 'handle-unit-isom-4', product: 'lcn', volume: data.coker_out_lnap_to_isom },
        { source: 'unit-coker', target: 'unit-isom', sourceHandle: 'handle-unit-coker-4', targetHandle: 'handle-unit-isom-5', product: 'hcn', volume: data.coker_out_hnap_to_isom },
        { source: 'unit-coker', target: 'unit-reformer', sourceHandle: 'handle-unit-coker-3', targetHandle: 'handle-unit-reformer-3', product: 'lcn', volume: data.coker_out_lnap_to_reformer },
        { source: 'unit-coker', target: 'unit-reformer', sourceHandle: 'handle-unit-coker-4', targetHandle: 'handle-unit-reformer-1', product: 'hcn', volume: data.coker_out_hnap_to_reformer },
        { source: 'unit-coker', target: 'unit-dht', sourceHandle: 'handle-unit-coker-5', targetHandle: 'handle-unit-dht-1', product: 'lcgo', volume: data.coker_out_lcgo },
        { source: 'unit-coker', target: 'unit-fcc', sourceHandle: 'handle-unit-coker-6', targetHandle: 'handle-unit-fcc-4', product: 'hcgo', volume: data.coker_out_hcgo_to_fcc },
        { source: 'unit-coker', target: 'unit-fo-blender', sourceHandle: 'handle-unit-coker-6', targetHandle: 'handle-unit-fo-blender-5', product: 'hcgo', volume: data.coker_out_hcgo_to_fo },
        { source: 'unit-coker', target: 'product-coke', sourceHandle: 'handle-unit-coker-7', targetHandle: 'handle-product-coke-0', product: 'coke', volume: data.coker_out_coke },
    
        { source: 'unit-vgo-blender', target: 'product-vgo', sourceHandle: 'handle-unit-vgo-blender-3', targetHandle: 'handle-product-vgo-0', product: 'vgo', volume: data.vgo_out_vgo },
        { source: 'unit-fo-blender', target: 'product-fo', sourceHandle: 'handle-unit-fo-blender-6', targetHandle: 'handle-product-fo-0', product: 'fo', volume: data.fo_out_fo },
        
        { source: 'hidden-diesel', target: 'unit-fo-blender', sourceHandle: 'handle-hidden-diesel-0', targetHandle: 'handle-unit-fo-blender-4', product: 'diesel', volume: data.cdu_out_diesel_to_fo },
        { source: 'hidden-lvgo', target: 'unit-vgo-blender', sourceHandle: 'handle-hidden-lvgo-0', targetHandle: 'handle-unit-vgo-blender-1', product: 'lvgo', volume: data.vdu_out_lvgo_to_vgo },
        { source: 'hidden-hvgo', target: 'unit-vgo-blender', sourceHandle: 'handle-hidden-hvgo-0', targetHandle: 'handle-unit-vgo-blender-2', product: 'hvgo', volume: data.vdu_out_hvgo_to_vgo },
    
    ]
    
    const edges = generate_edges(data)
    const clean_nodes = ({ nodes, edges }) => {
        const Edges = edges.filter(({ volume }) => volume > 0)
        const Nodes = nodes.filter(({ volume }) => volume > 0)
        const handleSet = new Set()
        Edges.forEach(({ source, sourceHandle, target, targetHandle }) => {
            handleSet.add(`${source}-${sourceHandle.slice(-1)}`)
            handleSet.add(`${target}-${targetHandle.slice(-1)}`)
        })
        Nodes.forEach((node) => {
            const { id, handles } = node
            node.handles = handles ? handles.filter(({ idx }) => handleSet.has(`${id}-${idx}`)) : []
        })
        return { Nodes, Edges }
    }
    const { Nodes, Edges } = clean_nodes({ nodes, edges, data })
    return { Nodes, Edges }
}

export const RefineryDiagram = ({ data }) => {
    console.log(data)
    const size = useWindow()
    const instance = useReactFlow();
    const [begin, setBegin] = useState()
    const { Nodes, Edges } = process_data({ data, base_x: 100, base_y: 80 })
    const [nodes, setNodes, onNodesChange] = useNodesState(Nodes.map(({ id, type, width, height, x, y, product, bgColor, color, round=false, label, handles: shandles, labelHandles }) => {
        const handles = labelHandles || shandles.map(({ idx, position, type: htype, top, bottom, left, right }) => ({ id: `handle-${id}-${idx}`, type: htype, position, style: { top, bottom, left, right } }))
        return { id, type: 'general', position: { x, y }, dragHandle: '.no-drag',
        style: { width, height, backgroundColor: bgColor || colors[product], color: color || colors[product], border: type === 'unit' ? '1px solid black' : null, borderRadius: round ? '50px' : '0px' },
        data: {
            label,
            handles 
        }}
    }));
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);

    useEffect(() => {
        setNodes(nodes)
        setEdges(edges)
    }, [edges, nodes])

    useEffect(() => {
        Edges.forEach((edge) => {
            const { source, target, sourceHandle, targetHandle, product, marker=true, type='step' } = edge
            setEdges((eds) => addEdge({ source, sourceHandle, target, targetHandle, type, animated: true, 
            markerEnd: marker ? { type: MarkerType.ArrowClosed, color: colors[product] } : undefined, style: { strokeWidth: 3, stroke: colors[product] } }, eds))
        })
        setTimeout(() => {
            setBegin(true)
        }, 100)
    }, [])

    useEffect(() => {
        if (begin) {
            instance.setViewport({ x: (size.width-1050)/2, y: 0, zoom: 0.6 }, { duration: 0 })
        }
    }, [begin])

    return <div style={{ width: size.width-50, height: 810 }}>
        <ReactFlow nodes={nodes} edges={edges} onNodesChange={onNodesChange} onEdgesChange={onEdgesChange} nodeTypes={nodeTypes} >
        </ReactFlow>
    </div>
}

export const FlowWithProvider = (props) => {
    return <ReactFlowProvider>
        <RefineryDiagram {...props} />
    </ReactFlowProvider>
}
