import { useState } from 'react';
import { Bar } from '../../../components';
import { EiaWeekly } from '../EIA';

const options = [
    { name: 'eia_stock_crude', label: 'Crude' },
    { name: 'eia_stock_gasoline', label: 'Gasoline' },
    { name: 'eia_stock_distillate', label: 'Distillate' },
    { name: 'eia_stock_jet', label: 'Jet' },
    { name: 'eia_stock_residual', label: 'Residual' },
    { name: 'eia_stock_other', label: 'Other' },
]

export const EiaStocks = () => {
    const [tab, setTab] = useState('eia_stock_crude')
    const onSelectTab = event => {
        setTab(event)
    }
    return <div>
        <Bar options={options} cls={'thirdbar'} callback={onSelectTab} />
        <EiaWeekly type={tab} />
    </div>
}