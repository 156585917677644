export const NewsJson = ({ title, source, date, paragraph }) => {
    return (
        <div className='column-start px-1 pb-30'>
            <div className='mb-6'>
                <label className='ft-news-title'>{title}</label>
            </div>
            <div className='mb-6 row-between ft-news fs-17'>
                <label className='f-01 bold gray'>Source: {source}</label>
                <label className='f-01 bold gray'>{date}</label>
            </div>
            {paragraph && paragraph.map((text, i) => <div key={`key_news_json_paragraph_${i}`} className='mt-4 ft-news fs-17 line-height-news'><label className=''>{text}</label></div>)}
        </div>
    )
}