import { useEffect, useState } from 'react';
import { Tabs, Tab } from '@material-ui/core';

export const Bar = ({ options, cls='bar', callback }) => {

    const [tab, setTab] = useState(options[0].name)

    useEffect(() => {
        if (options && options[0]) setTab(options[0].name)
    }, [options])

    const handleChange = (event, value) => {
        setTab(value)
        callback(value)
    }

    return (
        <div className={cls}>
            <Tabs value={tab} onChange={handleChange} variant="scrollable" >
                {options.map(option => <Tab key={`key_bar_tab_${option.name}`} label={<label>{option.label}</label>} value={option.name} />)}
            </Tabs>
        </div>
    )
}