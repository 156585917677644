
import { useState, useContext, useRef } from 'react';
import { TextField, InputAdornment, IconButton, Modal, Checkbox, ListItemText, FormControl, InputLabel, Select, MenuItem, Button } from '@mui/material';
import { Search as SearchIcon, Clear as ClearIcon, Tune as TuneIcon } from '@mui/icons-material';
import moment from 'moment'
import { list2, getMonthRange } from '../../../services'
import { DispatchContext } from "../../../reducers";
import { Message } from '../../../components'


export const NewsSearch = ({ callback }) => {

    const dispatch = useContext(DispatchContext)
    const childRef = useRef()
    const [input, setInput] = useState()
    const [open, setOpen] = useState(false)
    const starts = getMonthRange('2023-08-01', moment().format('YYYY-MM-01'))
    const ends = getMonthRange('2023-08-01', moment().format('YYYY-MM-01'))
    const [start, setStart] = useState(moment().subtract(1, 'month').format('YYYY-MM-01'))
    const [end, setEnd] = useState(moment().format('YYYY-MM-01'))
    const [source, setSource] = useState(['Reuters', 'Platts', 'Argus', 'EA', 'Bloomberg'])
    const [take, setTake] = useState(10)

    const onSearch = async () => {
        if (!input) return
        dispatch({ type: 'spin on' })
        const data = await list2('news_search', null, { query: input, start, end, source, take })
        dispatch({ type: 'spin off' })
        callback(data)
    }

    const onKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault()
            onSearch()
        }
    }

    const onClear = () => {
        setInput('')
        callback(null)
    }

    const onSetting = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    const onSelectStart = (event) => {
        setStart(event.target.value)
    }
    
    const onSelectEnd = (event) => {
        setEnd(event.target.value)
    }

    const onSelectSource = (event) => {
        setSource(event.target.value)
    }

    const onSelectTake = (event) => {
        setTake(event.target.value)
    }

    const confirm = () => {
        if (moment(start).isAfter(moment(end))) {
            childRef.current.showError('start date is later than end date')
        } else if (source.length === 0) {
            childRef.current.showError('source is empty')
        } else {
            setOpen(false)
            onSearch()
        }
    }

    return (
        <div className='row-left w-full mb-3'>
            <div className='w-full bg-white row'>  
                <TextField multiline className='text-input' size="small" placeholder={'search topic'} value={input} variant="outlined" InputProps={{ 
                    startAdornment: (<InputAdornment position="start"><IconButton onClick={onSearch}><SearchIcon /></IconButton></InputAdornment>),
                    endAdornment: (<InputAdornment position="end"><IconButton onClick={onClear}><ClearIcon /></IconButton></InputAdornment>)  
                }} onInput={(event) => setInput(event.target.value)} onKeyDown={onKeyDown} />
                <IconButton className='top--3' onClick={onSetting}><TuneIcon /></IconButton>
            </div>
            <Modal open={open} onClose={handleClose} className='modal' closeAfterTransition
                style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'center', marginTop: 60 }}>
                <div className='w-90p mw-500 p-2 row-start bg-white' >
                    <Message ref={childRef} />
                    <div className='row-between my-4'>
                        <FormControl>
                            <InputLabel>Start</InputLabel>
                            <Select value={start} onChange={onSelectStart} >
                                {starts.map((month) => (
                                    <MenuItem key={`key_news_search_start_${month}`} value={month}>
                                        {moment(month).format('MMM YYYY')}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl>
                            <InputLabel>End</InputLabel>
                            <Select value={end} onChange={onSelectEnd} >
                                {ends.map((month) => (
                                    <MenuItem key={`key_news_search_end_${month}`} value={month}>
                                        {moment(month).format('MMM YYYY')}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div> 
                    <div className='row-between my-4'>
                        <FormControl>
                            <InputLabel sx={{ minWidth: '200px' }}>Sources</InputLabel>
                            <Select multiple value={source} onChange={onSelectSource} renderValue={(selected) => {
                                if (selected.length === 5) {
                                    return 'All sources'
                                } else if (selected.length === 1) {
                                    return selected[0]
                                } else if (selected.length === 0) {
                                    return `No source selected`
                                } else {
                                    return `${selected.length} sources`
                                }
                            }}>
                                {['Reuters', 'Platts', 'Argus', 'EA', 'Bloomberg'].map((s) => (
                                    <MenuItem key={`key_news_search_source_${s}`} value={s}>
                                        <Checkbox checked={source.indexOf(s) > -1} />
                                        <ListItemText primary={s} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl>
                            <InputLabel>Results</InputLabel>
                            <Select value={take} onChange={onSelectTake} >
                                {[10, 20, 50, 100].map((t) => (
                                    <MenuItem key={`key_news_search_take_${t}`} value={t}>
                                        {t}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                    <div className='row-center mt-6'>
                        <Button variant='outlined' onClick={confirm} className='ml-2'>Confirm</Button >
                    </div>
                </div>
            </Modal>
        </div>
    )
}