import React, { useState, useEffect } from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import useWindow from '../../hooks/useWindow'
import { singleDoubleChartWidth, chartHeight, post2 } from '../../services';
import Tree from 'highcharts/modules/treemap';
import exportingModule from "highcharts/modules/exporting";
import exportDataModule from 'highcharts/modules/export-data'
Tree(Highcharts)
exportingModule(Highcharts);
exportDataModule(Highcharts);


export const SentimentTreemapChart = ({ param }) => {
    const [options, setOptions] = useState({})
    const size = useWindow()

    useEffect(() => {
        const { title, data, isSingle } = param
        const options = {
            chart: {
                type: 'treemap',
                width: singleDoubleChartWidth(size.width, isSingle),
                height: chartHeight(size.height),
            },
            exporting: {
                buttons: {
                    contextButton: {
                        enabled: true,
                        align: 'left',
                        menuItems: ["downloadPNG", "downloadPDF", "downloadCSV", "separator",
                            {   
                                text: 'Send Email', 
                                onclick: function() {
                                    const csv = this.getCSV()
                                    const html = this.getSVG()
                                    const title = this.getFilename()
                                    post2('user/export_file', { csv, html, title })
                                } 
                            }
                        ]
                    }
                }
            },
            title,
            series: [{
                layoutAlgorithm: 'squarified',
                alternateStartingDirection: true,
                borderColor: '#fff',
                borderRadius: 6,
                borderWidth: 2,
                dataLabels: {
                    useHTML: true,
                    formatter: function() { 
                        if (!this.point.options.value) return this.key
                        return `<div>${this.key}</div><div>${this.point?.options?.sentiment?.toFixed(0)}</div>`
                    }
                },
                levels: [{
                    level: 1,
                    dataLabels: {
                        enabled: true,
                        align: 'left',
                        verticalAlign: 'top',
                        style: {
                            fontSize: '15px',
                            fontWeight: 'bold'
                        }
                    }
                }],
                data
            }]
        }
        setOptions(options)
        // console.log(options)
    }, [param, size])

    return <div className='row-center'>
        <HighchartsReact highcharts={Highcharts} options={options} />
    </div>

}