import { Divider, Grid } from "@material-ui/core";
import { useEffect, useState } from "react"
import useWindow from "../../hooks/useWindow"
import { list2 } from '../../services/Api';
import { PieChartMarketShare, PieChartProduct, Icon } from "../../components"
import { TweetUnits } from "../News";

//MUI List
import { List, ListItem, ListItemText, ListItemIcon } from '@mui/material';

//MUI Typography
import Typography from '@material-ui/core/Typography';

//MUI Card
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

//MUI Table
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

//MUI styles
import useProfileStyles from '../../hooks/useProfileStyles'
import useTableStyles from '../../hooks/useTableStyles'


export const keys = ['companyProfileSnapshot', 'organizationalStructure', 'globalAssetsSnapshot', 'boardMembers', 'financialPerformance', 'companyAssets', 'energyTransition', 'tweet']

export const CompanyDetailSector = ({ id, content }) => {
    const { items } = content
    return (
        <div id={id} className='column-left mb-2 p-1 scroll-company-profile'>
            {id === 'tweet' ? <TweetUnits data={items} /> : items.map((item, i) => {
                return <CompanyDetailItem key={`key_company_profile_item_${i}`} item={item} id={id} />
            })}
        </div>
    )
}

export const CompanyDetailItem = ({ item, id }) => {
    const { headline, subline, title, subtitle, caption, type, data, keys } = item
    const classes = useProfileStyles();

    return (
        <div className='w-full my-2'>
            {type !== 'market_intelligence' && <>{headline && 
                <Card elevation={3} className={classes.headline}>
                    <CardContent classes={{
                        root: classes.readjustPadding
                    }}>
                        <Typography variant="h5">{headline}</Typography>
                        {subline && <Typography className={classes.subline}>{subline}</Typography>}
                    </CardContent>
                </Card>}
            {title && <Card elevation={3} className={classes.title}> 
                        <CardContent classes={{
                            root: classes.readjustPadding
                        }}>
                            <Typography variant="h6">{title}</Typography>
                        </CardContent>
                      </Card>}
            {subtitle && <Card elevation={3} className={classes.subtitle}>
                <CardContent classes={{
                    root: classes.readjustPadding
                }}>
                    <Typography variant="h6">{subtitle}</Typography>
                </CardContent>
            </Card>}</>}
            {caption && <div className='white bg-black bold-5 f-02'>{caption}</div>}
            {type === 'text' && <CompanyDetailItemText data={data} id={id} />}
            {type === 'image' && <CompanyDetailItemImages data={data} id={id} />}
            {type === 'object' && <CompanyDetailItemObject data={data} id={id} />}
            {type === 'pie' && <CompanyDetailItemPie data={data} id={id} />}
            {type === 'table' && <CompanyDetailItemTable data={data} keys={keys} id={id} />}
            {type === 'html' && <ProfileDetailItemHtml data={data} id={id} />}
        </div>
    )
}
export const CompanyDetailItemText = ({ data, id }) => {
    return (
        <List>
        {data.map((dat, i) => {
            return <div>
                        <ListItem key={`company_detail_item_text_${id}_${i}`}>
                            <ListItemIcon>
                                <Icon icon='faCaretRight' />
                            </ListItemIcon>
                            <ListItemText primary={dat}></ListItemText> 
                        </ListItem>
                        {i===data.length-1 ?  null : <Divider/>}
                    </div>
        })}
        </List>
       
    )
}
export const CompanyDetailItemImages = ({ data }) => {
    return (
        <Grid container className='row-center'>
            {data.map((name) => (
                <Grid item sm={6} md={4} lg={3} key={`company_detail_item_image_${name}`}>
                    <CompanyDetailItemImage name={name} />
                </Grid>
            ))}
        </Grid>
    )
}
export const CompanyDetailItemImage = ({ name }) => {
    const size = useWindow()
    const [src, setSrc] = useState()
    const width = size.width - 10
    useEffect(() => {
        async function fetch() {
            if (!name) return
            const { data: buffer } = await list2('image/download', name)
            const base64 = Buffer.from(buffer).toString('base64')
            const src = `data:image/png;base64,${base64}`
            setSrc(src)
        }
        fetch()
    }, [])
    return (
        <div className='row-center'>
            {src && <img src={src} style={{'maxWidth': `${width}px`, 'maxHeight': '600px'}} alt={name} />}
        </div>
    )
}
export const CompanyDetailItemObject = ({ data, id }) => {
    const { width } = useWindow()
    return (
        <div className=''>
            {data.map((dat, i) => {
                const { key, value, logo, pie } = dat
                return (
                <div key={`company_detail_item_object_${id}_${key}_${i}`} className='row-stretch w-full'>
                    <div className='row-center mr-2 mb-2 mw-60 nw-60 white bg-green1'>
                        <span className={`rotate fs-20`}>{key}</span>
                    </div>
                    {logo && <div className='row-center'>
                        <CompanyDetailItemImage name={logo} maxWidth='60px' maxHeight='60px' marginRight='10px' />
                    </div>}
                    {value && <div className='w-full mb-2'>
                        <CompanyDetailItemText data={value} id={key} />
                    </div>}
                    {pie && <div className='w-full mb-2'>
                        <CompanyDetailItemLogo data={pie} />
                    </div>}
                </div>
            )})}
        </div>
    )
}
export const CompanyDetailItemLogo = ({ data }) => { 
    const size = useWindow()
    const [width, setWidth] = useState(Math.min(size.width - 10, 800))
    useEffect(() => {
        const wi = Math.min(size.width - 140, 800)
        setWidth(wi)
    }, [size])
    return (
        <div className='row-stretch w-full'>
            <PieChartMarketShare data={data} width={width} />
        </div>
    )
}
export const CompanyDetailItemPie = ({ data }) => { 
    const size = useWindow()
    const [width, setWidth] = useState(Math.min(size.width - 10, 800))
    useEffect(() => {
        const wi = Math.min(size.width - 10, 800)
        setWidth(wi)
    }, [size])
    return (
        <div className='row-center'>
            <PieChartProduct data={data} width={width} height={400} />
        </div>
    )
}

export const CompanyDetailItemTable = ({ data, keys: defaultKeys, id}) => {
    const keys = defaultKeys || Object.keys(data[0])
    const classes = useTableStyles();

    return (
        <div className='w-full my-2 row-center'>
            <TableContainer component={Paper}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            {
                                keys.map(key => <TableCell align="center" className={classes.headerCell} component="th" key={`company_detail_item_table_header_${id}_${key}`}>{key}</TableCell> )
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {data.map((dat, i) => (
                        <TableRow key={`company_detail_item_table_row_${id}_${i}`}>
                            {keys.map(key => <TableCell align="center" component="th" className={classes.tableCell} key={`company_detail_item_table_col_${id}_${i}_${key}`}>{dat[key]}</TableCell>)}
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}
export const ProfileDetailItemHtml = ({ data }) => {
    return <div className="w-full my-2 row-center" dangerouslySetInnerHTML={{__html: data }}></div>
}