import { useState, useEffect } from "react";
import { list2 } from '../services/Api'
import background from '../pics/login.png'
import { Icon } from "../components";
import Cookies from 'js-cookie'

export const Home = () => {
    const [name, setName] = useState('')
    
    useEffect(() => {
        async function fetch () {
            const name = Cookies.get('name')
            if (name) {
                setName(name)
            } else {
                const data = await list2('user')
                setName(data.name)
            }
        }
        fetch()
    }, [])

    return (
        <div style={{ 
                backgroundImage: `url(${background})`, 
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                width: '100vw',
                height: '100vh',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-end',
                justifyContent: 'flex-start',
            }}>
            {name && <div className='welcolm-message w-full column-center'>
                <div className="fs-30">Welcome</div>
                <div className="f-06">{name}</div>
                <div className="f-02 mt-3">You have 0 subscriptions</div>
                <div className="f-02 mt-1">Click <label className="bg-dark_blue white px-1"><Icon icon='faBars' size='sm' /></label> <label className="px-1 border-1"><Icon icon='faBookmark' size='sm' /></label> to setup subscriptions</div>
            </div>}
        </div>
    );
}