import { useEffect, useState } from 'react';
import { List, ListItem, ListItemButton, ListItemText, Checkbox, Divider } from '@mui/material';
import moment from 'moment'
import { Icon } from '..';

const FileIcon = ({ type }) => {
    const icon = type === 'text/csv' ? 'faFileCsv' : type === 'application/pdf' ? 'faFilePdf' : type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ? 'faFileExcel' : 'faFile'
    return <Icon icon={icon} size='1x' color='green' />
}

export const FileList = ({ files, external = [], callback }) => {
    const [checked, setChecked] = useState([...Array(files.length).keys()]);
    useEffect(() => {
        callback([...files, ...external].filter((file, index) => checked.includes(index)))
    }, [checked])
    const handleToggle = (index) => () => {
        const currentIndex = checked.indexOf(index);
        const newChecked = [...checked];
        if (currentIndex === -1) {
            newChecked.push(index);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        setChecked(newChecked);
    };
    const toggleSelectAll = () => () => {
        if (checked.length === files.length + external.length) {
            setChecked([])
        } else {
            setChecked([...Array(files.length + external.length).keys()])
        }
    };
    return (
        <List dense sx={{ width: '100%', maxWidth: 500, bgcolor: 'background.paper' }}>
            {files.length > 1 && <ListItem key={`key_filelist_select_all`} disablePadding secondaryAction={<Checkbox edge="end" onChange={toggleSelectAll()} checked={checked.length === files.length} inputProps={{ 'aria-labelledby': 'checkbox_label_id_select_all' }} />}>
                <ListItemButton>
                    <Icon icon='faList' size='1x' color='green' />&nbsp;&nbsp;
                    <ListItemText id={'checkbox_label_id_select_all'} primary={'Select All'} />
                </ListItemButton>
            </ListItem>}
            {files.map(({ filename, type }, idx) => {
                const labelId = `checkbox_label_id_${idx}`;
                return (
                    <ListItem key={`key_filelist_${idx}`} disablePadding secondaryAction={<Checkbox edge="end" onChange={handleToggle(idx)} checked={checked.indexOf(idx) !== -1} inputProps={{ 'aria-labelledby': labelId }} />}>
                        <ListItemButton>
                            <FileIcon type={type} />&nbsp;&nbsp;
                            <ListItemText id={labelId} primary={filename} />
                        </ListItemButton>
                    </ListItem>
                );
            })}
            {external.length > 0 && <Divider variant="fullWidth" />}
            {external.map(({ name, type, extension, size, lastupdate }, idx) => {
                const labelId = `checkbox_label_id_${idx + files.length}`;
                return (
                    <ListItem key={`key_filelist_${idx + files.length}`} disablePadding secondaryAction={<Checkbox edge="end" onChange={handleToggle(idx + files.length)} checked={checked.indexOf(idx + files.length) !== -1} inputProps={{ 'aria-labelledby': labelId }} />}>
                        <ListItemButton>
                            <FileIcon type={type} />&nbsp;&nbsp;
                            <ListItemText id={labelId} primary={`${name}.${extension}`} secondary={<><label className='mr-6'>{moment(lastupdate).format('YYYY-MM-DD')}</label><label className=''>{size}</label></>} />
                        </ListItemButton>
                    </ListItem>
                );
            })}
        </List>
    );
}