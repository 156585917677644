import React, { useState, useEffect, useContext } from 'react';
import { list2, post2, pascal } from '../services'
import { List, ListItem , ListItemAvatar, ListItemText, ListItemSecondaryAction, Avatar, IconButton, Switch } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { green, grey } from '@material-ui/core/colors';
import { DispatchContext } from "../reducers";
import { Icon } from '../components'
import { Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

export const mapping = {
    'News': { icon: 'faNewspaper', subtitle: 'News' },
    'News - General': { icon: 'faNewspaper', subtitle: 'News' },
    'News - Market': { icon: 'faNewspaper', subtitle: 'News' },
    'News - Region': { icon: 'faNewspaper', subtitle: 'News' },

    'Market View': { icon: 'faChartLine', subtitle: 'Price' },
    'Forward Prices': { icon: 'faChartLine', subtitle: 'Price' },
    'Forward Spreads': { icon: 'faChartLine', subtitle: 'Price' },

    'Refinery Model': { icon: 'faIndustry', subtitle: 'Refinery' },
    'Refinery Capacity': { icon: 'faIndustry', subtitle: 'Refinery' },
    'Refinery Turnarounds': { icon: 'faIndustry', subtitle: 'Refinery' },
    'Refinery Runs': { icon: 'faIndustry', subtitle: 'Refinery' },
    'Supply Demand Model': { icon: 'faGlobeAmericas', subtitle: 'Supply & Demand' },
    'Supply Demand Balance': { icon: 'faGlobeAmericas', subtitle: 'Supply & Demand' },

    'Flows Model': { icon: 'faShip', subtitle: 'Flows & Fixtures' },
    'Flows Data': { icon: 'faShip', subtitle: 'Flows & Fixtures' },
    'Fixtures Data': { icon: 'faShip', subtitle: 'Flows & Fixtures' },

    'Window Deal Data': { icon: 'faHandshake', subtitle: 'Window Deal' },
    'Window Deal Report': { icon: 'faHandshake', subtitle: 'Window Deal' },
}

const ColorSwitch = withStyles({
    switchBase: {
      color: grey[300],
      '&$checked': {
        color: green[500],
      },
      '&$checked + $track': {
        backgroundColor: green[300],
      },
    },
    checked: {},
    track: {},
})(Switch);

export const Subscription = ({ param }) => {

    const dispatch = useContext(DispatchContext)
    const [subscribes, setSubscribes] = useState([])
    const [changed, setChanged] = useState(false)

    useEffect(() => {
         fetch()
    }, [])

    const fetch = async () => {
        dispatch({ type: 'spin on' })
        const { result } = await list2('subscription', 'all')
        setChanged(false)
        setSubscribes(result)
        dispatch({ type: 'spin off' })
    }

    const onSave = async () => {
        const updated = subscribes.reduce((pre, cur) => {
            const checked = cur.children.filter(sub => sub.checked)
            return [...pre, ...checked]
        }, [])
        dispatch({ type: 'spin on' })
        await post2('subscription/update', updated)
        return fetch()
    }

    const handleToggle = subscription => (event, value) => {
        setChanged(true)
        const newSubs = [...subscribes]
        newSubs.find(tab => tab.tab === subscription.tab).children.find(sub => sub.id === subscription.id).checked = value
        setSubscribes(newSubs)
    }

    return (
        <div className='manage p-1 mb-6'>
            <div className='row-right mr-3'>
                <IconButton edge="end" size="small" color="primary" onClick={onSave} disabled={!changed}>
                    <Icon icon='faSave' /><label className='ml-2 f-04'>Save</label>
                </IconButton>
            </div>
            <div className='manage__subscribes'>
                <List dense={true}>
                    {subscribes.map(tab => 
                        <Accordion key={`key_subscription_accordion_${tab.tab}`} >
                            <AccordionSummary expandIcon={<ExpandMoreIcon />} >
                                <ListItem>
                                    <ListItemAvatar>
                                        <Avatar className={'manage__avatar__tab'}>
                                            <Icon icon={mapping[tab.tab].icon} size={'sm'} />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary={tab.tab} secondary={mapping[tab.tab].subtitle} />
                                </ListItem>
                            </AccordionSummary>
                            <AccordionDetails className='column'>
                                {tab.children.map(child => 
                                    <ListItem key={`key_subscribes_list_item_${child.id}`}>
                                        <ListItemAvatar>
                                            <Avatar className={child.checked ? 'manage__avatar__checked' : ''}>
                                                <Icon icon={mapping[tab.tab].icon} size={'sm'} />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText primary={child.name} secondary={pascal(child.category)} />
                                        <ListItemSecondaryAction className='manage__subscribes__action'>
                                            <IconButton edge="end">
                                                <ColorSwitch onChange={handleToggle(child)} checked={child.checked}/>
                                            </IconButton>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                )}
                            </AccordionDetails>
                        </Accordion>
                    )}
                </List>
            </div>
        </div>

    )
}