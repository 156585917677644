import { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { Tabs, Tab } from '@material-ui/core';

export const LinkBar = ({ options, cls='bar', callback }) => {

    const [tab, setTab] = useState()

    useEffect(() => {
        if (options && options[0]) setTab(options[0].name)
    }, [options])

    const handleChange = (event, value) => {
        setTab(value)
        callback(value)
    }

    return (
        <div className={cls}>
            <Tabs value={tab} onChange={handleChange} variant="scrollable" >
                {options && options.map(({ name, label, children }) => <Tab key={`key_linkbar_tab_${name}`} label={<Link className={`no-decoration dark_gray ${tab === name ? 'active_link' : ''}`} to={`/${name}/${children && children.length > 0 ? children[0].name : ''}`}>{label}</Link>} value={name} />)}
            </Tabs>
        </div>
    )
}