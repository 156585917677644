import { makeStyles } from '@material-ui/core/styles';

const useProfileStyles = makeStyles({
    root: {
      maxWidth: '100%'
    },
  
    readjustPadding: {
      padding: '5px',
      '&:last-child': {
          padding: '5px',
          paddingBottom: '5px'
      }
    },
  
    headline: {
      color: 'white',
      backgroundColor: '#55a7d2',
      fontWeight: 500,
      fontSize: '28px',
      marginBottom: '0.5rem',
    },
  
    subline: {
      color: 'white',
      fontWeight: 500,
      fontSize: '14px',
    },
  
    title: {
      color: 'white',
      backgroundColor: '#00632e',
      fontWeight: 500,
      fontSize: '20px',
      width: '100%',
      marginBottom: '10px'
    },
  
    subtitle: {
      color: 'white',
      backgroundColor: '#00b050',
      fontWeight: 500,
      fontSize: '20px',
      width: '100%',
      marginBottom: '10px',
    }
  });

  export default useProfileStyles;