import { useState, useContext, useRef } from 'react';
import { useParams } from "react-router-dom";
import { Button, Select, MenuItem } from '@material-ui/core';
import moment from 'moment';
import { readXlsx } from '../../services/Xlsx'
import { list2, post2, pascal } from '../../services'
import { Message, UploadGrid } from '../../components';
import { DispatchContext } from "../../reducers";

export const UploadPage = () => {
    const { type } = useParams()
    const childRef = useRef()
    const dispatch = useContext(DispatchContext)
    const [data, setData] = useState([])
    const [dataType, setDataType] = useState()
    const [subType, setSubType] = useState('naphtha')
    
    const prepareBalance = async (body) => {
        const countries = await list2('country')
        const balanceMap = new Map()
        const notFoundCountries = []
        const { table } = body[0]
        const dateArrays = [];
        Object.entries(table.find((item) => item[1] === 'Time')).forEach(([key, value]) => {
            if (moment(value, 'MMM-YY', true).isValid()) dateArrays.push([key, moment(value, 'MMM-YY').format('YYYY-MM-DD')])
        })
        body.forEach(({ name, table }) => {
            const field = name === 'REFIN' ? 'intake' : name === 'PDN' ? 'production' : name === 'CSN' ? 'consumption' : 'yield'
            table.forEach((item) => {
                const foundCountry = countries.find(({ names }) => names.includes(item[1]))
                if (foundCountry) {
                    const { id: country, region: { id: region }, subRegion: { id: subRegion } } = foundCountry
                    dateArrays.forEach(([idx, date]) => {
                        const key = `${date}${country}`
                        const exist = balanceMap.get(key)
                        const value = !item[idx] ? 0 : field === 'yield' ? +item[idx].replace('%', '') > 100 ? 0 : +(item[idx].replace('%', '')/100).toFixed(4) : +item[idx].replace(',', '')
                        if (exist) {
                            balanceMap.set(key, { ...exist, [field]: value })
                        } else {
                            const obj = { date, country, subRegion, region, intake: 0, yield: 0, production: 0, consumption: 0 }
                            balanceMap.set(key, { ...obj,  [field]: value })
                        }
                    })
                } else if (!notFoundCountries.includes(item[1])) {
                    notFoundCountries.push(item[1])
                }
            })
        })
        return [
            { name: 'balance', table: Object.values(Object.fromEntries(balanceMap)) }
        ]
    }
    
    const handleUpload = event => {
        dispatch({ type: 'spin on' })
        const files = event.target.files
        const file = files[0]
        if (!file) {    
            dispatch({ type: 'spin off' })
            return
        }
        const reader = new FileReader()
        reader.onload = async function(e) {
            const data = new Uint8Array(e.target.result)
            setDataType(type)
            const result = type === 'balance' ? readXlsx(data, ['REFIN', 'PDN', 'CSN', 'YIELD']) : readXlsx(data)
            if (type === 'balance') {
                console.log(result)
                const prepared = await prepareBalance(result)
                console.log(prepared)
                setData(prepared)
            } else {
                console.log(result)
                setData(result)
            }
            dispatch({ type: 'spin off' })
        }
        reader.readAsArrayBuffer(file)
    }

    const handleSubmit = async () => {
        dispatch({ type: 'spin on' })
        const result = await post2(`${type}${type === 'balance' ? `/${subType}` : ''}/upload`, data)
        dispatch({ type: 'spin off' })
        if (result.statusCode === 200) {
            childRef.current.showSuccess('Upload Success')
        } else {        
            childRef.current.showError(result.error)
        }
    }

    const onSelectSubType = event => {
        setData([])
        setSubType(event.target.value)
    }
    
    return (
        <div className=''>
            <div className='row-center fs-20 bold mt-3'>
                <label>{pascal(type)}</label>
            </div>
            <Message ref={childRef} />
            <div className='row-left mt-2 pl-4'>
                <div className='w-150 mr-1'>
                    <Button variant="contained" component="label" color="primary" fullWidth>
                        Upload File
                        <input type="file" hidden onChange={handleUpload} />
                    </Button>
                </div>
                <div className='w-150 mr-3'>
                    <Button disabled={!data || data.length === 0} variant="outlined" color="secondary" fullWidth onClick={handleSubmit} >
                        Submit
                    </Button>
                </div>
                {type === 'balance' && <div className='w-150'>
                    <Select value={subType} onChange={onSelectSubType}>
                        <MenuItem value="naphtha">Naphtha</MenuItem>
                        <MenuItem value="gasoline">Gasoline</MenuItem>
                        <MenuItem value="gasoil">Gasoil</MenuItem>
                        <MenuItem value="jet">Jet</MenuItem>
                        <MenuItem value="fuel">Fuel Oil</MenuItem>
                    </Select>
                </div>}
            </div>
            {type === dataType && data.map(({ name, table }, i) => 
                <div key={`key_upload_grid_${i}`} className='column-center mt-3 mb-3 pl-3'>
                    <div className='row-left w-full bold fs-16 mb-2 pl-3'>
                        <label>{name}</label>
                    </div>
                    <UploadGrid data={table} />
                </div>
            )}
        </div>
    )
}