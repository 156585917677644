import React, { useState, useEffect } from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import useWindow from '../../hooks/useWindow'
import { singleChartWidth, chartHeight, post2, list2 } from '../../services';
import moment from 'moment'
import exportingModule from "highcharts/modules/exporting";
import exportDataModule from 'highcharts/modules/export-data'
exportingModule(Highcharts);
exportDataModule(Highcharts);

export const ProductionChart = ({ data }) => {

    const [options, setOptions] = useState({})
    const size = useWindow()

    const [exp, setExp] = useState(false)
    useEffect(() => {
        async function fetch() {
            const auth = await list2('auth')
            setExp(auth === true)
        }
        fetch()
    }, [])

    useEffect(() => {
        if (!data) return
        const { categories, series, title, subtitle } = data
        const options = {
            chart:  { 
                width: singleChartWidth(size.width),
                height: chartHeight(size.height)
            },
            exporting: exp ? {
                buttons: {
                    contextButton: {
                        enabled: true,
                        align: 'left',
                        menuItems: ["downloadPNG", "downloadPDF", "downloadCSV", "separator",
                            {
                                text: 'Send Email',
                                onclick: function() {
                                    const csv = this.getCSV()
                                    const html = this.getSVG()
                                    const title = this.getFilename()
                                    post2('user/export_file', { csv, html, title })
                                }
                            }
                        ]
                    }
                }
            } : {
                buttons: {
                    contextButton: {
                        enabled: false
                    }    
                }
            },
            title: {
                text: title,
                useHtml: true,
                align: 'center'
            },
            subtitle: {
                text: subtitle,
                align: 'right'
            },
            xAxis: {
                title: { text: '' },
                categories: categories.map((date) => moment(date).format('MMM YY'))
            },
            legend:{
                maxHeight: 100
            },
            yAxis: {
                title: {
                    text: ''
                },
                stackLabels: {
                    enabled: true,
                    crop: false,
                    overflow: 'allow',
                    style: {
                        fontWeight: 'bold',
                        color: 'gray'
                    }
                }
            },
            plotOptions: {
                column: {
                    stacking: 'normal',
                    dataLabels: {
                        enabled: false
                    }
                }
            },
            series: series
        }
        setOptions(options)
    }, [data, size, exp]);

    return (<div>
        <HighchartsReact
            highcharts={Highcharts}
            options={options}
        />
    </div>)
}