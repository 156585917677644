import { useState, useEffect, useContext } from 'react'
import { Accordion, AccordionSummary, AccordionDetails, ListItem, ListItemAvatar, ListItemText, Avatar } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Group } from '../pages/Dashboard'
import { list2, pascal } from '../services'
import { DispatchContext } from '../reducers'
import { Home, Event, PriceLive } from '../layout'
import { Icon } from '../components'
import { mapping } from './Subscription'

export const Dashboard = () => {

    const dispatch = useContext(DispatchContext)
    const [event, setEvent] = useState()
    const [subscriptions, setSubscriptions] = useState([])
    const [categories, setCategories] = useState([])
    const [tabs, setTabs] = useState([])
    const [expanded, setExpanded] = useState(-1);
    const [exp, setExp] = useState(false)

    useEffect(() => {
        async function fetch () {
            dispatch({ type: 'spin on' })
            const [event, { result, categories, tabs }, auth] = await Promise.all([
                list2('countryProfile', 'event'),
                list2('subscription'),
                list2('auth')
            ])
            dispatch({ type: 'spin off' }) 
            if (event && event.name) {
                setEvent(event)
            } 
            setSubscriptions(result)
            setCategories(categories)
            setTabs(tabs)
            setExp(auth === true)
        }
        fetch()
    }, [])

    const handleChange = i => (event, newExpanded) => {
        setExpanded(newExpanded ? i : -1);
    };
    
    return (
        <div className='mb-6'>
            <a name="_top"></a>
            {event ? <Event event={event} /> : <PriceLive />}
            {/* {!exp && !event && categories.length === 0 && <Home />} */}
            {/* {!exp && !event && categories.map((category, i) => {
                return (
                <Accordion expanded={expanded === i} onChange={handleChange(i)} key={`key_subscription_accordion_${i}`} >
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} >
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar className={'bg-dark_blue'} variant='rounded' sx={{ width: 120, height: 120 }}>
                                    <Icon icon={mapping[pascal(category)].icon} size={'sm'} />
                                </Avatar>
                            </ListItemAvatar>
                            <label className='f-02 bolder dark_blue'>{pascal(category).toUpperCase()}</label>
                        </ListItem>
                    </AccordionSummary>
                    <AccordionDetails className='column edge'>
                        {expanded === i && <Group key={`key_subscription_category_${i}`} category={category} subscriptions={subscriptions[category]} />}
                    </AccordionDetails>
                </Accordion>)}
            )} */}
        </div>

    )
}
