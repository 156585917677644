import { useParams } from "react-router-dom";
import { FloatingModel, OnshoreModel } from '.'
import { DispatchContext } from "../../../reducers";

export const StorageHub = ({ type: initType }) => {
    const { type: path } = useParams()
    const type = initType || path

    return (
        <div>
            <div className="row-center">
                {type === 'floating' && <FloatingModel />}
                {type === 'onshore' && <OnshoreModel />}
            </div>
        </div>
    )
}