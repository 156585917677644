import { useState, useEffect, useContext } from 'react';
import { list2 } from '../../../services/Api'
import { WindowDealGrid } from '../../../components';
import { DispatchContext } from "../../../reducers";

export const WindowDealInfo = () => {

    const dispatch = useContext(DispatchContext)
    const [data, seData] = useState()

    useEffect(() => {
        async function fetch () {
            dispatch({ type: 'spin on' })
            const data = await list2('windowDeal', 'info')
            dispatch({ type: 'spin off' })
            seData(data)
        }
        fetch()
    }, [])

    return <div className='p-2 row-center mt-2'>{data && <WindowDealGrid data={data} />}</div>

}

export const WindowDealInfoByType = ({ type }) => {

    const dispatch = useContext(DispatchContext)
    const [data, setData] = useState()
    const [date, setDate] = useState()

    useEffect(() => {
        async function fetch () {
            dispatch({ type: 'spin on' })
            const raw = await list2('windowDeal', 'info', { type })
            dispatch({ type: 'spin off' })
            if (raw.length > 0) {
                const last = raw[0]
                setDate(last.value)
                const data = raw.filter(({ date }) => date === last.value)
                setData(data)
            }
        }
        fetch()
    }, [type])

    return <div className=''>
            {data ? <div key={`key_window_deal_block_${type}`}>
                <label className='row-between bold f-04 mt-2 px-2'>{type}{date && <label>{date}</label>}</label>
                <div className='row-center mt-2'>
                    <WindowDealGrid data={data} />
                </div>
            </div> : <label>No Trades Reported</label>}
        </div>

}