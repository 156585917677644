import React, { useState, useEffect } from 'react';
import { Switch, Route, Redirect } from "react-router-dom";
import { LinkBar, LinkSubBar } from '../components'
import { useConfig } from '../hooks';
import Models from '../pages/Model';

export const Model = () => {
    const { model: options } = useConfig()
    const [tab, setTab] = useState()
    useEffect(() => {
        if (options) setTab(options[0])
    }, [options])
    const onSelectTab = event => {
        setTab(options.find(option => option.name === event))
    }
    return (
        <div>
            <LinkBar options={options} callback={onSelectTab} />
            {tab && tab.children && tab.children.length > 0 && <LinkSubBar category={tab.name} options={tab.children} />}
            <Switch>
                {options && <>
                    {options.map(({ name, path, component }) => {
                        const Component = Models[component]
                        return <Route key={`key_route_model_${name}`} path={path} children={<Component />} />
                    })}
                    <Redirect to={options.length > 0 ? options[0].path.replace(':type', options[0].children[0].name) : '/'} />
                </>}
            </Switch>
        </div>
    )
}