import { useParams } from "react-router-dom";
import { WindowDealInfo, WindowDealReport } from '.'

export const WindowDeal = () => {
    const { type } = useParams()
    return (
        <>
            {type === 'info' && <WindowDealInfo />}
            {type === 'report' && <WindowDealReport />}
        </>
    )
}
