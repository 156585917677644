import * as XLSX from 'xlsx'

export const readFile = data => {
    return XLSX.read(data, { type: 'array' })
}

export const readSheet = sheet => {
    return XLSX.utils.sheet_to_json(sheet, { raw: false, defval: null })
}

export const getSheetNames = book => {
    return book.SheetNames
}

export const parseBook = (book, sheetNames = null) => {
    return book.SheetNames.filter(SheetName => sheetNames ? sheetNames.includes(SheetName) : true).map(SheetName => ({ name: SheetName, table: readSheet(book.Sheets[SheetName]) }))
}

export const readXlsx = (data, sheetNames = null) => {
    const book = readFile(data)
    return parseBook(book, sheetNames)
}