import moment from 'moment'

export const DateShort = (param) => moment(param.value).format('MMM-DD')

export const YearMonth = (param) => moment(param.value).format('MMM/YY')

export const Percentage = (param) => param.value === 0 ? 0 : !param.value ? '' : `${(param.value*100).toFixed(2)}%`

export const TwoDecimal = (param) => param.value === 0 ? 0 : !param.value ? '' : param.value.toFixed(2)

export const ZeroDecimal = (param) => param.value === 0 ? 0 : !param.value ? '' : param.value.toFixed(0)

export const TwoDecimalDefaultZero = (param) => param.value === 0 ? 0 : !param.value ? 0 : param.value.toFixed(2)

export default {
    DateShort,
    Percentage,
    TwoDecimal,
    YearMonth,
    ZeroDecimal,
    TwoDecimalDefaultZero,
}