import { useState, useEffect } from "react";

const useUrl = () => {
    const [url, setUrl] = useState({})
    
    useEffect(() => {
        const [, ...items] = window.location.pathname.split('/')
        setUrl(items.reduce((pre, cur, idx) => ({ ...pre, [`param_${idx}`]: cur }), {}))
    }, [window.location.pathname]);
    return url;
}

export default useUrl;