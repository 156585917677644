import React, { useEffect, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

export const UploadGrid = ({ data, name }) => {
    const [gridApi, setGridApi] = useState(null);
    const [gridColumnApi, setGridColumnApi] = useState(null);
    const [rowData, setRowData] = useState([])
    
    const [columnDefs, setColumnDefs] = useState([])

    const onGridReady = (params) => {
        setGridApi(params.api);
        setGridColumnApi(params.columnApi);
        sizeToFit();
        window.onresizable = () => {
            autoSizeAll()
        }
    };
  
    const sizeToFit = () => {
        if (gridApi) gridApi.sizeColumnsToFit();
    };

    const autoSizeAll = (skipHeader) => {
        if (gridColumnApi) {
            const allColumnIds = [];
            gridColumnApi.getAllColumns().forEach(function (column) {
                allColumnIds.push(column.colId);
            });
            gridColumnApi.autoSizeColumns(allColumnIds, skipHeader);
        }
    };

    useEffect(() => {
        if (data) {
            const keys = Object.keys(data[0])
            setColumnDefs(keys.map(key => ({ field: key, headerName: key })))
            setRowData(data)
        }
    }, [data])
    
    return (
        <div className="column-center w-full">
            <div className="ag-theme-alpine ag-center" style={{ height: 600, width: '99%' }}>
                <AgGridReact rowData={rowData} columnDefs={columnDefs} onGridReady={onGridReady} 
                    defaultColDef={{
                        flex: 1,
                        minWidth: 100,
                        suppressMovable: true,
                        cellStyle: { textAlign: 'center' }
                    }}
                />
            </div>
        </div>
    );
};