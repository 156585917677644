import React, { useState, useEffect } from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import moment from 'moment'
import useWindow from '../../hooks/useWindow'
import { singleDoubleChartWidth, chartHeight, post2, list2 } from '../../services';
import exportingModule from "highcharts/modules/exporting";
import exportDataModule from 'highcharts/modules/export-data'
exportingModule(Highcharts);
exportDataModule(Highcharts);

export const StorageChart = ({ data, title, controls, type }) => {

    const [options, setOptions] = useState({})
    const size = useWindow()

    const [exp, setExp] = useState(false)
    useEffect(() => {
        async function fetch() {
            const auth = await list2('auth')
            setExp(auth === true)
        }
        fetch()
    }, [])

    useEffect(() => {
        const options = {
            chart:  { 
                type: 'areaspline',
                width: singleDoubleChartWidth(size.width),
                height: chartHeight(size.height)
            },
            exporting: exp ? {
                buttons: {
                    contextButton: {
                        enabled: true,
                        align: 'left',
                        menuItems: ["downloadPNG", "downloadPDF", "downloadCSV", "separator",
                            {
                                text: 'Send Email',
                                onclick: function() {
                                    const csv = this.getCSV()
                                    const html = this.getSVG()
                                    const title = this.getFilename()
                                    post2('user/export_file', { csv, html, title })
                                }
                            }
                        ]
                    }
                }
            } : {
                buttons: {
                    contextButton: {
                        enabled: false
                    }    
                }
            },
            title: {
                text: title
            },
            yAxis: { title: { text: '' } },
            xAxis: {
                title: { text: '' },
                tickPositions: data ? data.map(({ date }, i) => type === 'floating' ? moment(date).date() === 1 ? i : null : i % 5 === 0 ? i : null).filter((i) => i) : [],
                categories: data ? data.map(dat => moment(dat.date).format(type === 'floating' ? 'MM/YY' : 'YYYY-MM-DD')) : []
            },
            legend:{
                maxHeight: 100
            },
            plotOptions: {
                series: {
                    stacking: 'normal'
                }
            },
            series: data && controls ? controls.map((name) => ({ name, data: data.map((dat) => dat[name])})) : []
        }
        setOptions(options)
    }, [data, controls, type, size, exp]);

    return (
    <div>
        <HighchartsReact
            highcharts={Highcharts}
            options={options}
        />
    </div>)
}