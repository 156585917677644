import { useEffect, useState, useContext } from 'react';
import { Grid, FormControl, InputLabel, OutlinedInput, Select, MenuItem, ListItemText, ListItemAvatar, Avatar } from '@mui/material'
import { list2 } from '../../services';
import { DispatchContext } from "../../reducers";
import { GeneralGrid, GeneralChart, SentimentTreemapChart, Lastupdate } from '../../components';

export const RiskProfile = () => {
    const dispatch = useContext(DispatchContext)
    const [countries, setCountries] = useState([])
    const [country, setCountry] = useState('Global')
    const [heatmap, setHeatmap] = useState()
    const [bar_charts, setBarCharts] = useState([])
    const [newsP, setNewsP] = useState()
    const [newsN, setNewsN] = useState()
    const [treemap, setTreemap] = useState()

    useEffect(() => {
        async function fetch() {
            dispatch({ type: 'spin on' })
            const data = await list2('risk_profile', 'list_country')
            dispatch({ type: 'spin off' })
            setCountries(data)
        }
        fetch()
    }, [])

    useEffect(() => {
        async function fetch() {
            dispatch({ type: 'spin on' })
            const data = await list2('risk_profile', null, { country })
            dispatch({ type: 'spin off' })
            setHeatmap(data.heatmap)
            setBarCharts(data.bar_charts)
            setNewsP(data.news_positive)
            setNewsN(data.news_negative)
            setTreemap(data.treemap)
        }
        fetch()
    }, [country])

    const onSelectCountry = (event) => {
        setCountry(event.target.value)
    }

    return <div className='column px-1 mb-6'>
        <div className='row-between mt-2 mb-3'>
            <FormControl fullWidth sx={{ minWidth: 230, width: 300 }} size="small">
                <InputLabel>Country/Region</InputLabel>
                <Select value={country} onChange={onSelectCountry} input={<OutlinedInput label="Country" sx={{ marginRight: 1 }} />}
                    renderValue={(selected) => countries.find((item) => selected === item.country)?.country}>
                    {countries.map((item) => <MenuItem key={`key_risk_country_selection_${item.country_code}`} value={item.country}>
                        <ListItemAvatar>
                            <Avatar alt={item.country_code} src={item.src} sx={{ width: 30, height: 30 }} />
                        </ListItemAvatar>
                        <ListItemText primary={item.country} />
                    </MenuItem>)}
                </Select>
            </FormControl>
            <Lastupdate type='risk_profile' />
        </div>
        <Grid container className='row-around mt-3'>
            <Grid item xs={12} key={`key_risk_profile_heatmap`} className='column-center mb-3' >                    
                {heatmap && <GeneralGrid param={heatmap} callback={() => null} />}
            </Grid>
            {bar_charts.map((item, idx) => (
                <Grid item sm={12} md={6} key={`key_risk_profile_${idx}`} className='column-center' >                    
                    <GeneralChart param={item} />
                </Grid>
            ))}
        </Grid>
        <div className='mt-3'>
            <div className='mb-1 bold'>Top News (Positive Sentiment)</div>
            {newsP && <GeneralGrid param={newsP} callback={() => null} />}
        </div>
        <div className='mt-3'>
            <div className='mb-1 bold'>Top News (Negative Sentiment)</div>
            {newsN && <GeneralGrid param={newsN} callback={() => null} />}
        </div>
        <div className='mt-3'>
            {treemap && <SentimentTreemapChart param={treemap} />}
        </div>
    </div>
}
