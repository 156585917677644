import { Icon } from '..'
import { numberCommaFormatter } from '../../services/Api';

export const userStatusRenderer = ({ param }) => {
    
    return (
        <div className="center"><Icon icon={param.value === 'ACTIVE' ? 'faCheck' : 'faHourglassStart'} color={param.value === 'ACTIVE' ? 'green' : 'blue'} size='1x' /></div>
    )
    
}

export const number = (param) => numberCommaFormatter(param.value)
export const number_link = (col) => col ? param => `<div class="underline blue text-center">${numberCommaFormatter(param.value)}</div>` : undefined

export default {
    number,
    number_link,
}