import { Backdrop, CircularProgress } from '@material-ui/core'; 
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: 9999,
        color: '#fff',
    },
    spinner: {
        color: '#4caf50',
        position: 'fixed',
        top: '50%',
        bottom: '50%',
        left: '50%',
        right: '50%'
    }
}));

export const Spinner = ({ spin = false }) => {
    const classes = useStyles();
    return spin && 
    <Backdrop className={classes.backdrop} open={spin}>
        <div className={classes.spinner}><CircularProgress color='inherit' /></div>
    </Backdrop>
}