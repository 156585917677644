import React, { useEffect, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import moment from 'moment'
import { gridHeight, numberCommaFormatter } from '../../services/Api';

export const TwoLineHeader = (props) => {
    const { line1, line2 } = props
    return <div className='column-center bold-7'>
        <div>{line1}</div>
        <div>{line2}</div>
    </div>
}
export const CftcGrid = ({ param, callback }) => {
    const [gridApi, setGridApi] = useState();
    const [rowData, setRowData] = useState([])
    const [columnDefs, setColumnDefs] = useState([])

    const onGridReady = (params) => {
        setGridApi(params.api);
        sizeToFit();
        window.onresizable = () => {
            sizeToFit();
        }
    };
  
    const sizeToFit = () => {
        if (gridApi) gridApi.sizeColumnsToFit();
    };

    useEffect(() => {
        const { data, colDef } = param
        if (data && colDef) {
            const cellRenderer = (col) => {
                return col ? param => `<div class="underline blue text-center">${numberCommaFormatter(param.value)}</div>` : undefined
            }
            const cd = colDef.map(({ cellrdr, chart, ...rest }) => ({ cellRenderer: cellRenderer(cellrdr), onCellClicked: param => onClickCallback(param), ...rest }))
            setColumnDefs(cd)
            setRowData(data)
        }
    }, [param])
    
    const onClickCallback = (param) => {
        if (param.colDef.field !== 'name' && param.value !== undefined) callback(param)
    }
    
    return (
        <div className="column-center w-full">
            <div className="ag-theme-alpine ag-packed" style={{ height: gridHeight(param.data.length, 50), width: '99%' }}>
                <AgGridReact rowData={rowData} columnDefs={columnDefs} rowHeight={50} onGridReady={onGridReady} 
                    frameworkComponents={{ TwoLineHeader }}
                    defaultColDef={{
                        flex: 1,
                        minWidth: 60,
                        wrapText: true,
                        suppressMovable: true
                    }}
                />
            </div>
        </div>
    );
};
