import React, { useEffect, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import cellRenderers from './CellRenderer'
import valueFormatters from './ValueFormatter'
import { gridHeight } from '../../services/Api';

export const DoubleGrid = ({ param, callback }) => {
    const [gridApi, setGridApi] = useState();
    const [rowData1, setRowData1] = useState([])
    const [rowData2, setRowData2] = useState([])
    const [columnDefs1, setColumnDefs1] = useState([])
    const [columnDefs2, setColumnDefs2] = useState([])
    const [defaultCellPosition, setDefaultCellPosition] = useState('center')
    const [defaultHeaderPosition, setDefaultHeaderPosition] = useState('center')

    const onGridReady = (params) => {
        setGridApi(params.api);
        sizeToFit();
        window.onresizable = () => {
            sizeToFit();
        }
    };
  
    const sizeToFit = () => {
        if (gridApi) gridApi.sizeColumnsToFit();
    };

    useEffect(() => {
        const { data1, colDef1, data2, colDef2, defaultCellPosition = 'center', defaultHeaderPosition = 'center' } = param
        // console.log(param)
        if (data1 && colDef1) {
            const colDef_ = colDef1.map(({ chart, valueFormatter, cellRenderer, ...rest }) => ({
                valueFormatter: valueFormatters[valueFormatter], 
                cellRenderer: cellRenderers[cellRenderer], 
                onCellClicked: param => onClickCallback(param), ...rest 
            }))
            setColumnDefs1(colDef_)
            setRowData1(data1)
        }
        if (data2 && colDef2) {
            const colDef_ = colDef2.map(({ chart, valueFormatter, cellRenderer, ...rest }) => ({
                valueFormatter: valueFormatters[valueFormatter], 
                cellRenderer: cellRenderers[cellRenderer], 
                onCellClicked: param => onClickCallback(param), ...rest 
            }))
            setColumnDefs2(colDef_)
            setRowData2(data2)
        }
        setDefaultCellPosition(defaultCellPosition)
        setDefaultHeaderPosition(defaultHeaderPosition)
    }, [param])
    
    const onClickCallback = (param) => {
        callback(param)
    }
    
    return (
        <div className="column-center w-full">
            <div className={`ag-theme-alpine ag-packed ag-header-${defaultHeaderPosition}`} style={{ maxHeight: 500, minHeight: 100, height: gridHeight(param.data1.length, 36), width: '100%' }}>
                <AgGridReact rowData={rowData1} columnDefs={columnDefs1} rowHeight={36} onGridReady={onGridReady} headerHeight={36}
                    defaultColDef={{
                        flex: 1,
                        minWidth: 60,
                        wrapText: true,
                        suppressMovable: true,
                        suppressHorizontalScroll: true,
                        cellStyle: { 
                            height: '100%',
                            display: 'flex',
                            justifyContent: defaultCellPosition,
                            alignItems: 'center' 
                        }
                    }}
                />
            </div>
            <div className={`ag-theme-alpine ag-packed ag-header-${defaultHeaderPosition}`} style={{ maxHeight: 500, minHeight: 100, height: gridHeight(param.data2.length, 36), width: '100%' }}>
                <AgGridReact rowData={rowData2} columnDefs={columnDefs2} rowHeight={36} onGridReady={onGridReady} headerHeight={36}
                    defaultColDef={{
                        flex: 1,
                        minWidth: 60,
                        wrapText: true,
                        suppressMovable: true,
                        suppressHorizontalScroll: true,
                        cellStyle: { 
                            height: '100%',
                            display: 'flex',
                            justifyContent: defaultCellPosition,
                            alignItems: 'center' 
                        }
                    }}
                />
            </div>
        </div>
    );
};
