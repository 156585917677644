import { useState, useEffect, useContext } from 'react';
import { useParams } from "react-router-dom";
import { List, ListItem , ListItemText, Accordion, AccordionSummary, AccordionDetails, Paper } from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { DispatchContext } from "../../../reducers";
import { list2 } from '../../../services'
import { Tender } from '.'

export const Tenders = ({ type: initType }) => {
    const { type: path } = useParams()
    const type = initType || path
    const dispatch = useContext(DispatchContext)
    const [tenders, setTenders] = useState([])

    useEffect(() => {
        async function fetch () {
            if (!type) return
            dispatch({ type: 'spin on' })
            const data = await list2('tender', type)
            dispatch({ type: 'spin off' })
            setTenders(data)
        }
        fetch()
    }, [type])

    return (
        <div className='column-center pt-2'>
            <a name='_top'></a>
            <List dense={true}>
                {tenders.map((tender) => 
                    <Accordion key={`key_tender_title_${tender.id}`} >
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} >
                            <div className='column-center pt-2 w-full'>
                                <div className='row-left mb-1 w-full'>
                                    <label className='ft-news-title-sm'>{tender.headline}</label>
                                </div>
                                <div className='row-between ft-news fs-17 w-full'>
                                    <label className='f-01 bold gray'>{tender.date}</label>
                                    <label className='f-01 bold gray'>Source: Platts</label>
                                </div>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails className='column'>
                            <ListItem key={`key_tender_content_${tender.id}`}>
                                <Tender tender={tender} />
                            </ListItem>
                        </AccordionDetails>
                    </Accordion>
                )}
            </List>
        </div>
    )
}