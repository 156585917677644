import { useState, useEffect } from 'react';
import { ClickAwayListener, Tooltip } from '@mui/material';
import { pascal } from '../../services';

export const Sentiments = ({ sid, analysis }) => {
    const [sentiments, setSentiments] = useState([])
    
    useEffect(() => {
        const sentiments = Object.entries(analysis).map(([key, { content_percentage, sentiment_score, explain }]) => {
            return { sid, key, content_percentage, sentiment_score, explain, label: pascal(key).replace('Lng', 'LNG').replace('Lpg', 'LPG').replace('Fuel', 'Fuel Oil') }
        }).sort((a, b) => b.content_percentage - a.content_percentage).slice(0, 3)
        setSentiments(sentiments)
    }, [analysis, sid])
  
    return <div className='column w-full mt-2'>
            {sentiments.map(({ key, sid, ...rest }) => <Sentiment key={`key_sentiment_${sid}_${key}`} sentiment={rest} />)}
        </div>
}

export const Sentiment = ({ sentiment: { content_percentage, sentiment_score, explain, label } }) => {
    const [open, setOpen] = useState(false)
    const height = content_percentage >= 50 ? 50 : content_percentage >= 20 ? 30 : 20
    const leftScore = sentiment_score*100 + 100 - height/2
    const leftPosition = leftScore < 0 ? 0 : leftScore > 200 - height ? 200 - height : leftScore
    const left = Math.round(leftPosition)

    const handleTooltipClose = () => {
        setOpen(false);
    };
  
    const handleTooltipOpen = () => {
        setOpen(true);
    };
  
    return (
        <ClickAwayListener onClickAway={handleTooltipClose}>
            <Tooltip PopperProps={{ disablePortal: true }} onClose={handleTooltipClose} open={open} leaveTouchDelay={30000}
                title={
                    <div className='fs-12 column-left'>
                        <div className='mb-2 w-full'>{label}</div>
                        <div className='row-between w-full'><div className='w-150'>Content Percentage: </div><div>{content_percentage}%</div></div>
                        <div className='row-between w-full'><div className='w-150'>Sentiment Score: </div><div>{sentiment_score}</div></div>
                        <div className='mt-2 w-full'>{explain}</div>
                    </div>
                }>
                <div className='row-between w-full'>
                    <div className='bold-5 w-120 fs-14'>{label}</div>
                    <div className={`sentiment-bar w-200 mt-1`} style={{ height: `${height}px` }} onClick={handleTooltipOpen} onTouchStart={handleTooltipOpen} onMouseEnter={handleTooltipOpen} >
                        <div className='sentiment-dot bold-5 fs-12' style={{ width: `${height}px`, height: `${height}px`, left: `${left}px` }}>{sentiment_score}</div>
                    </div>
                </div>
            </Tooltip>
        </ClickAwayListener>
    )
}