import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTwitter, faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import { 
    faArrowAltCircleUp, 
    faBookmark, 
    faCaretSquareDown, 
    faChartBar, 
    faComment,
    faCommentDots,
    faEnvelope, 
    faEye, 
    faFolder, 
    faHandPointer, 
    faHandshake,
    faHeart, 
    faNewspaper, 
} from '@fortawesome/free-regular-svg-icons'
import { 
    faAnchor, 
    faArchive,
    faArrowLeft, 
    faArrowRight, 
    faBars, 
    faCaretDown, 
    faCaretRight, 
    faCarSide,
    faChartLine, 
    faCheck,
    faChevronLeft,
    faChevronRight,
    faCog, 
    faCompressArrowsAlt, 
    faExpandArrowsAlt, 
    faDatabase,
    faDownload,
    faExclamationTriangle,
    faFile, 
    faFileCode,
    faFileCsv,
    faFileDownload,
    faFileExcel,
    faFileExport,
    faFilePdf,
    faFilter, 
    faFolder as faSolidFolder,
    faFolderOpen,
    faGlobeAmericas, 
    faHistory, 
    faHome, 
    faHourglassStart,
    faIndustry, 
    faInfoCircle,
    faLink,
    faList,
    faMicrophone,
    faMicrophoneSlash,
    faPlane,
    faPlay,
    faPause,
    faPen,
    faPodcast,
    faQrcode,
    faReply,
    faRetweet,
    faSave, 
    faSearchPlus,
    faSearchMinus,
    faShip, 
    faSignOutAlt, 
    faStop,
    faTimes,
    faTruck, 
    faUpload,
    faUserPlus,
    faUserCircle,
    faVolumeMute,
    faVolumeUp,
    faComments,
} from '@fortawesome/free-solid-svg-icons'

const icons = {
    faWhatsapp,
    faArrowAltCircleUp, faBookmark, faCaretSquareDown, faChartBar, faComment, faCommentDots, faEnvelope, faExclamationTriangle, faEye, faFolder, faHandPointer, faHandshake, faHeart, faNewspaper,
    faAnchor, faArchive, faArrowLeft, faArrowRight, faBars, faCaretDown, faCaretRight, faChartLine, faCheck, faChevronLeft, faChevronRight, faCog, 
    faCompressArrowsAlt, faDatabase, faDownload, faExpandArrowsAlt, faFile, faFileCode, faFileCsv, faFileDownload, faFileExcel, faFileExport, faFilePdf, faFilter, faSolidFolder, faFolderOpen, 
    faGlobeAmericas, faHistory, faHome, faHourglassStart, faIndustry, faInfoCircle, faLink, faList, faMicrophone, faMicrophoneSlash, faPlay, faPause, faPodcast, faQrcode, faReply, faRetweet, 
    faSave, faSearchPlus, faSearchMinus, faShip, faSignOutAlt, faStop, faTimes, faTruck, faUpload, faUserCircle, faUserPlus, faVolumeMute, faVolumeUp,
    faTwitter, faPen, faCarSide, faPlane, faComments,
}

export const Icon = ({ icon, size='xs', color='' }) => <FontAwesomeIcon icon={icons[icon]} size={size} color={color} />