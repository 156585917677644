export const palette = {
    dark_blue: {
        main: '#0033a0',
    },
    aramco_blue: {
        main: '#00a3e0',
    },
    dark_green: {
        main: '#00843d',
    },
    aramco_green: {
        main: '#848d00',
    },
    teal: {
        main: '#26a8ab',
    },
    purple: {
        main: '#643278',
    },
    yellow: {
        main: '#ffc846',
    },
    red: {
        main: '#f05f41',
    },
    dark_gray: {
        main: '#323232',
    },
    aramco_gray: {
        main: '#5f6369',
    },
    neutral_gray: {
        main: '#c0c0c0',
    },
    light_gray: {
        main: '#dadada',
    },
    white: {
        main: '#ffffff',
    },
}