import { useState, useEffect, useContext } from 'react';
import { useParams } from "react-router-dom";
import { Modal, Backdrop, Fade } from '@material-ui/core'
import { list2 } from '../../../services/Api'
import { ExportFile, HeatGrid, HeatGridNew, Lastupdate, FlowsAccumulateChart } from '../../../components';
import { DispatchContext } from "../../../reducers";
import { useWindow } from '../../../hooks'

export const Flows = ({ type: initType }) => {
    const { type: path } = useParams()
    const type = initType || path
    const size = useWindow()
    const dispatch = useContext(DispatchContext)
    const [importsWorld, setImportsWorld] = useState()
    const [exportsWorld, setExportsWorld] = useState()
    const [importsIndividual, setImportsIndividual] = useState()
    const [exportsIndividual, setExportsIndividual] = useState()
    const [data, setData] = useState()
    const [open, setOpen] = useState(false)
    const [selected, setSelected] = useState()
    
    useEffect(() => {
        async function fetch () {
            if (!type) return
            dispatch({ type: 'spin on' })
            const [importWorld, exportWorld, importIndividual, exportIndividual] = await Promise.all([
                list2('flows/snapshot/world', type, { flow: 'import' }),
                list2('flows/snapshot/world', type, { flow: 'export' }),
                list2('flows/snapshot/individual', type, { flow: 'import' }),
                list2('flows/snapshot/individual', type, { flow: 'export' })
            ])
            dispatch({ type: 'spin off' })
            setImportsWorld(importWorld)
            setExportsWorld(exportWorld)
            setImportsIndividual(importIndividual)
            setExportsIndividual(exportIndividual)
            combineData({ importWorld, exportWorld, importIndividual, exportIndividual })
        }
        fetch()
    }, [type])

    const combineData = ({ importWorld, exportWorld, importIndividual, exportIndividual }) => {
        const data = [importWorld, exportWorld, importIndividual, exportIndividual]
        .map(({ filename, result }, i) => ({ filename, type: 'text/csv', data: result.map(({ data, name, origin, destination, total, current, currentValue }) => {
            return { origin: i === 0 ? 'World' : i === 1 ? name : origin, destination: i === 0 ? name : i === 1 ? 'World' : destination, ...data, [current]: currentValue, total, '12MA': Math.round(total/12), '% Δ': total === 0 ? 'N/A' : `${Math.round(currentValue/total*1200 - 100)}%` }
        }) }))
        setData(data)
    }

    const desc = (flow, type) => `(${['Fuel_Flows', 'Naphtha_Flows'].includes(type) ? 'KT' : 'MBD'}, ${flow === 'import' ? 'Arrival' : 'Loading'} Month)`

    const onCallback = (flow = 'import', kind = 'world', param) => {
        async function fetch () {
            if (!type) return
            dispatch({ type: 'spin on' })
            const data = await list2(`flows/month/${kind}`, type, { 
                flow, 
                value: param.data.name, 
                originValue: param.data.origin, 
                destinationValue: param.data.destination,
                id: param.data.id,
                origin_id: param.data.origin_id,
                destination_id: param.data.destination_id, 
            })
            dispatch({ type: 'spin off' })
            setSelected(data)
            setOpen(true)
        }
        fetch()
    }

    const handleClose = () => {
        setOpen(false)
        setSelected(null)
    }

    return (
        <div>
            <div className='column px-1'>
                <div className='row-between px-2'>
                    <ExportFile data={data} type={`flows_snapshot_${type.replace('_Flows', '').toLowerCase()}`} subject={`${type.toLowerCase().replace('_', ' ')} data`} />
                    <Lastupdate type='flows' />
                </div>
                {importsWorld && <div className='column-start mb-5'>
                    <div className='row-start mb-1'><label className='f-04 bold'>Import from World</label><label className='f-02 ml-2'>{desc('import', type)}</label></div>
                    <HeatGridNew data={importsWorld} type='import' height = {500} callback={(param) => onCallback('import', 'world', param)} />
                </div>}
                {exportsWorld && <div className='column-start mb-5'>
                    <div className='row-start mb-1'><label className='f-04 bold'>Export to World</label><label className='f-02 ml-2'>{desc('export', type)}</label></div>
                    <HeatGridNew data={exportsWorld} type='export' height = {500} callback={(param) => onCallback('export', 'world', param)} />
                </div>}
                {importsIndividual && <div className='column-start mb-5'>
                    <div className='row-start mb-1'><label className='f-04 bold'>Key Flows</label><label className='f-02 ml-2'>{desc('import', type)}</label></div>
                    <HeatGridNew data={importsIndividual} type='import' height = {910} callback={(param) => onCallback('import', 'individual', param)} />
                </div>}
                {exportsIndividual && <div className='column-start mb-5'>
                    <div className='row-start mb-1'><label className='f-04 bold'>Key Flows</label><label className='f-02 ml-2'>{desc('export', type)}</label></div>
                    <HeatGridNew data={exportsIndividual} type='export' height = {910} callback={(param) => onCallback('export', 'individual', param)} />
                </div>}
            </div>
            <Modal open={open} onClose={handleClose} className='modal' closeAfterTransition 
                BackdropComponent={Backdrop} BackdropProps={{ timeout: 500 }}>
                <Fade in={open}>
                    <div className='modal__curve' style={{ width: '90%', height: size.height*0.7, maxWidth: size.width*0.8 }} >
                        {selected && <div className='column-center p-2'>
                            <FlowsAccumulateChart data={selected} />
                        </div>}
                    </div>
                </Fade>
            </Modal>
        </div>
    )
}