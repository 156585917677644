import { useState, useEffect } from 'react'
import { text2Speech } from '../../services'

export const Player = ({ text, voice, callback }) => {

    const [src, setSrc] = useState()
    
    useEffect(() => {
        const fetch = async () => {
            setSrc(null)
            const url = await text2Speech(text, voice)
            setSrc(url)
        }
        fetch()
    }, [text])

    const onLoadedMetadata = () => {
        document.getElementById('audio').play()
        callback('audio loaded')
    }

    const onEnd = () => {
        callback('end')
    }

    return <>
        <audio id="audio" src={src} type="audio/mpeg" controls onLoadedMetadata={onLoadedMetadata} onEnded={onEnd}></audio>
    </>
}