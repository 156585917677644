import { makeStyles } from "@material-ui/core/styles";

const useTableStyles = makeStyles({
    tableCell: {
        padding: "5px 0px 5px 0px",
        border: '2px solid black'
    },
    headerCell: {
        fontWeight: 700,
        padding: "5px 0px 5px 0px",
        border: '2px solid black'
    },

    rowEven: {
        backgroundColor: '#D9E8CB'
    },
    rowOdd: {
        backgroundColor: '#EDF4E7'
    }
});

export default useTableStyles;