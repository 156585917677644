import { createContext } from "react";

export const initialState = {
    spin: false,
    intraday: true,
}

export const StateContext = createContext()
export const DispatchContext = createContext()

export const reducer = (state, action) => {
    const { type, payload } = action
    switch (type) {
        case "spin on": return { ...state, spin: true }
        case "spin off": return { ...state, spin: false }
        case "intraday on": return { ...state, intraday: true }
        case "intraday off": return { ...state, intraday: false }
        case "message": return { ...state, ...payload }
        default: return state
    }
}
  