import React, { useState, useEffect } from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import useWindow from '../../hooks/useWindow'
import { singleDoubleChartWidth, chartHeight, post2, list2 } from '../../services';
import exportingModule from "highcharts/modules/exporting";
import exportDataModule from 'highcharts/modules/export-data'
import moment from 'moment';
exportingModule(Highcharts);
exportDataModule(Highcharts);


export const CftcCompareChart = ({ param }) => {
    const [options, setOptions] = useState({})
    const size = useWindow()

    const [exp, setExp] = useState(false)
    useEffect(() => {
        async function fetch() {
            const auth = await list2('auth')
            setExp(auth === true)
        }
        fetch()
    }, [])

    useEffect(() => {
        const { name, product, data } = param
        const options = {
            chart: {
                type: 'areaspline',
                width: singleDoubleChartWidth(size.width),
                height: 400
            },
            exporting: exp ? {
                buttons: {
                    contextButton: {
                        enabled: true,
                        align: 'left',
                        menuItems: ["downloadPNG", "downloadPDF", "downloadCSV", "separator",
                            {   
                                text: 'Send Email', 
                                onclick: function() {
                                    const csv = this.getCSV()
                                    const html = this.getSVG()
                                    const title = this.getFilename()
                                    post2('user/export_file', { csv, html, title })
                                } 
                            }
                        ]
                    }
                }
            } : {
                buttons: {
                    contextButton: {
                        enabled: false
                    }    
                }
            },
            title: { text: `${name} Long - Short` },
            subtitle: { text: `Positions of ${product}`, align: 'right' },
            xAxis: {
                title: { text: '' },
                categories: data.map(({ date }) => moment(date).format('MMM/YY')),
                tickInterval: Math.round(data.length/12)
            },
            yAxis: { title: { text: '' } },
            plotOptions: { spline: { marker: { enabled: false } } },
            series: [
                { name: `${name} Long`, color: '#00b050', data: data.map((dat) => dat.Long), marker: { enabled: false } },
                { name: `${name} Short`, color: '#e5633f', data: data.map((dat) => -dat.Short), marker: { enabled: false } }
            ]
        }
        setOptions(options)
        // console.log(options)
    }, [param, size, exp])

    return <div className='row-center'>
        <HighchartsReact highcharts={Highcharts} options={options} />
    </div>

}