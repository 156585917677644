import { useEffect, useState } from 'react';
import { Icon } from '..'

export const ExpandableHeader = (props) => {
    const { displayName, columnGroup } = props
    const [expandState, setExpandState] = useState('collapsed')

    const syncExpandButtons = () => {
        setExpandState(columnGroup.getOriginalColumnGroup().isExpanded() ? 'expanded' : 'collapsed')
    }

    useEffect(() => {
        columnGroup.getOriginalColumnGroup().addEventListener('expandedChanged', syncExpandButtons)
        syncExpandButtons()
        return () => {
            columnGroup.getOriginalColumnGroup().removeEventListener('expandedChanged', syncExpandButtons)
        }
    }, [])
    
    const expandOrCollapse = () => {
        const currentState = columnGroup.getOriginalColumnGroup().isExpanded()
        props.setExpanded(!currentState)
    }

    return (
        <div className="ag-header-group-cell-label row-start">
            <div className="header-group">{displayName}</div>
            <div className={`ml-2 ${expandState}`} onClick={() => expandOrCollapse()}>
                <Icon icon='faArrowRight' />
            </div>
        </div>
    )
}