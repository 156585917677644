import { useState, useEffect, useContext } from 'react';
import { useParams } from "react-router-dom";
import { DispatchContext } from "../../../reducers";
import { list2 } from '../../../services/Api'
import { Tweet } from '.';

export const Tweets = ({ type: initType }) => {
    const { type: path } = useParams()
    const type = initType || path
    const dispatch = useContext(DispatchContext)
    const [tweets, setTweets] = useState([])

    useEffect(() => {
        setTweets([])
        async function fetch () {
            if (!type) return
            dispatch({ type: 'spin on' })
            const tweets = await list2('tweet', type)
            dispatch({ type: 'spin off' })
            setTweets(tweets)
        }
        fetch()
    }, [type])

    return <div className='column-center mt-2 px-1'>
        <a name='_top'></a>
        {tweets.map((data) => {
            return (
                <div className='mb-6 column-start border-1-grey w-full mw-1000' key={`key_tweets_item_${data.id}`} >
                    <Tweet data={data} />
                </div>
            )
        })}
    </div>
}