import React, { useState, useEffect } from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import useWindow from '../../hooks/useWindow'
import { singleDoubleChartWidth, chartHeight, post2 } from '../../services';
import Tree from 'highcharts/modules/treemap';
import exportingModule from "highcharts/modules/exporting";
import exportDataModule from 'highcharts/modules/export-data'
Tree(Highcharts)
exportingModule(Highcharts);
exportDataModule(Highcharts);


export const TreemapChart = ({ param }) => {
    const [options, setOptions] = useState({})
    const size = useWindow()

    useEffect(() => {
        const { title, series } = param
        const options = {
            chart: {
                type: 'treemap',
                width: size.width - 90,
                height: size.height*0.6-50,
            },
            exporting: {
                buttons: {
                    contextButton: {
                        enabled: true,
                        align: 'left',
                        menuItems: ["downloadPNG", "downloadPDF", "downloadCSV", "separator",
                            {   
                                text: 'Send Email', 
                                onclick: function() {
                                    const csv = this.getCSV()
                                    const html = this.getSVG()
                                    const title = this.getFilename()
                                    post2('user/export_file', { csv, html, title })
                                } 
                            }
                        ]
                    }
                }
            },
            title: { text: title },
            tooltip: {
                formatter: function() {
                    return this.key
                }
            },
            series
        }
        setOptions(options)
        // console.log(options)
    }, [param, size])

    return <div className='row-center'>
        <HighchartsReact highcharts={Highcharts} options={options} />
    </div>

}