import React, { useState, useEffect } from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { singleDoubleChartWidth, colors, post2, list2 } from '../../services/Api'
import useWindow from '../../hooks/useWindow'
import exportingModule from "highcharts/modules/exporting";
import exportDataModule from 'highcharts/modules/export-data'
exportingModule(Highcharts);
exportDataModule(Highcharts);

export const InterestChart = ({ chartData, index }) => {

    const [options, setOptions] = useState({})
    const size = useWindow()

    const [exp, setExp] = useState(false)
    useEffect(() => {
        async function fetch() {
            const auth = await list2('auth')
            setExp(auth === true)
        }
        fetch()
    }, [])

    useEffect(() => {
        const { name, size: lot, unit, categories, data } = chartData
        const options = {
            chart: {
                type: 'column',
                width: singleDoubleChartWidth(size.width, false)
            },
            exporting: exp ? {
                buttons: {
                    contextButton: {
                        enabled: true,
                        align: 'left',
                        menuItems: ["downloadPNG", "downloadPDF", "downloadCSV", "separator",
                            {
                                text: 'Send Email',
                                onclick: function() {
                                    const csv = this.getCSV()
                                    const html = this.getSVG()
                                    const title = this.getFilename()
                                    post2('user/export_file', { csv, html, title })
                                }
                            }
                        ]
                    }
                }
            } : {
                buttons: {
                    contextButton: {
                        enabled: false
                    }    
                }
            },
            title: { text: name },
            subtitle: { text: `(1 lot = ${lot} ${unit}s)`, y: 35 },
            xAxis: { 
                title: { text: '' },
                categories
            },
            yAxis: { title: { text: '' } },
            plotOptions: {
                column: {
                    dataLabels: {
                        enabled: true,
                        formatter: function() {
                            return this.y.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                        }
                    }
                }
            },
            series: [
                {
                    name,
                    data,
                    color: colors[index]
                }
            ]
        }
        setOptions(options)
    }, [chartData, size, index, exp]);

    return (<div className='row-center' style={{ height: 400, width: '99%' }}>
        <HighchartsReact
            highcharts={Highcharts}
            options={options}
        />
    </div>)
}