import React, { useState, useEffect } from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import moment from 'moment';
import HC_more from 'highcharts/highcharts-more'
import useWindow from '../../hooks/useWindow'
import { singleDoubleChartWidth, chartHeight, post2, list2 } from '../../services';
import exportingModule from "highcharts/modules/exporting";
import exportDataModule from 'highcharts/modules/export-data'
exportingModule(Highcharts);
exportDataModule(Highcharts);

HC_more(Highcharts)

export const LiquidChart = ({ data, title, start, type }) => {

    const [options, setOptions] = useState({})
    const size = useWindow()
    
    const [exp, setExp] = useState(false)
    useEffect(() => {
        async function fetch() {
            const auth = await list2('auth')
            setExp(auth === true)
        }
        fetch()
    }, [])

    useEffect(() => {
        if (!data || data.length === 0) return
        const end = data[data.length - 1].date
        const text = `${moment(start).format('MMM YY')}-${moment(end).format('MMM YY')} (KT)`
        const options = {
            chart:  { 
                width: singleDoubleChartWidth(size.width, false),
                height: chartHeight(size.height)
            },
            exporting: exp ? {
                buttons: {
                    contextButton: {
                        enabled: true,
                        align: 'left',
                        menuItems: ["downloadPNG", "downloadPDF", "downloadCSV", "separator",
                            {
                                text: 'Send Email',
                                onclick: function() {
                                    const csv = this.getCSV()
                                    const html = this.getSVG()
                                    const title = this.getFilename()
                                    post2('user/export_file', { csv, html, title })
                                }
                            }
                        ]
                    }
                }
            } : {
                buttons: {
                    contextButton: {
                        enabled: false
                    }    
                }
            },
            title: {
                text: title,
                align: 'center'
            },
            subtitle: {
                text,
                align: 'right'
            },
            xAxis: {
                title: { text: '' },
                categories: data.map(dat => moment(dat.date).format('MMM YY'))
            },
            tooltip: {
                formatter: function () {
                    let positive = '', negative = '', footer = ''
                    this.points.forEach((point) => {
                        if (point.series.userOptions.name === 'Net') footer = `<div><b>Net:</b>&nbsp;${point.y}</div>`
                        else if (point.y > 0) positive = `${positive}<br /><div><b>${point.series.userOptions.name}:</b>&nbsp;${point.y}</div>`
                        else negative = `${negative}<br /><div><b>${point.series.userOptions.name}:</b>&nbsp;${-point.y}</div>`   
                    })
                    return `<b>${this.x}</b><br />${positive}<br />${negative}<br /><br />${footer}`
                },
                shared: true
            },
            yAxis: { 
                title: { text: '' },
                stackLabels: {
                    enabled: true,
                    crop: false,
                    overflow: 'allow',
                    style: {
                        fontWeight: 'bold',
                        color: 'gray'
                    }
                }
            },
            plotOptions: {
                column: {
                    stacking: 'normal',
                    dataLabels: {
                        enabled: false
                    }
                }
            },
            series: type.map(({ name, key }) => ({
                name,
                type: name === 'Net' ? 'spline' : 'column',
                data: data.map((dat) => dat[key]),
            }))
        }
        setOptions(options)
    }, [data, title, start, type, size, exp]);

    return (
        <div>
            <HighchartsReact
                highcharts={Highcharts}
                options={options}
            />
        </div>
    )
}
