import { useState, useEffect, useContext } from 'react';
import { FormControl, InputLabel, OutlinedInput, Select, MenuItem, Modal, Backdrop, Fade, Typography, ListItemText, ListItemAvatar, Avatar } from '@mui/material'
import { list2 } from '../../../services/Api'
import { GeneralGrid, Lastupdate } from '../../../components';
import { DispatchContext } from "../../../reducers";
import { useWindow } from '../../../hooks'

export const PortRisk = () => {
    const size = useWindow()
    const dispatch = useContext(DispatchContext)
    const [ports, setPorts] = useState([])
    const [port, setPort] = useState('Zhoushan')
    const [data, setData] = useState()
    const [open, setOpen] = useState(false)
    const [selected, setSelected] = useState()
    
    useEffect(() => {
        async function fetch () {
            dispatch({ type: 'spin on' })
            const result = await list2('port_risk', 'list_port')
            dispatch({ type: 'spin off' })
            setPorts(result)
        }
        fetch()
    }, [])

    useEffect(() => {
        async function fetch () {
            dispatch({ type: 'spin on' })
            const result = await list2('port_risk', null, { port })
            dispatch({ type: 'spin off' })
            setData(result)
        }
        fetch()
    }, [port])

    const onSelectPort = (event) => {
        setPort(event.target.value)
    }

    const onClickGridCell = (param) => {
        if (param.data.risk === 'High') {
            setSelected(param.data)
            setOpen(true)
        }
    }

    return (
        <div>
            <div className='column px-1'>
                <div className='row-between mt-2 mb-3'>
                    <FormControl fullWidth sx={{ minWidth: 230, width: 300 }} size="small">
                        <InputLabel>Port</InputLabel>
                        <Select value={port} onChange={onSelectPort} input={<OutlinedInput label="Port" sx={{ marginRight: 1 }} />}
                            renderValue={(selected) => ports.find((item) => selected === item.port)?.port}>
                            {ports.map((item) => <MenuItem key={`key_ports_selection_${item.port}`} value={item.port}>
                                <ListItemAvatar>
                                    <Avatar alt={item.country_code} src={item.src} sx={{ width: 30, height: 30 }} />
                                </ListItemAvatar>
                                <ListItemText primary={item.port} />
                            </MenuItem>)}
                        </Select>
                    </FormControl>
                    <Lastupdate type='port_risk' />
                </div>
                {data && <GeneralGrid param={data} callback={onClickGridCell} />}
            </div>
            <Modal open={open} onClose={() => setOpen(false)} className='column-center' closeAfterTransition 
                slots={{ backdrop: Backdrop }} slotProps={ { backdrop: { timeout: 500 } } }>
                <Fade in={open}>
                    <div className='p-1 bg-white position-absolute w-200 h-60' >
                        {selected && <div className='column-center p-2'>
                            <Typography>{selected.statement}</Typography>
                        </div>}
                    </div>
                </Fade>
            </Modal>
        </div>
    )
}