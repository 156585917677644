import { createContext, useContext, useEffect, useState, CSSProperties } from 'react';
import moment from 'moment'
import { useHistory } from "react-router-dom";
import { Box, Button, Card, CardContent, ToggleButtonGroup, ToggleButton, ListItem, TextField, Typography, InputAdornment, IconButton, Dialog, DialogTitle, DialogContent } from '@mui/material';
import { AudioRecorder, ChatChart, ChatGrid, TreemapChart, Icon, BarChart } from '../components';
import { list2, post2 } from '../services'
import { useWindow } from '../hooks';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import SendIcon from '@mui/icons-material/Send';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import ClipLoader from "react-spinners/ClipLoader";

const defaultContext= {
    format: 'table',
    language: 'en-US',
    read: 'off',
    subject: 'news'
}
export const ChatContext = createContext(defaultContext);

export const Headlines = ({ param, fontsize=14 }) => {
    const [selected, setSelected] = useState()
    const [open, setOpen] = useState(false)
    const handleClose = () => {
        setSelected(null)
        setOpen(false)
    };
    const onSelect = (event) => {
        setSelected(event)
        setOpen(true)
    }
    return <div className='column-left'>
        {param.map(({ date, title, sid, source, ...rest }, idx) => {
            return <div key={`key_headline_${sid}`} className={`ft-news fs-${fontsize} blue underline`} onClick={() => onSelect({ date, title, sid, source, ...rest })}>
                {`[${idx + 1}] ${title} [${source} ${moment(date).format('MMM D')}]`}
            </div>
        })}
        <Dialog open={open} onClose={handleClose} >
            <DialogContent>
                {selected && <Summarization param={selected} />}
            </DialogContent>
        </Dialog>
    </div>
}

const Summarization = ({ param: { title, summarization, date, source } }) => {
    return <div className='column-left mb-3'>
        <div className="ft-news-title">{title}</div>
        <div className="ft-news-title-sm my-2">{`${source} ${moment(date).format('MMM D')}`}</div>
        <div className='ft-news fs-17'>
            {summarization}
        </div>
    </div>
}

const FullStory = ({ param: { title, sid, paragraph, date, source } }) => {
    return <div className='column-left mb-3'>
        <div className="ft-news-title">{title}</div>
        <div className="ft-news-title-sm my-2">{`${source} ${moment(date).format('MMM D')}`}</div>
        {paragraph.map((text, i) => <div className='ft-news fs-17 my-1' key={`key_pagraph_${sid}_${i}`}>{text}</div>)}
    </div>
}

const Reference = ({ param }) => {
    return <div className='column-left'>
        <div className='fs-18 bold-5 mb-2'>Reference:</div>
        <Headlines param={param} fontsize={14} />
    </div>
}
const CarbonIntensityCrude = ({ param }) => {
    const { crude_id, src, fields, intakes, intake_countries, treemap, barchart, barchart_field, name, short_name, country, country_code, api, sulphur, flaring, quality, ci_drilling, ci_extraction, ci_surface_processing, ci_venting_flaring, ci_other, ci_offsite, ci_without_transport, ci_transport, ci_total } = param
    const api_level = api === 1 ? 'LO' : api === 2 ? 'AVG' : 'HI'
    const sulphur_level = sulphur === 1 ? 'LO' : sulphur === 2 ? 'AVG' : 'HI'
    const flaring_level = flaring === 1 ? 'LO' : flaring === 2 ? 'AVG' : 'HI'
    const level_class = (level) => level === 'LO' ? 'bg-green4' : level === 'AVG' ? 'bg-grey' : 'bg-red_darker'
    const size = useWindow()
    const [n, setN] = useState(4)
    useEffect(() => {
        if (size.width < 400) setN(4)
        else {
            setN(4 + parseInt((size.width - 400)/80))
        }
    }, [size])
    return <Card sx={{ minWidth: 275 }}>
        <CardContent>
            <div className='fs-20 bold-5 row-between mb-3 w-full'>
                <div className=''>{name}</div>
                <div className='row-right fs-14'>
                    <div className='mr-1'>{country}</div>
                    <img src={src} alt={`${country_code}.svg`} width={'20%'} height={'20%'} />
                </div>
            </div>
            <div className='row-between w-full mb-3 bold-5'>
                <div>Total Carbon Intensity</div>
                <div className=''>{ci_total.toFixed(0)}</div>
            </div>    
            <TreemapChart param={treemap} />
            <hr />
            <div className='bold-5 mb-3'>
                Crude Grade Profile
            </div>
            <div className='row-between mb-3 fs-12'>
                <div className='row w-70'>
                    <div className={`circle-40 white ${level_class(api_level)}`}>{api_level}</div>
                    <div className='ml-1'>API</div>
                </div>
                <div className='row w-70'>
                    <div className={`circle-40 white ${level_class(sulphur_level)}`}>{sulphur_level}</div>
                    <div className='ml-1'>Sulphur</div>
                </div>
                <div className='row w-70'>
                    <div className={`circle-40 white ${level_class(flaring_level)}`}>{flaring_level}</div>
                    <div className='ml-1'>Flaring/Oil</div>
                </div>
            </div>
            <hr />
            <div className='bold-5 mb-3'>
                Consuming Countries
            </div>
            <BarChart param={barchart} />
            <hr />
            <div className='bold-5 mb-3'>
                Contributing Fields
            </div>
            <BarChart param={barchart_field} />
            <div className='fs-12 mt-3 gray text-right'>Unit: kgCO2e/bbl</div>
        </CardContent>
    </Card>
}

const CarbonIntensityCrudes = ({ param }) => {
    return <div className='column-left my-1'>
        {param.map((crude) => <div className='my-1'><CarbonIntensityCrude key={`key_carbon_intensity_${crude.id}`} param={crude} /></div>)}
    </div>
}

const CarbonIntensityRefinery = ({ param }) => {
    const { name, average_api, average_sulphur, average_complexity, average_quality, treemap, src, country, country_code, ci_total, ci_intake, refining, refining_detail, well_to_refinery_exit, intakes } = param
    const api_level = average_api === 1 ? 'LO' : average_api === 2 ? 'AVG' : 'HI'
    const sulphur_level = average_sulphur === 1 ? 'LO' : average_sulphur === 2 ? 'AVG' : 'HI'
    const complexity_level = average_complexity === 1 ? 'LO' : average_complexity === 2 ? 'AVG' : 'HI'
    const level_class = (level) => level === 'LO' ? 'bg-green4' : level === 'AVG' ? 'bg-grey' : 'bg-red_darker'
    const size = useWindow()
    const [n, setN] = useState(4)
    useEffect(() => {
        if (size.width < 400) setN(4)
        else {
            setN(4 + parseInt((size.width - 400)/80))
        }
    }, [size])
    return <Card sx={{ minWidth: 275 }}>
        <CardContent>
            <div className='fs-20 bold-5 row-between mb-3 w-full'>
                <div className='row'><div className='mr-1'>{name}</div><img src={src} alt={`${country_code}.svg`} width={'20%'} height={'20%'} /></div>
                <div>{ci_total.toFixed(0)}</div>
            </div>
            <TreemapChart param={treemap} />
            <hr />
            <div className='bold-5 mb-3'>
                Refinery Profile
            </div>
            <div className='row-between mb-3 fs-12'>
                <div className='row w-70'>
                    <div className={`circle-40 white ${level_class(api_level)}`}>{api_level}</div>
                    <div className='ml-1'>API</div>
                </div>
                <div className='row w-70'>
                    <div className={`circle-40 white ${level_class(sulphur_level)}`}>{sulphur_level}</div>
                    <div className='ml-1'>Sulphur</div>
                </div>
                <div className='row w-70'>
                    <div className={`circle-40 white ${level_class(complexity_level)}`}>{complexity_level}</div>
                    <div className='ml-1'>Complexity</div>
                </div>
            </div>
            <hr />
            <div className='bold-5 mb-3'>
                Crude Intakes
            </div>
            <div className='row mb-3'>
                {intakes.slice(0, n).map(({ ci_crude_total, crude_short_name, source_country_code, src }) => <div className='column-center bold-5 mr-2 border-1 w-60 h-60'>
                    <div className='row mb-2'><div className='mr-1'>{crude_short_name}</div><img src={src} alt={`${source_country_code}.svg`} width={'50%'} height={'50%'} /></div>
                    <div>{ci_crude_total.toFixed(0)}</div>
                </div>)}
            </div>
            <hr />
            <div className='bold-5 mb-3'>
                Product Total (Well-to-Refinery-Exit)
            </div>
            <div className='row-between mb-3'>
                {well_to_refinery_exit.map(({ ci, label, icon }) => <div className='column-center bold-5 border-1 w-60 h-60 mr-2'>
                    <Icon icon={icon} className='mb-2' />
                    <div className='fs-12'>{ci.toFixed(0)}</div>
                    <div className='fs-12'>{label}</div>
                </div>)}
            </div>
            <div className='fs-12 mt-3 gray text-right'>Unit: kgCO2e/bbl</div>
        </CardContent>
    </Card>
}

const CarbonIntensityRefineries = ({ param }) => {
    return <div className='column-left my-1'>
        {param.map((refinery) => <div className='my-1'><CarbonIntensityRefinery key={`key_carbon_intensity_${refinery.id}`} param={refinery} /></div>)}
    </div>
}

const CarbonIntensityCountry = ({ param }) => {
    const { name, country_code, src, barchart, well_to_refinery_exit, total } = param
    return  <div>
            <div className='fs-20 bold-5 row-between mb-3 w-full'>
                <div>{name}</div>
                <div><img src={src} alt={`${country_code}.svg`} width={'100%'} height={'100%'} /></div>
            </div>
            <div className='row-between w-full mb-3 bold-5'>
                <div>Total Carbon Intensity</div>
                <div className=''>{total}</div>
            </div>            
            <BarChart param={barchart} />
            <hr />
            <div className='bold-5 mb-3'>
                Well-to-Pump CI Without Combustion
            </div>
            <div className='row-between'>
                {well_to_refinery_exit.map(({ ci, label, icon }) => <div className='column-center bold-5 border-1 w-60 h-60 mr-2'>
                    <Icon icon={icon} className='mb-2' />
                    <div className='fs-12'>{ci.toFixed(0)}</div>
                    <div className='fs-12'>{label}</div>
                </div>)}
            </div>
            <div className='fs-12 mt-3 gray text-right'>Unit: kgCO2e/bbl</div>
            </div>
    
}

const CarbonIntensityCountries = ({ param }) => {
    return <div className='column-left'>
        {param.map((country) => <div className='my-1'><CarbonIntensityCountry key={`key_carbon_intensity_${country.country_id}`} param={country} /></div>)}
    </div>
}

const Data = ({ param }) => {
    return <div className='column-left mb-3'>
        <div className='fs-16 bold-5 mb-3'>{param.name}</div>
        <ChatGrid param={param} />
    </div>
}

const Help = ({ param }) => {
    return <div className='column-left mb-3'>
        <div className='fs-14 my-3'>For example:</div>
        {param.map((example, idx) => <div className='fs-14 mb-1'>{idx + 1}. {example}</div>)}
        {/* <div className='mt-3'>Type "set subject" + name to switch subject. Available subject names are "General", "Data", "Carbon" and "News"</div> */}
    </div>
}

export const Chat = () => {

    const history = useHistory()
    const [context, setContext] = useState(defaultContext)
    const [chats, setChats] = useState([])

    useEffect(() => {
        list2('auth', 'copilot').then((auth) => { if (auth !== true) history.push('/') })
    }, [])

    const changeContext = (newContext) => {
        if (newContext) setContext(newContext)
    }

    const onReceiveInput = (input) => {
        const newChats = [...chats, input]
        setChats(newChats)
    }

    return <ChatContext.Provider value={{ context, changeContext }} >
        <ChatDisclaimer />
        <ChatContent chats={chats} />
        <ChatInput callback={(input) => onReceiveInput(input)} />
    </ChatContext.Provider>
}

export const ChatDisclaimer = () => {
    const [disclaimer, setDisclaimer] = useState()
    useEffect(() => {
        list2('ai/disclaimer').then((d) => setDisclaimer(d))
    }, [])
    return <div className='fs-12 gray w-full bg-white fixed top-45 px-2'>
        <div>{disclaimer}</div>
    </div>
}

export const ChatInput = ({ callback }) => {
    
    const { context } = useContext(ChatContext)
    const [input, setInput] = useState('')
    const [placeholder, setPlaceholder] = useState('Type "help" for more information')
    const placeholders = {
        news: 'e.g. What is crude market outlook',
        general: 'Ask me anything',
        carbon: 'e.g. What is CI of Arab Light Crude',
        data: 'e.g. China gasoline demand'
    }
    
    useEffect(() => {
        setPlaceholder(placeholders[context.subject])
    }, [context])

    const onSend = () => {
        if (input) callback(input)
        setInput('')
    }
    const onAudioCallback = (event) => {
        if (context.pause) {
            setInput(event)
        } else {
            callback(event)
        }
    }

    const onKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault()
            onSend()
        }
    }
    
    return <div className='fixed bottom-50 w-full bg-white row'>  
        <TextField multiline  InputProps={{
          endAdornment: (
            <>
            <InputAdornment position="end">
                <IconButton onClick={onSend}>
                    <SendIcon />
                </IconButton>
            </InputAdornment>
            {/* <InputAdornment position="end"> 
                <AudioRecorder callback={onAudioCallback} />
            </InputAdornment> */}
            </>
          ),
        }} className='text-input' size="small" placeholder={placeholder} value={input} onInput={(event) => setInput(event.target.value)} onKeyDown={onKeyDown} variant="outlined" />
        
    </div>
}

export const ChatContent = ({ chats }) => {
    return <div className='w-full px-2 pt-65 pb-50'>
        {chats.map((input, i) => <ChatUnit key={`chat_${i}`} input={input} />)}
    </div>
}

export const ChatUnit = ({ input }) => {

    const { context, changeContext } = useContext(ChatContext)
    const [ask, setAsk] = useState(input)
    const [answer, setAnswer] = useState()

    useEffect(() => {
        setAsk(input)
        getAnswer(input)
    }, [input])
  
    const getAnswer = async (question) => {
        if (question.toUpperCase().includes('SET')) {
            const { data: { context: ctx, answer } } = await post2('ai/setting', { question, context })
            changeContext(ctx)
            setAnswer(answer)
        } else if (question.toUpperCase().includes('HELP') && question.length < 10) {
            const { data: { context: ctx, answer } } = await post2('ai/help', { question, context })
            const secondline = <Help param={ctx.help_reference} />
            setAnswer(<><div className='mb-1 pre-line'>{answer}</div>{secondline}</>)
        } else {
            const { data: { answer: asr, context: ctx, template } } = await post2('ai/conversation', { question, context })
            changeContext(ctx)
            const firstline = asr ? <div className='mb-1 pre-line'>{asr}</div> : ''
            let secondline = ''
            if (template === 'headlines') {
                secondline = <Headlines param={ctx.news_reference} />
            } else if (template === 'summarization') {
                secondline = <>{ctx.news_reference.map((n)=> <Summarization key={`key_chat_news_summarization_${n.sid}`} param={n} />)}</>
            } else if (template === 'full_story') {
                secondline = <>{ctx.news_reference.map((n)=> <FullStory key={`key_chat_news_full_story_${n.sid}`} param={n} />)}</>
            } else if (template === 'talk_about_topic') {
                secondline = <Reference param={ctx.news_reference} />
            } else if (template === 'carbon_intensity_crude') {
                secondline = <CarbonIntensityCrudes param={ctx.carbon_intensity} />
            } else if (template === 'carbon_intensity_refinery') {
                secondline = <CarbonIntensityRefineries param={ctx.carbon_intensity} />
            } else if (template === 'carbon_intensity_country') {
                secondline = <CarbonIntensityCountries param={ctx.carbon_intensity} />
            } else if (template === 'data') {
                secondline = <>{ctx.data_reference.map((n)=> <Data key={`key_chat_data_${n.name}`} param={n} />)}</>
            } else if (template === 'help') {
                secondline = <Help param={ctx.help_reference} />
            }
            setAnswer(<>{firstline}{secondline}</>)
        }
    }

    const onGridCallback = (event) => {
        console.log(event)
    }

    return <div className='w-full my-3'>
        <div className='row-right mb-2'>
            <Icon icon='faInfoCircle' size='2x' color='white' />
            <Card sx={{  borderRadius:'10px', width: '100%', marginRight: '10px', backgroundColor: 'rgba(51, 71, 142,0.75)', color: 'white' }}>
                <CardContent sx={{ "&:last-child": { paddingBottom: '16px' }}}>
                    {ask}
                </CardContent>
            </Card>
            <AccountCircleOutlinedIcon />
        </div>
        <div className='row-left mt-1'>
            <InfoOutlinedIcon />
            <Card sx={{ borderRadius: '10px',width: '100%', marginLeft: '10px', backgroundColor: 'rgba(237, 233, 220, 0.5)' }}>
                 <CardContent sx={{ "&:last-child": { paddingBottom: '16px' }}}>
                    {answer}
                    {!answer && <div className='row-left w-full'>
                        <div className='mr-3'>Processing...</div>
                        <ClipLoader color='#000000' size="20" />
                    </div>}
                </CardContent>
            </Card>
        </div>
    </div>
}