import { useState, useEffect, useContext } from 'react'
import { list2 } from '../../../services/Api'
import { Lastupdate, GeneralChart } from '../../../components'
import { StorageFilter } from '.'
import { Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import { FilterList } from '@material-ui/icons';
import { DispatchContext } from "../../../reducers";
import moment from 'moment';

export const StorageModel = () => {

    const dispatch = useContext(DispatchContext)
    const [data, setData] = useState()
    const [expanded, setExpanded] = useState(false)
    const [conditions, setConditions] = useState({ type: 'country', value: [29], name: ['China'], year: moment().format('YYYY-01-01') })

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false)
    }

    const callback = event => {
        setConditions(event)
        setExpanded(false)
    }

    useEffect(() => {
        async function fetch () {
            if (conditions) {
                dispatch({ type: 'spin on' })
                const data = await list2('onshore_inventory', null, conditions)
                dispatch({ type: 'spin off' })
                setData(data)
            }
        }
        fetch()
    }, [conditions])

    return <div>
            <Accordion expanded={expanded === 'panel'} className={`w-95p ${expanded === 'panel' ? '' : 'box-shadow-0'}`} onChange={handleChange('panel')}>
                <AccordionSummary expandIcon={<FilterList />}>
                    <Lastupdate type='storage:onshore:kpler' align='left'/>
                </AccordionSummary>
                <AccordionDetails className='column'>
                    <StorageFilter init={conditions} callback={callback} />
                </AccordionDetails>
            </Accordion>
            <div className="mt-3 row-center">
                {data && conditions && <GeneralChart param={data} />}
            </div>
        </div>
}