import { useState, useEffect, useContext } from 'react';
import { useParams } from "react-router-dom";
import { Grid, Modal, Backdrop, Fade } from '@material-ui/core'
import { list2 } from '../../services/Api'
import { ForwardCurveChart, ForwardCurveDetailChart, Lastupdate } from '../../components';
import { DispatchContext } from "../../reducers";
import { useWindow } from '../../hooks'

export const ForwardCurve = ({ type: initType }) => {
    const { type: path } = useParams()
    const type = initType || path
    const size = useWindow()
    const dispatch = useContext(DispatchContext)
    const [data, setData] = useState()
    const [open, setOpen] = useState(false)
    const [selected, setSelected] = useState()
    
    useEffect(() => {
        async function fetch () {
            if (!type) return
            dispatch({ type: 'spin on' })
            const data = await list2('forwardCurve/curve', type)
            dispatch({ type: 'spin off' })
            setData(data)
        }
        fetch()
    }, [type])

    const onSelect = data => {
        setSelected(data)
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
        setSelected(null)
    }

    return (
        <div className='pb-30'>
            <Lastupdate type={type.startsWith('Price') ? 'forwardPrices' : 'forwardSpreads'} />
            <Grid container className='row-center-start'>
                {data && data.map((dat, i) => (
                    <Grid key={`forward_curve_chart_${i}`} item md={2} className='row-center'>
                        <div className='mx-2 mt-6 column-center w-100' onClick={() => onSelect(dat)}>
                            <ForwardCurveChart data={dat.series} label={dat.label} categories={dat.categories} />
                            <label className='ft-title f-01 bolder'>{dat.label}</label>
                        </div>
                    </Grid>
                ))}
            </Grid>
            <Modal open={open} onClose={handleClose} className='modal' closeAfterTransition 
                BackdropComponent={Backdrop} BackdropProps={{ timeout: 500 }}>
                <Fade in={open}>
                    <div className='modal__curve' style={{ width: '90%', height: size.height*0.7, maxWidth: size.width*0.8 }} >
                        {selected && <div className='column-center p-2'>
                            <label className='ft-title fs-16 bolder mb-4'>{selected.label}</label>
                            <ForwardCurveDetailChart series={selected.series} series1={selected.series1} series2={selected.series2} date={selected.date} label={selected.label} categories={selected.categories} height={size.height*0.7-50} width={size.width*0.8-10} />
                        </div>}
                    </div>
                </Fade>
            </Modal>
        </div>
    )
}