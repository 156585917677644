import { useState, useEffect, useContext, useRef } from 'react';
import { Button, Select, MenuItem, InputLabel, Checkbox, ListItemText, Input, FormControl, Divider } from '@material-ui/core';
import moment from 'moment'
import { list2, pascal } from '../../../services'
import { DispatchContext } from "../../../reducers";
import { Message } from '../../../components'

export const ProductionFilter = ({ init, callback }) => {
    const dispatch = useContext(DispatchContext)
    const childRef = useRef()
    const categories = ['Crude Oil', 'Condensate']
    const [countries, setCountries] = useState([])
    const [grades, setGrades] = useState([])
    const [starts, setStarts] = useState([])
    const [ends, setEnds] = useState([])
    const [category, setCategory] = useState(['Crude Oil', 'Condensate'])
    const [split, setSplit] = useState(init.split)
    const [country, setCountry] = useState(init.country)
    const [grade, setGrade] = useState([init.grade])
    const [start, setStart] = useState(init.start)
    const [end, setEnd] = useState(init.end)

    const getMonthRange = (start, end) => {
        const range = []
        while(start <= end) {
            range.push(start)
            start = moment(start).add(1, 'month').format('YYYY-MM-DD')
        }
        return range
    }

    useEffect(() => {
        async function fetch () {
            dispatch({ type: 'spin on' })
            const { countries, grades, start, end } = await list2('production', 'condition')
            dispatch({ type: 'spin off' })
            setCountries(countries)
            // setGrades(grades)
            // setGrade(grades)
            const range = getMonthRange(start, end)
            setStarts(range)
            setEnds(range)
        }
        fetch()
    }, [])

    useEffect(() => {
        async function fetch () {
            dispatch({ type: 'spin on' })
            const { grade: grades, country: countries } = await list2('production/condition', 'country', { country, start, end })
            dispatch({ type: 'spin off' })
            setCountries(countries)
            setGrades(grades)
            setGrade(grades)
        }
        fetch()
    }, [start, end, country])

    const onSelectCategory = (event) => {
        setCategory(event.target.value)
    }

    const onSelectType = (event) => {
        setSplit(event.target.value)
    }

    const onSelectCountry = (event) => {
        const { value } = event.target
        if (value.includes('select_all')) {
            if (country.length === countries.length) {
                setCountry([])
                setGrades(grades)
            } else {
                setCountry(countries)
                setGrades(grades)
            }
        } else if (value.includes('top_5')) {
            if (country.length === 5 && countries.slice(0, 5).every((c) => country.includes(c))) {
                setCountry(country.filter((c) => !countries.slice(0, 5).includes(c)))
            } else {
                setCountry(countries.slice(0, 5))
            }
        } else if (value.includes('top_10')) {
            if (country.length === 10 && countries.slice(0, 10).every((c) => country.includes(c))) {
                setCountry(country.filter((c) => !countries.slice(0, 10).includes(c)))
            } else {
                setCountry(countries.slice(0, 10))
            }
        } else {
            setCountry(value)
        }
    }
    
    const onSelectGrade = (event) => {
        const { value } = event.target
        if (value.includes('select_all')) {
            if (grade.length === grades.length) {
                setGrade([])
            } else {
                setGrade(grades)
            }
        } else if (value.includes('top_5')) {
            if (grade.length === 5 && grades.slice(0, 5).every((c) => grade.includes(c))) {
                setGrade(grade.filter((c) => !grades.slice(0, 5).includes(c)))
            } else {
                setGrade(grades.slice(0, 5))
            }
        } else if (value.includes('top_10')) {
            if (grade.length === 10 && grades.slice(0, 10).every((c) => grade.includes(c))) {
                setGrade(grade.filter((c) => !grades.slice(0, 10).includes(c)))
            } else {
                setGrade(grades.slice(0, 10))
            }
        } else {
            setGrade(value)
        }
    }

    const onSelectStart = (event) => {
        setStart(event.target.value)
    }

    const onSelectEnd = (event) => {
        setEnd(event.target.value)
    }

    const confirm = () => {
        if (!moment(start).isBefore(moment(end))) {
            childRef.current.showError('illegal month range')
        } else if (category.length === 0) {
            childRef.current.showError('category is empty')
        } else if (grade.length === 0) {
            childRef.current.showError('grade is empty')
        } else if (country.length === 0) {
            childRef.current.showError('country is empty')
        } else {
            callback({ split, country: country.length === countries.length ? [] : country, category, grade: grade.length === grades.length ? [] : grade, start, end })
        }
    }

    return (
        <div className="row-start">
            <Message ref={childRef} />
            <div className='row-between my-2'>
                <FormControl>
                    <InputLabel>Start</InputLabel>
                    <Select value={start} onChange={onSelectStart} >
                        {starts.map((month) => (
                            <MenuItem key={`key_production_start_${month}`} value={month}>
                                {moment(month).format('MMM YYYY')}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormControl>
                    <InputLabel>End</InputLabel>
                    <Select value={end} onChange={onSelectEnd} >
                        {ends.map((month) => (
                            <MenuItem key={`key_production_end_${month}`} value={month}>
                                {moment(month).format('MMM YYYY')}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div> 
            <div className='row-between my-2'>
                <FormControl>
                    <InputLabel>Country</InputLabel>
                    <Select multiple value={country} onChange={onSelectCountry} input={<Input /> } displayEmpty={true}
                        renderValue={(selected) => {
                            if (country.length === countries.length) {
                                return 'World'
                            } else if (selected.includes('Others')) {
                                return 'Multiple Countries';
                            } else if (selected.length === 1) {
                                return selected[0];
                            } else if (selected.length === 0) {
                                return 'No Country Selected'
                            } else {
                                return `${selected.length} Countries`
                            }
                        }} >
                        <MenuItem key={`key_production_country_select_all`} value={'select_all'}>
                            <Checkbox checked={country.length === countries.length} />
                            <ListItemText primary={'All'} />
                        </MenuItem>
                        <MenuItem key={`key_production_country_top_5`} value={'top_5'}>
                            <Checkbox checked={country.length === 5 && countries.slice(0, 5).every((c) => country.includes(c))} />
                            <ListItemText primary={'Top 5'} />
                        </MenuItem>
                        <MenuItem key={`key_production_country_top_10`} value={'top_10'}>
                            <Checkbox checked={country.length === 10 && countries.slice(0, 10).every((c) => country.includes(c))} />
                            <ListItemText primary={'Top 10'} />
                        </MenuItem>
                        <Divider variant="fullWidth" />
                        {countries.map((c) => (
                            <MenuItem key={`key_production_country_${c}`} value={c}>
                                <Checkbox checked={country.indexOf(c) > -1} />
                                <ListItemText primary={c} />
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormControl>
                    <InputLabel>Grade</InputLabel>
                    <Select multiple value={grade} onChange={onSelectGrade} input={<Input />} displayEmpty={true}
                        renderValue={(selected) => {
                            if (grade.length === grades.length) {
                                return 'All Grades'
                            } else if (selected.includes('Others')) {
                                return 'Multiple Grades';
                            } else if (selected.length === 1) {
                                return selected[0].length > 10 ? `${selected[0].slice(0, 7)}...` : selected[0];
                            } else if (selected.length === 0) {
                                return 'No Grade Selected'
                            } else {
                                return `${selected.length} Grades`
                            }
                        }} >
                        <MenuItem key={`key_production_grade_select_all`} value={'select_all'}>
                            <Checkbox checked={grade.length === grades.length} />
                            <ListItemText primary={'All'} />
                        </MenuItem>
                        {grades.length > 5 && <MenuItem key={`key_production_grade_top_5`} value={'top_5'}>
                            <Checkbox checked={grade.length === 5 && grades.slice(0, 5).every((c) => grade.includes(c))} />
                            <ListItemText primary={'Top 5'} />
                        </MenuItem>}
                        {grades.length > 10 && <MenuItem key={`key_production_grade_top_10`} value={'top_10'}>
                            <Checkbox checked={grade.length === 10 && grades.slice(0, 10).every((c) => grade.includes(c))} />
                            <ListItemText primary={'Top 10'} />
                        </MenuItem>}
                        <Divider variant="fullWidth" />
                        {grades.map((g) => (
                            <MenuItem key={`key_production_grade_${g}`} value={g}>
                                <Checkbox checked={grade.indexOf(g) > -1} />
                                <ListItemText primary={g} />
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>
            <div className='row-between my-2'>
                <FormControl>
                    <InputLabel>Category</InputLabel>
                    <Select multiple value={category} onChange={onSelectCategory} input={<Input />} 
                        renderValue={(selected) => {
                            return selected.length === 1 ? selected[0] : 'Crude/Condensate'
                        }} >
                        {categories.map((c) => (
                            <MenuItem key={`key_production_category_${c}`} value={c}>
                                <Checkbox checked={category.indexOf(c) > -1} />
                                <ListItemText primary={c} />
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormControl>
                    <InputLabel>Split By</InputLabel>
                    <Select value={split} onChange={onSelectType} >
                        {['country', 'grade'].map((t) => (
                            <MenuItem key={`key_production_split_${t}`} value={t}>
                                {pascal(t)}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>
            <div className='row-center mt-6'>
                <Button variant='outlined' onClick={confirm} className='ml-2'>Confirm</Button >
            </div>
        </div>
    )
}
