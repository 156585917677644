import { useState, useEffect, useContext } from 'react';
import { useParams } from "react-router-dom";
import { Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import { FilterList } from '@material-ui/icons';
import moment from 'moment';
import { list2 } from '../../../services/Api'
import { DispatchContext } from "../../../reducers";
import { FlowsChart, Lastupdate } from '../../../components';
import { FlowsFilter } from './FlowsFilter';

export const FlowsModel = ({ type: initType }) => {
    const { type: path } = useParams()
    const type = initType || path
    const dispatch = useContext(DispatchContext)
    const [data, setData] = useState()
    const [conditions, setConditions] = useState({ 
        family: type || 16, 
        originValue: ['East of Suez', 'Americas', 'Europe', 'Africa'], 
        origin: 'region', 
        destinationValue: ['China'],  
        destination: 'country', 
        start: moment().subtract(8, 'month').format('YYYY-MM-01'), 
        end: moment().add(3, 'month').endOf('month').format('YYYY-MM-DD'), 
        flow: 'import', 
        split: 'origin',
        grade: []
    })

    const [expanded, setExpanded] = useState(false)

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false)
    }
  
    useEffect(() => {
        async function fetch () {
            dispatch({ type: 'spin on' })
            const data = await list2('flows', 'model', conditions)
            dispatch({ type: 'spin off' })
            setData(data)
        }
        fetch()
    }, [conditions])

    const callback = event => {
        setConditions(event)
        setExpanded(false)
    }

    return (
        <div className="column-center">
            <Accordion expanded={expanded === 'panel'} className={`w-95p ${expanded === 'panel' ? '' : 'box-shadow-0'}`} onChange={handleChange('panel')}>
                <AccordionSummary expandIcon={<FilterList />}>
                    <Lastupdate type='flows' align='left'/>
                </AccordionSummary>
                <AccordionDetails className='column'>
                    <FlowsFilter init={conditions} callback={callback} />
                </AccordionDetails>
            </Accordion>
            <div className="row-center">
                {data && <FlowsChart data={data} /> }
            </div>
        </div>
    )
}