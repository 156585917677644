import { useEffect, useState, useContext, useRef } from "react"
import { Button } from '@material-ui/core'
import { DispatchContext } from "../../reducers";
import { list2, upload, defaultTimeFormatter } from '../../services/Api';
import { Icon, Message } from '../../components'

export const UploadPdf = () => {
    const messageRef = useRef()
    const dispatch = useContext(DispatchContext)
    const [dirname, setDirname] = useState()
    const [file, setFile] = useState()
    const [directories, setDirectories] = useState([])

    useEffect(() => {
        async function fetch() {
            dispatch({ type: 'spin on' })
            const data = await list2('report', 'list')
            dispatch({ type: 'spin off' })
            setDirectories(data)
        }
        fetch()
    }, [])

    const handleUpload = (event, name) => {
        setDirname(name)
        dispatch({ type: 'spin on' })
        const files = event.target.files
        const file = files[0]
        setFile(file)
        if (!file) setDirname(null)
        dispatch({ type: 'spin off' })
    }

    const handleSubmit = async (name) => {
        if (!file) {
            messageRef.current.showError(<>Click&nbsp;<Icon icon='faFolderOpen' color='orange' />&nbsp;to select file then click&nbsp;<Icon icon='faUpload' color='green' />&nbsp;to upload</>)
            return
        } else if (name !== dirname) {
            messageRef.current.showError(<>Wrong button. Click the&nbsp;<Icon icon='faUpload' color='green' />&nbsp;to upload</>)
            return
        }
        dispatch({ type: 'spin on' })
        const result = await upload(name, file)
        dispatch({ type: 'spin off' })
        if (result === true) {
            messageRef.current.showSuccess('Upload Success')
            setFile(null)
            setDirname(null)
        } else {        
            messageRef.current.showError(result.error)
        }
    }

    return (
        <div className='column-center w-full'>
            <Message ref={messageRef} />
            <div className='mt-6 w-90p row-center'>
                Click&nbsp;<Icon icon='faFolderOpen' color='orange' />&nbsp;to select file then click&nbsp;<Icon icon='faUpload' color='green' />&nbsp;to upload
            </div>
            <div className='mt-6 w-90p'>
                {directories.map(({ label, name, pic, lastupdate }) => {
                    return (
                        <div key={`key_directories_${label}`} className='mb-5 row-between'>
                            <div className='row'>
                                <Button component="label" color="primary" fullWidth>
                                    <Icon icon='faFolderOpen' color='orange' size='2x' />
                                    <div className='column'>
                                        <label className='ml-2 dark_blue'>{label}</label>
                                        <div className="w-200 row-between">{pic && <label className='ml-2 gray f-01'>PIC: {pic}</label>}
                                        {lastupdate && <label className='gray f-01'>{defaultTimeFormatter(lastupdate)}</label>}</div>
                                    </div>
                                    <input type="file" hidden onChange={(event) => handleUpload(event, name)} />
                                </Button>
                            </div>
                            <div className='' onClick={() => handleSubmit(name)}>
                                <Icon icon='faUpload' color={file && dirname === name ? 'green' : 'grey'} size='2x' />
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}