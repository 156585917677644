
import { useState, useEffect } from 'react';
import { list2, pascal } from '../../../services'
import { MedianChart } from '../../../components'

export const SDMedian = ({ conditions, type }) => {

    const [data, setData] = useState()
    const [title, setTitle] = useState('')
    const [subtitle, setSubtitle] = useState('')
    const { region, subRegion, country, year } = conditions

    useEffect(() => {
        async function fetch () {
            const geo = `${type === 'liquid' ? 'Asia' : region === 'All' ? 'Global' : region.name}${subRegion === 'All' ? '' : ` - ${subRegion.name}`}${country === 'All' ? '' : ` - ${country.name}`}`
            setTitle(geo)
            setSubtitle(`Product: ${pascal(type)}`)
            const query = { year }
            if (region !== 'All') query.regionId = region.id
            if (subRegion !== 'All') query.subRegionId = subRegion.id
            if (country !== 'All') query.countryId = country.id
            const data = await list2('balance', type, query) 
            setData(data)
        }
        fetch()
    }, [region, subRegion, country, year, type])

    return (
        <div className="">
            { data && <MedianChart type={type} data={data} title={title} subtitle={subtitle} year={year} /> }
        </div>
    )
}