import { useState, useEffect } from 'react';
import { Button, Select, MenuItem } from '@material-ui/core';
import moment from 'moment'
import { list2 } from '../../../services/Api'

export const SDFilter = ({ type, init, callback }) => {
    const [regions, setRegions] = useState([])
    const [subRegions, setSubRegions] = useState([])
    const [countries, setCountries] = useState([])
    const [region, setRegion] = useState(init.region)
    const [subRegion, setSubRegion] = useState(init.subRegion)
    const [country, setCountry] = useState(init.country)
    const [start, setStart] = useState(init.start)
    const starts = [...Array(6).keys()].flatMap((i) => {
        const year = moment().subtract(i, 'year').format('YYYY')
        return [...Array(12).keys()].map((e) => moment(`${year}${12 - e}`, 'YYYYM').format('YYYY-MM-01'))
    })

    useEffect(() => {
        async function fetch () {
            const [regions] = await Promise.all([
                list2('region')
            ])
            setRegions(regions)
        }
        fetch()
    }, [])

    useEffect(() => {
        setSubRegions(region.subRegions || [])
    }, [region])

    useEffect(() => {
        setCountries(subRegion.countries || [])
    }, [subRegion])

    const onSelectRegion = event => {
        setCountry('All')
        setSubRegion('All')
        setRegion(event.target.value)
    }

    const onSelectSubRegion = event => {
        setCountry('All')
        setSubRegion(event.target.value)
    }

    const onSelectCountry = event => {
        setCountry(event.target.value)
    }

    const onSelectStart = event => {
        setStart(event.target.value)
    }

    const confirm = () => {
        callback({ type, region, subRegion, country, start, end: moment(start).add(12, 'month').format('YYYY-MM-01') })
    }
    return (
        <div className="row-start">
            <div className='row-between my-2'>
                <label className=''>Start Month</label>
                <Select value={start} onChange={onSelectStart}>
                    {starts.map((item) => (
                        <MenuItem key={`sd_start_key_${item}`} value={item}>{moment(item).format('MMM YY')}</MenuItem>
                    ))}
                </Select>
            </div> 
            { type !== 'liquid' && <div className='row-between my-2'>
                <label className=''>Region</label>
                <Select value={region} onChange={onSelectRegion}>
                    <MenuItem value="All">Global</MenuItem>
                    {regions.map(region => (
                        <MenuItem key={`region_key_${region.id}`} value={region}>{region.name}</MenuItem>
                    ))}
                </Select>
            </div> }
            { type !== 'liquid' && subRegions.length > 0 && <div className='row-between my-2'>
                <label className=''>Sub-Region</label>
                <Select value={subRegion} onChange={onSelectSubRegion}>
                    <MenuItem value="All">All</MenuItem>
                    {subRegions.map(subRegion => (
                        <MenuItem key={`subRegion_key_${subRegion.id}`} value={subRegion}>{subRegion.name}</MenuItem>
                    ))}
                </Select>
            </div> } 
            { type !== 'liquid' && countries.length > 0 && <div className='row-between my-2'>
                <label className=''>Country</label>
                <Select value={country} onChange={onSelectCountry}>
                    <MenuItem value="All">All</MenuItem>
                    {countries.map(country => (
                        <MenuItem key={`country_key_${country.id}`} value={country}>{country.name}</MenuItem>
                    ))}
                </Select>
            </div> }
            <div className='row-center mt-6'>
                <Button variant='outlined' onClick={confirm} className='ml-2'>Confirm</Button >
            </div>
        </div>
    )
}
