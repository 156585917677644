import { useState, useEffect, useContext } from 'react';
import { useParams } from "react-router-dom";
import { Grid, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import { FilterList } from '@material-ui/icons';
import moment from 'moment';
import { SDFilter } from '.';
import { Lastupdate, BalanceChart, MedianChart, LiquidChart } from "../../../components"
import { list2, pascal } from "../../../services"
import { DispatchContext } from "../../../reducers";

const type_bz = ['benzene', 'styrene', 'phenol', 'aniline', 'cyclohexane', 'net_bz'].map((key) => ({ name: key === 'net_bz' ? 'Net' : pascal(key), key }))
const type_px = ['px', 'pta', 'net_px'].map((key) => ({ name: key === 'net_px' ? 'Net' : key.toUpperCase(), key }))

export const SupplyDemandModel = ({ type: initType }) => {
    
    const { type: path } = useParams()
    const type = initType || path
    const dispatch = useContext(DispatchContext)
    const [conditions, setConditions] = useState({ start: moment().format('YYYY-01-01'), end: moment().add(12, 'month').format('YYYY-MM-01'), region: 'All', subRegion: 'All', country: 'All' })
    const [expanded, setExpanded] = useState(false)
    const [data, setData] = useState()
    const [title, setTitle] = useState('')
    const [start, setStart] = useState()

    useEffect(() => {
        async function fetch () {
            const { region, subRegion, country, start, end } = conditions
            setStart(start)
            const geo = `${type === 'liquid' ? 'Asia' : region === 'All' ? 'Global' : region.name}${subRegion === 'All' ? '' : ` - ${subRegion.name}`}${country === 'All' ? '' : ` - ${country.name}`}`
            const query = { start, end }
            if (region !== 'All') query.regionId = region.id
            if (subRegion !== 'All') query.subRegionId = subRegion.id
            if (country !== 'All') query.countryId = country.id
            dispatch({ type: 'spin on' })
            const data = await list2('balance', type, query) 
            dispatch({ type: 'spin off' })
            setData(data)
            const ttl = `${geo} ${pascal(type)}`
            setTitle(ttl)
        }
        fetch()
    }, [conditions, type])

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false)
    }

    const callback = event => {
        setConditions(event)
        setExpanded(false)
    }

    return (
        <div className="column-center mb-6">
            <Accordion expanded={expanded === 'panel'} className={`w-95p ${expanded === 'panel' ? '' : 'box-shadow-0'}`} onChange={handleChange('panel')}>
                <AccordionSummary expandIcon={<FilterList />}>
                    <Lastupdate type={`balance:${type}`} align='left'/>
                </AccordionSummary>
                <AccordionDetails className='column'>
                    <SDFilter init={conditions} callback={callback} />
                </AccordionDetails>
            </Accordion>
            {type !== 'liquid' && data && <Grid container className='row-center'>
                <Grid item md={12} className='row-center w-full'>
                    <BalanceChart data={data} start={start} title={title} />
                </Grid>
                <Grid item md={6} className='row-center'>
                    <MedianChart type='balance' data={data} start={start} title={title} />
                </Grid>
                <Grid item md={6} className='row-center'>
                    <MedianChart type='production' data={data} start={start} title={title} />
                </Grid>
                <Grid item md={6} className='row-center'>
                    <MedianChart type='consumption' data={data} start={start} title={title} />
                </Grid>
                <Grid item md={6} className='row-center'>
                    <MedianChart type='yield' data={data} start={start} title={title} />
                </Grid>
            </Grid>}
            {type === 'liquid' && data && <Grid container>
                <Grid item md={6} className='row-center'>
                    <LiquidChart type={type_bz} data={data} title='Asia Benzene Balance' start={start} />
                </Grid>
                <Grid item md={6} className='row-center'>
                    <LiquidChart type={type_px} data={data} title='Asia Paraxylene Balance' start={start} />
                </Grid>
            </Grid>}
        </div>
    )
}

export const SDItem = ({ product, type }) => {

    const [data, setData] = useState()
    const [title, setTitle] = useState('')
    const [subject, setSubject] = useState()
    const [csvData, setCsvData] = useState()
    const start = moment().subtract(6, 'month').format('YYYY-MM-DD'), end = moment().add(6, 'month').format('YYYY-MM-DD')

    useEffect(() => {
        async function fetch () {
            const geo = `${product === 'liquid' ? 'Asia' : 'Global'}`
            const query = { start, end }
            const data = await list2('balance', product, query) 
            setData(data)
            const ttl = `${geo} ${pascal(type)}`
            setTitle(ttl)
            const subject = `${ttl} Supply&Demand ${start}-${end} (${product === 'liquid' ? 'KT' : 'MBD'})`
            setSubject(subject)
            const processed = product === 'liquid' ? data.map((item) => ({
                Date: item.date, 
                BZ: item.benzene,
                Styrene: item.styrene,
                Phenol: item.phenol,
                Aniline: item.aniline,
                Cyclohexane: item.cyclohexane,
                'Net BZ': item.net_bz,
                PX: item.px,
                PTA: item.pta,
                'Net PX': item.net_px
            })) : data.map((item) => ({ 
                Date: moment(item.date).format('YYYY-MM-DD'), 
                Production: item.production, 
                '5-Year-Max Production': item.production_max, 
                '5-Year-Min Production': item.production_min, 
                '5-Year-Median Production': item.production_median, 
                Consumption: item.consumption, 
                '5-Year-Max Consumption': item.consumption_max, 
                '5-Year-Min Consumption': item.consumption_min, 
                '5-Year-Median Consumption': item.consumption_median, 
                'Gross Balance': item.balance, 
                '5-Year-Max Gross Balance': item.balance_max, 
                '5-Year-Min Gross Balance': item.balance_min, 
                '5-Year-Median Gross Balance': item.balance_median
            }))
            setCsvData([{ filename: `${subject}.csv`, data: processed, type: 'text/csv' }])
        }
        fetch()
    }, [product, type])

    return <div className="column-center mb-6">
        <Lastupdate type={`balance:${product}`} />
        {data && <div className='my-2'>
            {product !== 'liquid' && type === 'balance' && <Grid container>
                <Grid item md={6}>
                    <BalanceChart data={data} start={start} title={title} />
                </Grid>
                <Grid item md={6}>
                    <MedianChart type={type} data={data} start={start} title={title} />
                </Grid>
            </Grid>}
            {product !== 'liquid' && type !== 'balance' && <MedianChart type={type} data={data} start={start} title={title} />}
            {product === 'liquid' && <Grid container>
                <Grid item md={6}>
                    <LiquidChart type={type_bz} data={data} title='Asia Benzene Balance' start={start} />
                </Grid>
                <Grid item md={6}>
                    <LiquidChart type={type_px} data={data} title='Asia Paraxylene Balance' start={start} />
                </Grid>
            </Grid>}
        </div>}
    </div>
}