import moment from "moment"
import { Icon } from "../../../components"

const process = (text) => {
    const start = text.indexOf('https://t.co/')
    if (start < 0) {
        return text
    } else {
        const end = start + 23
        const firstPart = text.slice(0, start)
        const link = text.slice(start, end)
        const secondPart = text.slice(end)
        return `${firstPart}<a href=${link} target="_blank" class="f-02 no-decoration twitter_blue">${link}</a>${process(secondPart)}</>`
    }
}

const processAll = (text) => {
    const data = process(text)
    return <div className="" dangerouslySetInnerHTML={{__html: data }}></div>
}

export const Tweet = ({ data }) => {
    const { date, text, author, username, media_url, avatar_url, url } = data
    const processed = processAll(text)
    return (
        <div className='column-start p-2 ft-title'>
            <div className="row-between">
                <div className='row-left'>
                    <div className="column-center mr-1">
                        <img className="avatar" src={avatar_url} style={{ width: 50, height: 50 }} />
                    </div>
                    <div className='column-start'>
                        <div className='f-04 bolder'>{author}</div>
                        <div className='f-02 bold grey'>@{username}</div>
                    </div>
                </div>
                <a href={url} target="_blank" className='bold no-decoration twitter_blue'><Icon icon='faTwitter' size='1x' /></a>
            </div>
            <div className='mt-3 f-04 ft-tweet'>{processed}</div>
            {media_url && <div className="mt-2">
                <img src={media_url} style={{ maxWidth: 960, width: "100%" }} />
            </div>}
            <div className='row-between mt-4'>
                <div className="row-between grey w-100">
                    <div className='grey'><Icon icon='faComment' size='1x' /></div>
                    <div className='grey'><Icon icon='faRetweet' size='1x' /></div>
                    <div className='grey'><Icon icon='faHeart' size='1x' /></div>
                </div>
                <label className="f-01 grey">{moment(date).format('YYYY-MM-DD HH:mm')}</label>
            </div>
        </div>

    )
}