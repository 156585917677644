
import { Icon } from '../../../components'

export const Tender = ({ tender }) => {

    const goTop = (event) => {
        event.preventDefault()
        document.getElementsByName('_top')[0].scrollIntoView(0, -200)
    }

    return (
        <div className='column-start px-1 pb-15'>
            {tender.paragraph && tender.paragraph.map((text, i) => <div key={`key_tender_paragraph_${i}`} className='mt-4 ft-news fs-17 line-height-news'><label className=''>{text}</label></div>)}
            <div className='row-between mt-4 aramco_blue'>
                <div className='' onClick={goTop}><Icon icon='faArrowAltCircleUp' size='1x' /></div>
            </div>
        </div>

    )
}