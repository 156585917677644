import { useState, useEffect } from "react";
import { list2, sleep } from '../services'
import { PriceLiveGrid } from "../components";

export const PriceLive = () => {
    const [prices, setPrices] = useState([])
    
    async function fetch () {
        console.log('fetching...')
        const data = await list2('price', 'live')
        setPrices(data)
    }

    const onVisibilityChange = () => {
        console.log('visibility change', document.hidden)
        if (!document.hidden) {
            fetch()
        }
    }

    useEffect(() => {
        fetch()
        document.addEventListener("visibilitychange", onVisibilityChange)
        return () => {
            document.removeEventListener("visibilitychange", onVisibilityChange)
        }
    }, [])

    useEffect(() => {
        async function refresh() {
            await sleep(300000)
            if (!document.hidden) fetch()
        }
        refresh()
    }, [prices])
    
    return (
        <div className="p-2">
            <PriceLiveGrid data={prices} />
        </div>
    );
}