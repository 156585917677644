import { createContext, useContext, useEffect, useState } from 'react'
import { useHistory } from "react-router-dom"
import moment from 'moment'
import { Button, Card, CardContent, Dialog, DialogContent, InputAdornment, TextField } from '@mui/material'
import { Send as SendIcon, InfoOutlined as InfoOutlinedIcon, AccountCircleOutlined as AccountCircleOutlinedIcon } from '@mui/icons-material'
import { list2, post2, sleep } from '../services'
import { DispatchContext } from "../reducers"
import { Icon } from '../components'
import ClipLoader from "react-spinners/ClipLoader";

const defaultContext= {
    format: 'table',
    language: 'en-US',
    mode: 'fast',
    read: 'off',
    subject: 'news'
}
export const ConversationContext = createContext(defaultContext);

export const ConversationDisclaimer = ({ callback }) => {
    const [disclaimer, setDisclaimer] = useState()
    const onClear = () => {
        callback({ type: 'clear' })
    }
    useEffect(() => {
        list2('ai/agent/disclaimer_conversation').then((d) => setDisclaimer(d))
    }, [])
    
    return <div className='fs-12 gray w-full bg-white fixed top-45 px-2'>
        <div>{disclaimer}</div>
        <div className='row-center w-full'>
            <Button onClick={onClear} component="label">
                Clear Conversation
            </Button>
        </div>
    </div>
}

export const ConversationInput = ({ callback }) => {
    
    const [content, setContent] = useState('')

    const onSend = () => {
        if (content) {
            callback(content)
            setContent('')
        }
    }

    const onChange = (event) => {
        setContent(event.target.value)
    }

    const onKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault()
            onSend()
        }
    }
    
    return <div className='fixed bottom-50 w-full bg-white row'>  
        <TextField multiline InputProps={{ endAdornment: 
            <InputAdornment position="end">
                <Button onClick={onSend} component="label" sx={{ minWidth: '32px', padding: '0px' }}>
                    <SendIcon />
                </Button>
            </InputAdornment>,
        }} className='text-input' size="small" placeholder={`Type "help" for more information`} value={content} onInput={onChange} onKeyDown={onKeyDown} variant="outlined" />
        
    </div>
}

const MessageUnitContent = ({ content }) => {
    return <div className='column-left my-2'>
        {content.split('\n\n').map((p, i) => {
            return <div key={`message_unit_content_paragraph_${i}`} className='column-left'>
                {p.split('\n').map((l, j) => <div key={`message_unit_content_line_${i}_${j}`} className='row-left'>
                    <div className='mr-3'>{l}</div>
                </div>)}
            </div>
        })}
    </div>
}

const MessageUnitImage = ({ image_id, callback }) => {
    
    const [src, setSrc] = useState()
    
    useEffect(() => {
        async function fetch() {
            const data = await list2('ai/agent/image', image_id)
            setSrc(data)
            callback({ type: 'image' })
        }
        fetch()
    }, [])

    return <>
        {src && <img src={src} width={'100%'} height={'100%'} />}
        {!src && <ClipLoader color='#000000' size={20} />}
    </>
}

const MessageUnitData = ({ datafile_id, callback }) => {

    const [file, setFile] = useState()
    
    useEffect(() => {
        async function fetch() {
            const data = await list2('ai/agent/data', datafile_id)
            setFile(data)
            callback({ type: 'data' })
        }
        fetch()
    }, [])

    const onDownload = async () => {
        const url = encodeURI(`data:text/csv;charset=utf-8,${file.csv}`)
        const link = document.createElement('a');
        link.setAttribute('href', url);
        link.setAttribute('download', file.filename);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    return <>
        {file && <div className='row-left hover_pointer' onClick={onDownload} onTouchEnd={onDownload}>
            <Icon icon='faFileCsv' size='1x' color='green' />
            <div class='ml-2 underline blue'>{file.filename}</div>
            <div class='ml-2 underline blue'>({file.size})</div>
        </div>}
        {!file && <ClipLoader color='#000000' size={20} />}
    </>
}

const Summarization = ({ param: { title, summarization, date, source } }) => {
    return <div className='column-left mb-3'>
        <div className="ft-news-title">{title}</div>
        <div className="ft-news-title-sm my-2">{`${source} ${moment(date).format('MMM D')}`}</div>
        <div className='ft-news fs-17'>
            {summarization}
        </div>
    </div>
}

const MessageUnitNews = ({ datafile_id, callback }) => {

    const [news, setNews] = useState()
    const [type, setType] = useState()
    const [selected, setSelected] = useState()
    const [open, setOpen] = useState(false)

    useEffect(() => {
        async function fetch() {
            const { news, type } = await list2('ai/agent/news', datafile_id)
            setNews(news)
            setType(type)
            callback({ type: 'news' })
        }
        fetch()
    }, [])

    const handleClose = () => {
        setSelected(null)
        setOpen(false)
    };
    const onSelect = (event) => {
        setSelected(event)
        setOpen(true)
    }
    return <>
        {news && <div className='column-left'>
            {type === 'reference' && <div className='fs-18 bold-5 mb-2'>Reference:</div>}
            {news.map(({ date, title, sid, source, ...rest }, idx) => {
                return <div key={`key_headline_${sid}`} className={`ft-news fs-14 blue underline`} onClick={() => onSelect({ date, title, sid, source, ...rest })}>
                    {`[${idx + 1}] ${title} [${source} ${moment(date).format('MMM D')}]`}
                </div>
            })}
            <Dialog open={open} onClose={handleClose} >
                <DialogContent>
                    {selected && <Summarization param={selected} />}
                </DialogContent>
            </Dialog>
        </div>}
        {!news && <ClipLoader color='#000000' size={20} />}
    </>
}

const MessageUnitRole = ({ role }) => {
    return <div className={`mb-1 w-full ${role === 'user' ? 'row-right' : 'row-left'}`}>
        {role === 'assistant' && <InfoOutlinedIcon />}
        {role === 'user' && <AccountCircleOutlinedIcon />}
    </div>
}

const MessageUnit = ({ message: { role, type, content, image_id, datafile_id }, callback }) => {
    const backgroundColor = role === 'user' ? '#33478e' : 'rgba(237, 233, 220, 0.5)'
    const color = role === 'user' ? 'white' : undefined
    return <div className={'column-left my-2'}>
        <MessageUnitRole role={role} />
        {content && <Card sx={{ borderRadius: '10px', width: '100%', paddingInline: '16px', marginLeft: '5px', marginRight: '5px', backgroundColor, color }}>
            <CardContent sx={{ "&:last-child": { paddingBottom: '5px' }, padding: '0px' }}>
                {content && <MessageUnitContent content={content} />}
            </CardContent>
        </Card>}
        {['image', 'data', 'news'].includes(type) && <Card sx={{ borderRadius: '10px', width: '100%', paddingInline: '16px', marginLeft: '5px', marginRight: '5px', backgroundColor, color, marginTop: '10px' }}>
            <CardContent sx={{ "&:last-child": { paddingBottom: '5px' }, padding: '0px' }}>
                {type === 'image' && <MessageUnitImage image_id={image_id} callback={callback} />} 
                {type === 'data' && <MessageUnitData datafile_id={datafile_id} callback={callback} />}
                {type === 'news' && <MessageUnitNews datafile_id={datafile_id} callback={callback} />}
            </CardContent>
        </Card>}
    </div>
}

const ConversationDetail = ({ step_status, step_detail }) => {
    return <div className='w-full pl-5 pb-65 pt-25 row-left my-2'>
        {step_status !== 'completed' && <ClipLoader color='#000000' size={20} />}
        <div name='_bottom' className='ml-3'>{step_detail}</div>
    </div>
}

const ConversationContent = ({ messages, callback }) => {
    return <div className='w-full px-2 pt-65'>
        {messages.filter(({ role }) => role !== 'system').map((message) => <MessageUnit key={`message_unit_${message.id}`} message={message} callback={callback} />)}
    </div>
}

export const Conversation = () => {

    const history = useHistory()
    const dispatch = useContext(DispatchContext)
    const [context, setContext] = useState(defaultContext)
    const [conversationId, setConversationId] = useState()
    const [messages, setMessages] = useState([])
    const [status, setStatus] = useState('completed')
    const [detail, setDetail] = useState()

    const toBottom = () => {
        document.getElementsByName('_bottom')[0].scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' })
    }

    useEffect(() => {
        list2('auth').then(async (auth) => { 
            if (auth !== true) {
                history.push('/') 
            } else {
                dispatch({ type: 'spin on' })
                const { id, messages } = await list2('ai/agent', 'conversation')
                dispatch({ type: 'spin off' })
                console.log(id, messages)
                setConversationId(id)
                setMessages(messages)
                toBottom()
            }
        })
    }, [])

    const checkStatus = async () => {
        const { step_status, step_detail } = await list2('ai/agent/conversation', conversationId)
        console.log('check status...', { step_status, step_detail })
        setStatus(step_status)
        setDetail(step_detail)
    }

    useEffect(() => {
        console.log('status change', status)
        toBottom()
        if (status !== 'completed' && status !== 'pending') {
            setStatus('pending')
            sleep(5000).then(() => checkStatus())
        }
    }, [status])

    const changeContext = (newContext) => {
        if (newContext) setContext(newContext)
    }

    // existing messages remain
    // add input to new message
    // 

    const onReceiveInput = async (content) => {
        setMessages([...messages, { role: 'user', content }])
        setStatus('pending')
        setDetail('Sending...')
        toBottom()
        post2(`ai/agent/${conversationId}`, { question: content }).then(({ data }) => {
            setMessages(data)
            setStatus('completed')
            setDetail(null)
            toBottom()
        })
        await sleep(5000)
        await checkStatus()
    }

    const onClear = async () => {
        dispatch({ type: 'spin on' })
        const { id, messages } = await list2('ai/agent', 'clear_conversation')
        dispatch({ type: 'spin off' })
        console.log(id, messages)
        setConversationId(id)
        setMessages(messages)
        setDetail(null)
        setStatus('completed')
    }

    const onCallback = async (input) => {
        console.log(input)
        toBottom()
    }

    return <ConversationContext.Provider value={{ context, changeContext }} >
        <ConversationDisclaimer callback={onClear} />
        <ConversationContent messages={messages} callback={(input) => onCallback(input)} />
        <ConversationDetail step_status={status} step_detail={detail} />
        <ConversationInput callback={(input) => onReceiveInput(input)} />
    </ConversationContext.Provider>
}
