import React, { useState, useEffect } from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import useWindow from '../../hooks/useWindow'
import { doubleChartWidth, chartHeight, post2, list2 } from '../../services';
import exportingModule from "highcharts/modules/exporting";
import exportDataModule from 'highcharts/modules/export-data'
exportingModule(Highcharts);
exportDataModule(Highcharts);

export const PieChart = ({ data }) => {

    const [options, setOptions] = useState({})
    const size = useWindow()

    const [exp, setExp] = useState(false)
    useEffect(() => {
        async function fetch() {
            const auth = await list2('auth')
            setExp(auth === true)
        }
        fetch()
    }, [])

    useEffect(() => {
        const { series, title, subtitle } = data
        const options = {
            chart: {
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                type: 'pie',
                width: doubleChartWidth(size.width),
                height: chartHeight(size.height)
            },
            exporting: exp ? {
                buttons: {
                    contextButton: {
                        enabled: true,
                        align: 'left',
                        menuItems: ["downloadPNG", "downloadPDF", "downloadCSV", "separator",
                            {
                                text: 'Send Email',
                                onclick: function() {
                                    const csv = this.getCSV()
                                    const html = this.getSVG()
                                    const title = this.getFilename()
                                    post2('user/export_file', { csv, html, title })
                                }
                            }
                        ]
                    }
                }
            } : {
                buttons: {
                    contextButton: {
                        enabled: false
                    }    
                }
            },
            title: {
                text: title,
            }, 
            subtitle: {
                text: subtitle,
            },
            colors: Highcharts.map(Highcharts.getOptions().colors, function (color) {
                return {
                    radialGradient: {
                        cx: 0.5,
                        cy: 0.3,
                        r: 0.7
                    },
                    stops: [
                        [0, color],
                        [1, Highcharts.color(color).brighten(-0.3).get('rgb')] // darken
                    ]
                };
            }),
            tooltip: {
                formatter: function() {
                    return `<b>${this.point.name}</b><br /><b>${this.point.y}</b> ${this.point.unit}`;
                }
            },
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    dataLabels: {
                        enabled: true,
                        distance: -50,
                        format: '<b>{point.name}</b><br /><b>{point.y}</b> {point.unit}',
                        connectorColor: 'silver'
                    }
                }
            },
            legend:{
                maxHeight: 70
            },
            series
        }
        setOptions(options)
    }, [data, size, exp]);

    return (<div className='row-center'>
        <HighchartsReact
            highcharts={Highcharts}
            options={options}
        />
    </div>)
}