import React, { useEffect, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { gridHeight } from '../../services/Api';
import useWindow from '../../hooks/useWindow'
import cellRenderers from './CellRenderer'
import valueFormatters from './ValueFormatter'

export const HeaderBreakWord = (props) => {
    const { header } = props
    return <div className='column-center break-word bold-7 w-full'>{header}</div>
}

export const ChatGrid = ({ param, callback }) => {
    const [gridApi, setGridApi] = useState();
    const [rowData, setRowData] = useState([])
    const [columnDefs, setColumnDefs] = useState([])
    const size = useWindow()

    const onGridReady = (params) => {
        setGridApi(params.api);
        sizeToFit();
        window.onresizable = () => {
            sizeToFit();
        }
    };
  
    const sizeToFit = () => {
        if (gridApi) gridApi.sizeColumnsToFit();
    };

    useEffect(() => {
        const { data, colDef } = param
        if (data && colDef) {
            const coldef = colDef.map(({ chart, valueFormatter, cellRenderer, ...rest }) => ({
                valueFormatter: valueFormatters[valueFormatter], 
                cellRenderer: cellRenderers[cellRenderer], 
                onCellClicked: param => onClickCallback(param), ...rest 
            }))
            setColumnDefs(coldef)
            setRowData(data)
        }
    }, [param])
    
    const onClickCallback = (param) => {
        callback(param)
    }
    
    return (
        <div className="column-center w-full">
            <div className="ag-theme-alpine ag-packed" style={{ maxHeight: 500, height: Math.min(gridHeight(param.data.length, 36), size.height*0.6, 500), width: '99%' }}>
                <AgGridReact rowData={rowData} columnDefs={columnDefs} rowHeight={36} onGridReady={onGridReady} headerHeight={72}
                    frameworkComponents={{ HeaderBreakWord }}
                    defaultColDef={{
                        flex: 1,
                        minWidth: 60,
                        wrapText: true,
                        suppressMovable: true,
                        cellStyle: { 
                            textAlign: 'center',
                            height: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center' 
                        }
                    }}
                />
            </div>
        </div>
    );
};
