import { useState, useEffect, useContext } from 'react';
import { useParams } from "react-router-dom";
import { Paper } from "@mui/material";
import { DispatchContext } from "../../../reducers";
import { list2 } from '../../../services'
import { Player } from '../../../components';
import { NewsSum, NewsSearch } from '.'

export const NewsSummaries = ({ type: initType }) => {
    const { type: path } = useParams()
    const type = initType || path
    const dispatch = useContext(DispatchContext)
    const [news, setNews] = useState()
    const [text, setText] = useState()
    const [playing, setPlaying] = useState()
    const [exp, setExp] = useState(false)

    async function fetch () {
        if (!type) return
        dispatch({ type: 'spin on' })
        const [data, auth] = await Promise.all([
            list2('news', type),
            list2('auth')
        ])
        dispatch({ type: 'spin off' })
        setNews(data)
        setExp(auth === true)
    }

    useEffect(() => {
        fetch()
    }, [type])

    const onPlay = (event) => {
        const { title, paragraph, id, stop } = event
        if (stop) {
            setText(null)
            setPlaying(null)
        } else {
            const txt = paragraph.reduce((pre, cur) => `${pre}${cur.replace(/</g, '').replace(/>/g, '')}\n`, '\n')
            setText(`\n${title}${txt}`)
            setPlaying(id)
        }
    }

    const onPlayEnd = (event) => {
        if (event === 'end') {
            setText(null)
        }
    }

    const onSearch = async (data) => {
        if (data) {
            setNews(data)
        } else {
            fetch()
        }
    }

    return (<>
        <div className='column-center pt-2'>
            <a name='_top'></a>
            {news &&
            <div className='w-95p'>
                <div className='p-1 my-3'>
                    {exp && <NewsSearch callback={onSearch} />}
                    {text && <div className='fixed w-full row-center'><Player text={text} callback={onPlayEnd} /></div>}
                    {news.map((item) => {
                        const { id } = item
                        return (
                            <div className='mb-4 column-start' key={`key_news_item_${id}`} >
                                <Paper>
                                    <div className=''>
                                        <NewsSum news={item} playing={playing===id} exp={exp} callback={onPlay} />
                                    </div>
                                </Paper>
                            </div>
                        )
                    })}
                </div>
            </div>}
        </div>
        </>
    )
}