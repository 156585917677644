import { Tweet } from "."

export const TweetUnits = ({ data: tweets }) => {

    return (
        <div className='column-center mt-2 px-1'>
            <a name='_top'></a>
            {tweets.map((data) => {
                return (
                    <div className='mb-6 column-start border-1-grey w-full mw-1000' key={`key_tweets_item_${data.id}`} >
                        <Tweet data={data} />
                    </div>
                )
            })}
        </div>
    )
}