
import { useState, useEffect, useContext } from 'react'
import { useParams } from "react-router-dom";
import { Grid } from '@material-ui/core'
import { list2 } from '../../services/Api'
import { Lastupdate, PriceChart, PriceGrid, GeneralChart } from '../../components'
import { DispatchContext } from "../../reducers";

export const MarketView = ({ type: initType }) => {
    const { type: path } = useParams()
    const type = initType || path
    const dispatch = useContext(DispatchContext)
    const [prices, setPrices] = useState([])

    useEffect(() => {
        async function fetch () {
            if (!type) return
            setPrices([])
            dispatch({ type: 'spin on' })
            const combo = await list2('price/combo', type)
            dispatch({ type: 'spin off' })
            setPrices(combo)
        }
        // console.log(type, path)
        if (type === path) fetch()
    }, [type])

    return (
        <div className='column-center mb-6'>
            <Lastupdate type='price' />
            <Grid container className='row-center'>
                {prices.map(price => {
                    return (
                    <Grid key={`key_price_grid_double_${price.name}`} item md={6} className='row-center w-full'>
                        {price.category === 'chart' && <PriceChart key={`key_price_chart_${price.id}`} data={price.data} label={price.name} config={price.config} />}
                        {price.category === 'table' && <PriceGrid key={`key_price_grid_${price.id}`} name={price.name} data={price.data} config={price.config} />}
                        {price.category === 'chart_ea' && <GeneralChart key={`key_price_chart_${price.id}`} param={price} />}
                    </Grid>
                )})}
            </Grid>
        </div>
    )
}