import { useState, useEffect, useContext } from 'react';
import { useParams } from "react-router-dom";
import { Grid, Backdrop, Fade, Modal } from '@material-ui/core'
import { Bar, Lastupdate, StorageChart, OtherStocksGrid, EiaChart } from '../../../components';
import { DispatchContext } from "../../../reducers";
import { list2 } from '../../../services'
import { useWindow } from '../../../hooks'



const options = [
    { name: 'onshore:singapore', label: 'Singapore' },
    { name: 'onshore:fujairah', label: 'Fujairah' },
    { name: 'onshore:ara', label: 'ARA' },
]

export const OtherStocks = () => {
    const [tab, setTab] = useState('onshore:singapore')
    const onSelectTab = event => {
        setTab(event)
    }
    return <div>
        <Bar options={options} cls={'thirdbar'} callback={onSelectTab} />
        <OtherStock type={tab} />
    </div>
}

export const OtherStock = ({ type: initType }) => {
    const { type: path } = useParams()
    const type = initType || path
    const dispatch = useContext(DispatchContext)
    const [data, setData] = useState()
    const [open, setOpen] = useState()
    const [selected, setSelected] = useState()
    const size = useWindow()

    useEffect(() => {
        async function fetch () {
            if (!type) return
            dispatch({ type: 'spin on' })
            const result = await list2('storage/onshore', type)
            dispatch({ type: 'spin off' })
            setData(result)
        }
        fetch()
    }, [type])

    const onChartClick = (event) => {
        setSelected(event.data.chart)
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
        setSelected(null)
    }

    return ( 
        <div className='column px-1 mb-6'>
        <div className='row-right pr-2'>
            <Lastupdate type='storage:onshore:eia' align='left' />
        </div>
        <div  className='column-center my-2'>
            {data && data.map(({ name, config, data }) => 
            <div  key={`key_other_storage_${name}`} className="column-center w-full my-2">
                 <div className='fs-20 bold-5'>{name}</div>
                 <Grid container className='row-center mt-5 mb-2'>
                    <OtherStocksGrid param={data} callback={onChartClick} />
                </Grid>
            </div>
            )}       
        
        </div>
       

        <Modal open={open} onClose={handleClose} className='modal' closeAfterTransition 
                BackdropComponent={Backdrop} BackdropProps={{ timeout: 500 }}>
                <Fade in={open}>
                    <div className='modal__eia' style={{ width: '98%', height: size.height*0.7, maxWidth: size.width*0.98 }} >
                        {selected && <div className='column-center p-2'>
                            <EiaChart param={selected} />
                        </div>}
                    </div>
                </Fade>
            </Modal>
    </div>
    )
}