import { useState, useEffect, useContext } from 'react';
import { useParams } from "react-router-dom";
import { Grid,Modal, Backdrop, Fade } from '@material-ui/core'
import { list2 } from '../../../services/Api'
import { EiaChart, EiaGrid, Lastupdate } from '../../../components';
import { DispatchContext } from "../../../reducers";
import { useWindow } from '../../../hooks'

export const EiaWeekly = ({ type: initType }) => {
    const { type: path } = useParams()
    const type = initType || path
    const size = useWindow()
    const dispatch = useContext(DispatchContext)
    const [eiaData, setEiaData] = useState()
    const [open, setOpen] = useState(false)
    const [selected, setSelected] = useState()
    
    useEffect(() => {
        async function fetch () {
            if (!type) return
            dispatch({ type: 'spin on' })
            const result = await list2('eia', type)
            dispatch({ type: 'spin off' })
            setEiaData(result)
        }
        fetch()
    }, [type])

    const onCallback = (event) => {
        setSelected(event.data.chart)
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
        setSelected(null)
    }

    return (
        <div className='column px-1 mb-6'>
            <div className='row-right pr-2'>
                <Lastupdate type='eia' />
            </div>
            { eiaData && eiaData.map(({ id, name, category, data }) => {
                return (
                    <div key={`key_eia_combo_${type}_${id}`} className='column-center my-2'>
                        <div className='fs-20 bold-5'>{name}</div>
                        <Grid container className='row-center mt-5 mb-2'>
                            { data && data.map((param, index) => {
                                return (
                                    <Grid key={`key_eia_combo_${type}_${id}_${category}_${param.title}`} item md={6} className='row-center w-full'>
                                        <EiaGrid param={param} callback={onCallback} />
                                    </Grid>
                                )}
                            )}
                        </Grid>
                    </div>
                )
            })}
            <Modal open={open} onClose={handleClose} className='modal' closeAfterTransition 
                BackdropComponent={Backdrop} BackdropProps={{ timeout: 500 }}>
                <Fade in={open}>
                    <div className='modal__eia' style={{ width: '98%', height: size.height*0.7, maxWidth: size.width*0.98 }} >
                        {selected && <div className='column-center p-2'>
                            <EiaChart param={selected} />
                        </div>}
                    </div>
                </Fade>
            </Modal>
        </div>
    )
}