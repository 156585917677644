import { useState, useEffect, useContext, createContext } from 'react';
import { useParams } from "react-router-dom";
import moment from 'moment'
import { list2 } from '../../../services'
import { ExportFile, FixturesGrid, Lastupdate } from '../../../components'
import { DispatchContext } from "../../../reducers";

export const GeoContext = createContext(null)

export const Fixtures = ({ type: initType }) => {
    const { type: path } = useParams()
    const type = initType || path
    const dispatch = useContext(DispatchContext)
    const [data, setData] = useState([])
    const [origin, setOrigin] = useState({ region: 'All', subregion: 'All', country: 'All', port: 'All' });
    const [destination, setDestination] = useState({ region: 'All', subregion: 'All', country: 'All', port: 'All' });

    const changeOrigin = ori => {
        setOrigin(ori)
    }

    const changeDestination = des => {
        setDestination(des)
    }

    useEffect(() => {
        async function fetch () {
            if (!type) return
            dispatch({ type: 'spin on' })
            const query = {}
            if (origin.region !== 'All') query.origin_region = origin.region.label
            if (origin.subregion !== 'All') query.origin_subregion = origin.subregion.label
            if (origin.country !== 'All') query.origin_country = origin.country.label
            if (origin.port !== 'All') query.origin_port = origin.port.label
            if (destination.region !== 'All') query.destination_region = destination.region.label
            if (destination.subregion !== 'All') query.destination_subregion = destination.subregion.label
            if (destination.country !== 'All') query.destination_country = destination.country.label
            if (destination.port !== 'All') query.destination_port = destination.port.label
            const result = await list2('fixtures', type, query)
            dispatch({ type: 'spin off' })
            setData(result)
        }
        fetch()
    }, [type, origin, destination])

    return (
        <GeoContext.Provider value={{ origin, destination, changeOrigin, changeDestination }}>
            <div className='mt-3 column-center'>
                <div className='row-between px-2 w-90p'>
                    <ExportFile data={[{ filename: `${type} Fixtures ${moment().format('YYYY-MM-DD')}.csv`, data, type: 'text/csv' }]} type={`fixtures_${type.toLowerCase()}`} subject='fixtures data' />
                    <Lastupdate type='flows' />
                </div>
                <FixturesGrid data={data} />
            </div>
        </GeoContext.Provider>
    )
}