import { useState, useEffect, useContext, useRef } from 'react';
import { list2 } from '../../services/Api'
import { DispatchContext } from "../../reducers";
import { Icon, Message } from '..';
import { Button, Dialog } from '@material-ui/core'
import Cookies from 'js-cookie'

export const ExportPpt = ({ filename, type, color = 'green' }) => {
    const messageRef = useRef()
    const dispatch = useContext(DispatchContext)
    const [open, setOpen] = useState(false)
    const [access, setAccess] = useState(false)
    useEffect(() => {
        async function fetch() {
            const auth = await list2('auth')
            setAccess(auth === true)
        }
        fetch()
    }, [])
    const onPopup = () => {
        if (filename && type) setOpen(true)
    }
    const onClose = () => {
        setOpen(false)
    }
    const onConfirm = async () => {
        if (!type || !filename) return
        setOpen(false)
        dispatch({ type: 'spin on' })
        const result = await list2(`report/export/${type}`, filename)
        dispatch({ type: 'spin off' })
        if (result === true) {
            messageRef.current.showSuccess('Export Data Success')
        } else {        
            messageRef.current.showError('Export Data Fail')
        }
    }
    return (
        <div className={``}>
            <Message ref={messageRef} />
            {access && <label className='f-08 up-3' onClick={onPopup}><Icon icon={'faDownload'} color={color} /></label>}
            <Dialog onClose={onClose} open={open}>
                <div className='row-center f-02 bold-5 nw-300 my-3 px-2'>Export {<label className='mx-1 underline aramco_blue'>{filename}</label>} to email</div>
                <div className='row-center f-02'>{Cookies.get('username')}</div>
                <div className='row-between px-3 my-3'>
                    <Button onClick={onClose}>Cancel</Button>
                    <Button onClick={onConfirm}><label className='dark_blue bold-5'>Export</label></Button>
                </div>
            </Dialog>
        </div>
    )
}