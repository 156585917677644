import { useState, forwardRef, useImperativeHandle } from 'react';
import { Backdrop, Fade, Modal } from '@material-ui/core';

export const ModalComponent = forwardRef(({ body = <div>body</div>, button = <>open medal</>, width = 500, height = 500 }, ref) => {
    const [open, setOpen] = useState(false)
    useImperativeHandle(ref, () => ({ close() { setOpen(false) } }))

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    return (
        <>
            <div onClick={handleOpen}>{button}</div>
            <Modal open={open} onClose={handleClose} className='modal' closeAfterTransition 
                BackdropComponent={Backdrop} BackdropProps={{ timeout: 500 }}>
                <Fade in={open}>
                    <div className='modal__content' style={{ width, height }} >{body}</div>
                </Fade>
            </Modal>
        </>
    )
})