import { Divider, Grid } from "@material-ui/core";
import { useEffect, useState } from "react"
import useWindow from "../../hooks/useWindow"
import { list2 } from '../../services/Api';
import { PieChartMarketShare, PieChartProduct, ProfileChartStack, ProfileChartColumn, ProfileChartBalance, ProfileChartNetRow, LineChart, Icon } from "../../components"
import { ProfileDetailItemHtml } from './CompanyDetail'

//MUI List 
import { List, ListItem, ListItemText, ListItemIcon } from '@mui/material';
import { Select, MenuItem } from '@material-ui/core';

//MUI Typography
import Typography from '@material-ui/core/Typography';

//MUI Card
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

//MUI Table
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

//MUI styles
import useProfileStyles from '../../hooks/useProfileStyles'
import useTableStyles from "../../hooks/useTableStyles";

export const keys = ['overview', 'demand', 'infrastructure', 'trade', 'competitors', 'energyTransition', 'tradeVolume']



export const CountryDetail = ({ country }) => {
    return (
        <div className=''>
            {Object.entries(country).filter(([key, items]) => keys.includes(key) && items).map(([key, items]) => {
                return (
                    <CountryDetailSector key={`key_country_profile_sector_${key}`} id={key} items={items} />
                )
            })}
        </div>
    )
}

export const CountryDetailSector = ({ id, items }) => {
    return (
        <div id={id} className='column-left mb-2 p-1 scroll-country-profile'>
            {items.map((item, i) => {
                return <CountryDetailItem key={`key_country_profile_item_${i}`} item={item} id={id} />
            })}
        </div>
    )
}

export const CountryDetailItem = ({ item, id }) => {
    const { headline, subline, title, subtitle, caption, type, data, keys } = item
    const classes = useProfileStyles();

    return (
        <div className='w-full my-2'>
            {type !== 'market_intelligence' && <>{headline && 
                <Card elevation={3} className={classes.headline}>
                    <CardContent classes={{
                        root: classes.readjustPadding
                    }}>
                        <Typography variant="h5">{headline}</Typography>
                        {subline && <Typography className={classes.subline}>{subline}</Typography>}
                    </CardContent>
                </Card>}
            {title && <Card elevation={3} className={classes.title}> 
                        <CardContent classes={{
                            root: classes.readjustPadding
                        }}>
                            <Typography variant="h6">{title}</Typography>
                        </CardContent>
                      </Card>}
            {subtitle && <Card elevation={3} className={classes.subtitle}>
                <CardContent classes={{
                    root: classes.readjustPadding
                }}>
                    <Typography variant="h6">{subtitle}</Typography>
                </CardContent>
            </Card>}</>}
            {caption && <div className='white bg-black bold-5 f-02'>{caption}</div>}
            {type === 'text' && <CountryDetailItemText data={data} id={id} />}
            {type === 'image' && <CountryDetailItemImages data={data} id={id} />}
            {type === 'object' && <CountryDetailItemObject data={data} id={id} />}
            {type === 'pie' && <CountryDetailItemPie data={data} id={id} />}
            {type === 'table' && <CountryDetailItemTable data={data} keys={keys} id={id} />}
            {type === 'stack' && <CountryDetailItemStack data={data} keys={keys} id={id} />}
            {type === 'column' && <CountryDetailItemColumn data={data} keys={keys} id={id} />}
            {type === 'balance' && <CountryDetailItemBalance data={data} keys={keys} id={id} />}
            {type === 'net_row' && <CountryDetailItemNetRow data={data} keys={keys} id={id} />}
            {type === 'html' && <ProfileDetailItemHtml data={data} id={id} />}
            {type === 'economic' && <CountryDetailEconomic data={data} />}
        </div>
    )
}
export const CountryDetailItemText = ({ data, id }) => {
   
    return (
        <List>
        {data.map((dat, i) => {
            return <div>
                        <ListItem key={`country_detail_item_text_${id}_${i}`}>
                            <ListItemIcon>
                                <Icon icon='faCaretRight' />
                            </ListItemIcon>
                            <ListItemText primary={dat}></ListItemText> 
                        </ListItem>
                        {i===data.length-1 ?  null : <Divider/>}
                    </div>
        })}
        </List>
       
    )
}
export const CountryDetailItemImages = ({ data }) => {
    return (
        <Grid container className='row-center'>
            {data.map((name) => (
                <Grid item sm={6} md={4} lg={3} key={`country_detail_item_image_${name}`}>
                    <CountryDetailItemImage name={name} />
                </Grid>
            ))}
        </Grid>
    )
}
export const CountryDetailItemImage = ({ name, maxWidth, maxHeight = '600px', marginRight = 'auto' }) => {
    const size = useWindow()
    const [src, setSrc] = useState()
    const width = size.width - 10
    useEffect(() => {
        async function fetch() {
            if (!name) return
            const { data: buffer } = await list2('image/download', name)
            const base64 = Buffer.from(buffer).toString('base64')
            const src = `data:image/png;base64,${base64}`
            setSrc(src)
        }
        fetch()
    }, [])
    return (
        <div className='row-center'>
            {src && <img src={src} style={{maxWidth: maxWidth || `${width}px`, maxHeight, marginRight }} alt={name} />}
        </div>
    )
}
export const CountryDetailItemObject = ({ data, id }) => {
    return (
        <div className=''>
            {data.map((dat, i) => {
                const { key, value, logo, pie } = dat
                return (
                <div key={`country_detail_item_object_${id}_${key}_${i}`} className='row-stretch w-full'>
                    <div className='row-center mr-2 mb-2 fs-20 mw-100 nw-100 white bg-green1'>
                        {key}
                    </div>
                    {logo && <div className='row-center'>
                        <CountryDetailItemImage name={logo} maxWidth='60px' maxHeight='60px' marginRight='10px' />
                    </div>}
                    {value && <div className='w-full mb-2'>
                        <CountryDetailItemText data={value} id={key} />
                    </div>}
                    {pie && <div className='w-full mb-2'>
                        <CountryDetailItemLogo data={pie} />
                    </div>}
                </div>
            )})}
        </div>
    )
}
export const CountryDetailItemLogo = ({ data }) => { 
    const size = useWindow()
    const [width, setWidth] = useState(Math.min(size.width - 10, 800))
    useEffect(() => {
        const wi = Math.min(size.width - 140, 800)
        setWidth(wi)
    }, [size])
    return (
        <div className='row-stretch w-full'>
            <PieChartMarketShare data={data} width={width} />
        </div>
    )
}
export const CountryDetailItemPie = ({ data }) => { 
    const size = useWindow()
    const [width, setWidth] = useState(Math.min(size.width - 10, 800))
    useEffect(() => {
        const wi = Math.min(size.width - 10, 800)
        setWidth(wi)
    }, [size])
    return (
        <div className='row-center'>
            <PieChartProduct data={data} width={width} height={400} />
        </div>
    )
}

export const CountryDetailEconomic = ({ data }) => {
    
    const [selected, setSelected] = useState(data[0])
    
    useEffect(() => {
        setSelected(data[0])
    }, [data])

    const handleOptionChange = (event) => {
        setSelected(event.target.value)
    }

    return (
        <div className='column-center mt-2'>
            <div className='row-left'>
                <Select value={selected} onChange={handleOptionChange}>
                    {data.map((item) => (
                        <MenuItem key={`symbol_option_key_${item.id}`} value={item}>{item.title}</MenuItem>
                    ))}
                </Select>
            </div>
            <div className='linechart'>
                {<LineChart data={selected} />}
            </div>
        </div>
    )
}

export const CountryDetailItemTable = ({ data, keys: defaultKeys, id}) => {
    const keys = defaultKeys || Object.keys(data[0])
    const classes = useTableStyles();

    return (
        <div className='w-full my-2 row-center'>
            <TableContainer component={Paper}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            {
                                keys.map(key => <TableCell align="center" className={classes.headerCell} component="th" key={`country_detail_item_table_header_${id}_${key}`}>{key}</TableCell> )
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {data.map((dat, i) => (
                        <TableRow key={`country_detail_item_table_row_${id}_${i}`}>
                            {keys.map(key => <TableCell align="center" component="th" className={classes.tableCell} key={`country_detail_item_table_col_${id}_${i}_${key}`}>{dat[key]}</TableCell>)}
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}

export const CountryDetailItemStack = ({ data }) => { 
    return (
        <div className='row-center'>
            <ProfileChartStack data={data} />
        </div>
    )
}
export const CountryDetailItemColumn = ({ data }) => { 
    return (
        <div className='row-center'>
            <ProfileChartColumn data={data} />
        </div>
    )
}
export const CountryDetailItemBalance = ({ data }) => { 
    return (
        <div className='row-center'>
            <ProfileChartBalance data={data} />
        </div>
    )
}
export const CountryDetailItemNetRow = ({ data }) => { 
    return (
        <div className='row-center'>
            <ProfileChartNetRow data={data} />
        </div>
    )
}