import React, {useState, useEffect} from 'react'
import { AgGridReact } from 'ag-grid-react'
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-alpine.css'
import { gridHeight } from '../../services/Api'
import { TwoLineHeader } from './CftcGrid';

export const OtherStocksGrid = ({param, callback}) => {

   
    const [rowData, setRowData] = useState([])
    const [gridApi, setGridApi] = useState();
    const [columnDefs, setColumnDefs] = useState([])

    const onGridReady = (params) => {
        setGridApi(params.api);
        sizeToFit();
        window.onresizable = () => {
            sizeToFit();
        }
    };

    const sizeToFit = () => {
        if (gridApi) gridApi.sizeColumnsToFit();
    };


    useEffect(() => {
        const { data, colDefs } = param
        if(data && colDefs) {
            const cellRenderer = (col) => {
                return col ? col === 'name' ? param => `<div class="ml-2 underline blue">${param.value}</div>` : param => `<div class="cell-liner">${param.value}</div>
                <div class="cell-liner">${param.data[`${col}Diff`]}</div>
                <div class="cell-liner">${param.data[`${col}DiffPercent`]}</div>` : undefined
            }
            setColumnDefs(colDefs.map(({ cellrdr, chart, ...rest }) => ({ cellRenderer: cellRenderer(cellrdr), ...rest })))
            setRowData(data)
        }
       
    }, [param])

    const onCellClicked = (param) => {
        callback(param)
    }

    return (
        <div className="ag-theme-alpine ag-packed-right" style={{ height: gridHeight(param.data.length, 70), width: '99%' }}>
            <AgGridReact frameworkComponents={{TwoLineHeader}} 
            onCellClicked={onCellClicked} 
            rowData={rowData} rowHeight={70} 
            columnDefs={columnDefs}  
            onGridReady={onGridReady} defaultColDef={{
                        flex: 1,
                        minWidth: 60,
                        wrapText: true,
                        suppressMovable: true,
                        headerClass: (params) => {
                            return params.colDef.field === 'name' ? 'ag-header-center' : ''
                        }
                    }}/>
        </div>
    )
}   